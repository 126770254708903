import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useUser } from '~/providers/user';

import { validate } from '~/validators/user';

import { setFormData } from '~/utils/form';

import {
  Form,
  Button,
  Loading,
  TextInput,
  RadioGroup,
  ValueInput,
  Selector,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

import { parseFormData } from '~/utils/user';
import { cnpjMask, cpfMask, numberUnmask } from '~/utils/mask';

const userTypes = [
  { value: 'F', label: 'Física' },
  { value: 'J', label: 'Jurídica' },
];

function UserForm({ tablecolor }) {
  const location = useLocation();

  const {
    user,
    userLoading,
    userErrors,
    store,
    update,
    newUser,
    codeAuthUsers,
    clearState,
  } = useUser();
  const { logged_branch, user: loggedUser } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);
  const [type, setType] = useState('');
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);

  function handleChangeType(newType) {
    setType(newType);

    setFormData({ formRef, formData: { cpf_cnpj: '' } });
  }

  async function handleChangeCompany(newCompany) {
    const emailInputRef = formRef.current.getFieldRef('email');
    emailInputRef.handleChange(null);

    if (newCompany) {
      const { users: auxUsers } = await codeAuthUsers({
        company_key: newCompany.company_key,
      });

      setUsers(auxUsers);
    } else setUsers([]);
  }

  async function handleSubmit(data) {
    const dataToValidade = { ...user, ...data };

    if (!loggedUser.is_admin)
      dataToValidade.company_key =
        user.company_key || logged_branch.company_key;

    const { errorMessages, ...parsedData } = await validate(dataToValidade);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedUser = {
        ...parsedData,
        req_id: '1',
      };

      if (user.uuid) await update({ user: parsedUser });
      else await store({ user: parsedUser });
    }
  }

  useEffect(() => {
    if (
      Object.keys(userErrors).length &&
      userErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(userErrors.validations);
  }, [userErrors]);

  useEffect(() => {
    async function loadData() {
      if (loggedUser.is_admin) {
        const { companies: auxCompanies } = await newUser();

        setCompanies(auxCompanies);
      } else {
        const { users: auxUsers } = await codeAuthUsers({});

        setUsers(auxUsers);
      }
    }

    if (user.uuid) {
      const auxUser = { ...user };

      const {
        type: auxType,
        companies: auxCompanies,
        users: auxUsers,
        ...userData
      } = auxUser;

      setCompanies(auxCompanies);
      setUsers(auxUsers);

      if (formRef.current) {
        const typeInputRef = formRef.current.getFieldRef('type');
        typeInputRef.handleChange({ target: { value: auxType } });

        if (auxCompanies) {
          const findedCompany = auxCompanies.find(
            findCompany => findCompany.company_key === auxUser.company_key
          );

          const companyInputRef = formRef.current.getFieldRef('company_key');
          companyInputRef.handleChange(findedCompany);
        }

        const findedUser = auxUsers.find(
          findUser => findUser.email === auxUser.email
        );

        const emailInputRef = formRef.current.getFieldRef('email');
        emailInputRef.handleChange(findedUser);
      }

      const formData = parseFormData(userData);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showUser) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newUser) {
      loadData();

      setCanEdit(true);
    }
  }, [loggedUser, user, location, newUser, codeAuthUsers]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={
                    !canEdit || (!loggedUser.is_admin && !loggedUser.is_manager)
                  }
                />
                <RadioGroup
                  name="type"
                  label="Tipo"
                  options={userTypes}
                  onChange={handleChangeType}
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <TextInput
                  name="cpf_cnpj"
                  label={!type ? 'CPF / CNPJ' : type === 'F' ? 'CPF' : 'CNPJ'} // eslint-disable-line
                  helperText={!type ? 'Selecione um Tipo antes' : ''}
                  mask={type === 'F' ? cpfMask : cnpjMask}
                  disabled={!type || !canEdit}
                  required
                />
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                {loggedUser.is_admin && (
                  <Selector
                    name="company_key"
                    label="Empresa"
                    idColumn="company_key"
                    nameColumn="name"
                    options={companies}
                    loading={userLoading}
                    onChange={handleChangeCompany}
                    required
                    disabled={!canEdit}
                  />
                )}
                <Selector
                  name="email"
                  label="E-mail"
                  idColumn="email"
                  nameColumn="email"
                  options={users}
                  loading={userLoading}
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <h2>Licenças e Valores</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent>
                <TextInput
                  name="licenses"
                  label="Licenças"
                  required
                  mask={numberUnmask}
                  disabled={!canEdit}
                />
                <ValueInput
                  name="commission_percentage"
                  label="% Comissão"
                  disabled={!canEdit}
                  prefix="% "
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <ValueInput
                  name="commission_value"
                  label="Comissão Valor"
                  disabled={!canEdit}
                  prefix="R$ "
                />
                <ValueInput
                  name="sales_goal"
                  label="Meta de Venda"
                  disabled={!canEdit}
                  prefix="R$ "
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>

          <Button
            disabled={userLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {userLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default UserForm;

UserForm.propTypes = {
  tablecolor: PropTypes.bool,
};

UserForm.defaultProps = {
  tablecolor: true,
};
