import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIGrid from '@material-ui/core/Grid';
import MUIButton from '@material-ui/core/Button';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIGridListTileBar from '@material-ui/core/GridListTileBar';

export const Autocomplete = styled(MUIAutocomplete)`
  width: 200px;
  border-radius: 4px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(TextField)``;

export const SelectorLoading = styled(CircularProgress).attrs({ size: 20 })`
  position: absolute;

  top: 10px;
  right: 11px;
`;

export const CharacterLimit = styled.p`
  position: absolute;
  top: 30px;
  right: 4px;
  padding: 0 2px;

  background: ${props => props.theme.colors.backFirst};
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 6px;
`;

export const Grid = styled(MUIGrid)`
  width: 210px;

  padding: 0;

  &.MuiGrid-container {
    width: 100%;
    margin: 0;
  }
`;

export const Button = styled(MUIButton)`
  width: 300px;
`;

export const IconButton = styled(MUIIconButton)``;

export const Loading = styled(CircularProgress).attrs({ size: 20 })`
  position: absolute;
`;

export const GridListTileBar = styled(MUIGridListTileBar)``;

export const ButtonImage = styled(MUIButton).attrs({
  color: 'primary',
  disableElevation: true,
})``;
