import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IcBtn from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SwipViews from 'react-swipeable-views';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.backFirst};
`;

export const Branch = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 400px;
  padding: 1em;
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const Loading = styled(CircularProgress).attrs({
  size: 24,
})`
  position: absolute;
`;

export const SwipeableViews = styled(SwipViews).attrs({
  disabled: true,
})``;

export const IconButton = styled(IcBtn)`
  &.MuiIconButton-root {
    top: 20px;
    left: 20px;
    position: absolute;
    z-index: 1;
  }
`;

export const IconArrowBack = styled(ArrowBack)``;
