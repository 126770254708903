/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as Button } from '@material-ui/core/Button';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';

export { default as ArrowBack } from '@material-ui/icons/ArrowBack';
export { default as PersonAdd } from '@material-ui/icons/PersonAdd';
export { default as Block } from '@material-ui/icons/Block';
export { default as CheckCircle } from '@material-ui/icons/CheckCircle';
export { default as AccountCircle } from '@material-ui/icons/AccountCircle';
export { default as AttachMoney } from '@material-ui/icons/AttachMoney';
export { default as Warning } from '@material-ui/icons/Warning';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1px;

  height: 100vh;

  .loading-wrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 70px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em 2em;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;

  button {
    p {
      margin-right: 0.5em;
    }
  }
`;

export const CustomerList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em 2em;
  flex-flow: row wrap;
  overflow-x: auto;
`;

export const CustomerListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex: 0 1 400px;
  margin-right: 2em;
  margin-bottom: 2em;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  line-height: 1.5;

  .customer-details {
    border-left: 1px solid #ececec;
    padding-left: 1em;
    display: flex;
    justify-content: center;

    .customer-detail {
      > span {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 1em;
        background: #ffffff;
        margin-right: 1em;
        margin-bottom: 1em;
        font-size: 0.4rem;
      }

      .span-1 {
        svg {
          color: ${props =>
            props.status === 1
              ? '#2fc145'
              : props.status === 2
              ? '#e51010'
              : '#ffa500'};
        }
      }

      .span-2 {
        svg {
          color: #bbbbbb;
        }
      }

      .span-3 {
        svg {
          color: ${props =>
            props.status_buy === 1
              ? '#2fc145'
              : props.status_buy === 2
              ? '#e51010'
              : '#ffa500'};
        }
      }

      .span-4 {
        svg {
          color: #f8cb0d;
        }
      }
    }
  }
`;

export const CustomerInfo = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    padding: 1em;

    border-radius: 10px 0 0 10px;

    h1 {
      font-size: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: start;
    }

    .customer-info-detail {
      display: flex;
      gap: 20px;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;
