import React from 'react';

import { useAuth } from '~/providers/auth';

import Onn from './Onn';
import Off from './Off';

export default function Routes() {
  const { signed } = useAuth();

  return signed ? <Onn /> : <Off />;
}
