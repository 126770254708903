import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/freightTable';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    let parsedOrderBy = order_by;

    if (order_by === 'uf_city') parsedOrderBy = 'city_id';
    else if (order_by === 'start_at_view' || order_by === 'end_at_view')
      parsedOrderBy = parsedOrderBy.replace('_view', '');
    else if (order_by === 'customer_group_name' || order_by === 'customer_name')
      parsedOrderBy = parsedOrderBy.replace('_name', '_uuid');

    const { data } = await apiFacin
      .get('/freight-tables', {
        params: { search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { freightTables: parseToView(data) };
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao listar Tabelas de Frete',
      local: 'FreightTableActionsIndex',
    });

    return { freightTableErrors };
  }
}

export async function show({ freightTableUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/freight-tables/${freightTableUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.freight_table;

    return {
      freightTable: parseToView([{ ...data.freight_table, ...auxData }])[0],
    };
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao visualizar Tabela de Frete',
      local: 'FreightTableActionsShow',
    });

    return { freightTableErrors };
  }
}

export async function store({ freightTable = {} }) {
  try {
    const { data } = await apiFacin
      .post('/freight-tables', {
        data: [freightTable],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...freightTable, ...data[0].freight_table }])[0];
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Tabela de Frete',
      local: 'FreightTableActionsStore',
    });

    return { freightTableErrors };
  }
}

export async function update({ freightTable = {} }) {
  try {
    const { data } = await apiFacin
      .put('/freight-tables', {
        data: [freightTable],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...freightTable, ...data[0].freight_table }])[0];
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao atualizar Tabela de Frete',
      local: 'FreightTableActionsUpdate',
    });

    return { freightTableErrors };
  }
}

export async function destroy({ freightTable = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/freight-tables`,
      method: 'delete',
      data: { data: [freightTable] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao remover Tabela de Frete',
      local: 'FreightTableActionsDestroy',
    });

    return { freightTableErrors };
  }
}

export async function newFreightTable() {
  try {
    const { data } = await apiFacin
      .get('/new-freight-table')
      .then(res => res.data);

    return data;
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para nova Tabela de Frete',
      local: 'FreightTableActionsNewFreightTable',
    });

    return { freightTableErrors };
  }
}

export async function freightTableValue({ customer_uuid, gross_weight }) {
  try {
    const { data } = await apiFacin
      .get('/freight-table-value', { params: { customer_uuid, gross_weight } })
      .then(res => res.data);

    return { freightTable: data.freight_table };
  } catch (error) {
    const freightTableErrors = errorHandler({
      error,
      title: 'Error ao consultar Tabela de Frete',
      local: 'FreightTableActionsFreightTableValue',
    });

    return { freightTableErrors };
  }
}
