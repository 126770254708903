import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/customer-groups', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { customerGroups: data };
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao listar Grupos de Clientes',
      local: 'CustomerGroupActionsIndex',
    });

    return { customerGroupErrors };
  }
}

export async function show({ customerGroupUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/customer-groups/${customerGroupUuid}`)
      .then(res => res.data);

    return { customerGroup: data.customer_group };
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao visualizar Grupo de Cliente',
      local: 'CustomerGroupActionsShow',
    });

    return { customerGroupErrors };
  }
}

export async function store({ customerGroup = {} }) {
  try {
    const { data } = await apiFacin
      .post('/customer-groups', {
        data: [customerGroup],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...customerGroup, ...data[0].customer_group };
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Grupo de Cliente',
      local: 'CustomerGroupActionsStore',
    });

    return { customerGroupErrors };
  }
}

export async function update({ customerGroup = {} }) {
  try {
    const { data } = await apiFacin
      .put('/customer-groups', {
        data: [customerGroup],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...customerGroup, ...data[0].customer_group };
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao atualizar Grupo de Cliente',
      local: 'CustomerGroupActionsUpdate',
    });

    return { customerGroupErrors };
  }
}

export async function destroy({ customerGroup = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/customer-groups`,
      method: 'delete',
      data: { data: [customerGroup] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao remover Grupo de Cliente',
      local: 'CustomerGroupActionsDestroy',
    });

    return { customerGroupErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: customerGroups } = await apiFacin
      .get(`/customer-groups`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { customerGroups };
  } catch (error) {
    const customerGroupErrors = errorHandler({
      error,
      title: 'Error ao listar Grupos de Clientes',
      local: 'CustomerGroupActionsIndexSelector',
    });

    return { customerGroupErrors };
  }
}
