import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { usePaymentMethod } from '~/providers/paymentMethod';
import { useOrder } from '~/providers/order';
import { useNavigation } from '~/providers/navigation';

import { parseDataToApi } from '~/utils/order';

import OrderHeader from '~/components/OrderHeader';

import {
  Container,
  NavigationContent,
  PaymentList,
  PaymentListItem,
  Tooltip,
  Icon,
  CircularProgress,
} from './styles';

function PaymentMethods({ changing }) {
  const {
    paymentMethods,
    paymentMethodListLoading,
    index,
  } = usePaymentMethod();
  const { order, setOrderData, update } = useOrder();
  const { pageIndex, setPageIndex } = useNavigation();

  const loadPaymentMethods = useCallback(
    async (search = '', order_by = 'id', auxOrder = 'asc') => {
      await index({ search, order_by, auxOrder });
    },
    [index]
  );

  async function handleSelectPaymentMethod(paymentMethod) {
    if (changing === 'payment_method') {
      setPageIndex({ newPageIndex: 1 });

      await update({
        order: {
          ...parseDataToApi(order),
          payment_method_uuid: paymentMethod.uuid,
        },
      });
    } else {
      setOrderData({ order: { ...order, payment_method: paymentMethod } });

      setPageIndex({ newPageIndex: 3 });
    }
  }

  useEffect(() => {
    loadPaymentMethods();
  }, [loadPaymentMethods]);

  return (
    <Container>
      <OrderHeader
        changing={changing}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        search={loadPaymentMethods}
        loading={paymentMethodListLoading}
      />
      {paymentMethodListLoading && (
        <div className="loading-wrapper">
          <CircularProgress />
        </div>
      )}
      <NavigationContent>
        <h2>Formas de Pagamento</h2>
      </NavigationContent>
      <PaymentList>
        {paymentMethods.map(paymentMethod => (
          <PaymentListItem
            key={paymentMethod.uuid}
            onClick={() => handleSelectPaymentMethod(paymentMethod)}
          >
            <div className="payment-info">
              <Tooltip title={paymentMethod.name}>
                <h1>{paymentMethod.name}</h1>
              </Tooltip>
              <Icon>{paymentMethod.icon}</Icon>
            </div>
            <div className="payment-details">
              <p>
                Desconto Inicial:{' '}
                {paymentMethod.start_discount_percentage || '0,00'} %
              </p>
              <p>
                Desconto Máximo:{' '}
                {paymentMethod.max_discount_percentage || '0,00'} %
              </p>
              <p>
                Mínimo por parcela: {paymentMethod.quantity_installment || '0'}x
                R$ {paymentMethod.min_per_installment || '0,00'}
              </p>
            </div>
          </PaymentListItem>
        ))}
      </PaymentList>
    </Container>
  );
}

export default PaymentMethods;

PaymentMethods.propTypes = {
  changing: PropTypes.string,
};

PaymentMethods.defaultProps = {
  changing: '',
};
