import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/paymentMethod';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/payment-methods', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { paymentMethods: parseToView(data) };
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao listar Formas de Pagamentos',
      local: 'PaymentMethodActionsIndex',
    });

    return { paymentMethodErrors };
  }
}

export async function show({ paymentMethodUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/payment-methods/${paymentMethodUuid}`)
      .then(res => res.data);
    const { payment_method, types } = data;

    return {
      paymentMethod: { ...parseToView([{ ...payment_method }])[0], types },
    };
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao visualizar Forma de Pagamento',
      local: 'PaymentMethodActionsShow',
    });

    return { paymentMethodErrors };
  }
}

export async function store({ paymentMethod = {} }) {
  try {
    const { data } = await apiFacin
      .post('/payment-methods', {
        data: [paymentMethod],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...paymentMethod, ...data[0].payment_method }])[0];
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Forma de Pagamento',
      local: 'PaymentMethodActionsStore',
    });

    return { paymentMethodErrors };
  }
}

export async function update({ paymentMethod = {} }) {
  try {
    const { data } = await apiFacin
      .put('/payment-methods', {
        data: [paymentMethod],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...paymentMethod, ...data[0].payment_method }])[0];
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao atualizar Forma de Pagamento',
      local: 'PaymentMethodActionsUpdate',
    });

    return { paymentMethodErrors };
  }
}

export async function destroy({ paymentMethod = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/payment-methods`,
      method: 'delete',
      data: { data: [paymentMethod] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao remover Forma de Pagamento',
      local: 'PaymentMethodActionsDestroy',
    });

    return { paymentMethodErrors };
  }
}

export async function newPaymentMethod() {
  try {
    const { data } = await apiFacin
      .get('/new-payment-method')
      .then(res => res.data);

    return data;
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para nova Forma de Pagamento',
      local: 'PaymentMethodActionsNewPaymentMethod',
    });

    return { paymentMethodErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: paymentMethods } = await apiFacin
      .get(`/payment-methods`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { paymentMethods };
  } catch (error) {
    const paymentMethodErrors = errorHandler({
      error,
      title: 'Error ao listar Formas de Pagamento',
      local: 'PaymentMethodActionsIndexSelector',
    });

    return { paymentMethodErrors };
  }
}
