import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import * as store from './store';

const INITIAL_STATE = 'light';

const ThemeContext = createContext('light');

export function ThemeProvider({ children }) {
  const [data, setData] = useState(() => {
    const themeData = store.getData();

    if (themeData) return themeData;

    store.setData(INITIAL_STATE);

    return INITIAL_STATE;
  });

  function setTheme(theme) {
    store.setData(theme);

    setData(theme);
  }

  return (
    <ThemeContext.Provider value={{ theme: data, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context)
    throw new Error('useTheme must be used within an ThemeProvider');

  return context;
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
