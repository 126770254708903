import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useOrder } from '~/providers/order';

import { Form, Grid, Loading, ValueInput } from '../Form';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from './styles';

function OrderDiscountDialog({ showDialog, setShowDialog }) {
  const { order, orderLoading, setDiscount, orderErrors, show } = useOrder();

  const formRef = useRef(null);

  function handleClose() {
    setShowDialog(false);
  }

  async function handleSubmit() {
    if (formRef.current) {
      const data = formRef.current.getData();

      const orderDiscount = {
        ...order,
        ...data,
        req_id: '1',
      };

      const response = await setDiscount({
        order: orderDiscount,
      });

      if (response) {
        handleClose();

        if (order.uuid) await show({ orderUuid: order.uuid });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(orderErrors).length &&
      orderErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(orderErrors.validations);
  }, [orderErrors]);

  return (
    <Dialog open={showDialog} onClose={!orderLoading ? handleClose : () => {}}>
      <DialogTitle>Desconto principal %</DialogTitle>
      <DialogContent style={{ maxWidth: 300 }}>
        Coloque um valor para ser aplicado automaticamente nos próximos itens
        adicionados e itens que já estão no carrinho
      </DialogContent>
      <Form style={{ maxWidth: 300 }} ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <ValueInput
              name="header_discount"
              label="Desconto"
              required
              inputProps={{
                prefix: '% ',
                isAllowed: values => {
                  const { floatValue } = values;
                  return floatValue <= 100.0 || floatValue === undefined;
                },
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <DialogActions>
        <Button disabled={orderLoading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={orderLoading}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >
          Finalizar
          {orderLoading && <Loading />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderDiscountDialog;

OrderDiscountDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
