import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseDataToApi } from '~/utils/order';

import { Dialog, StatusContainer, SaveButtonStatus } from './styles';
import { Form, Selector, Loading } from '~/components/Form';
import { useOrder } from '~/providers/order';

export default function ChangeStatusDialog({ showDialog, setShowDialog }) {
  const { order, updateStatus, statusIndexSelector, orderLoading } = useOrder();
  const [orderStatusLoading, setOrderStatusLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const formRef = useRef(null);

  const loadOrderStatus = useCallback(
    async (search = '') => {
      setOrderStatusLoading(true);
      const auxOrderStatus = await statusIndexSelector({ search });
      setOrderStatusLoading(false);
      setOrderStatus(auxOrderStatus);
    },
    [statusIndexSelector]
  );

  const handleClose = () => {
    setShowDialog(false);
  };

  async function handleChangeStatus(data) {
    await updateStatus({
      order: {
        ...parseDataToApi({
          ...order,
          status_uuid: data.status_uuid,
          status_view: null,
          statuses: [],
        }),
      },
    });
    setShowDialog(false);
  }

  useEffect(() => {
    if (order.uuid) {
      const auxOrder = { ...order };

      const { statuses: auxStatuses, status } = auxOrder;

      setOrderStatus(auxStatuses);

      if (formRef.current) {
        const statusInputRef = formRef.current.getFieldRef('status_uuid');
        statusInputRef.handleChange(status);
      }
    }
  }, [order]);

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <Form ref={formRef} onSubmit={handleChangeStatus}>
        <StatusContainer>
          <Selector
            name="status_uuid"
            label="Status"
            idColumn="uuid"
            nameColumn="name"
            options={orderStatus}
            loading={orderStatusLoading}
            loadData={loadOrderStatus}
          />
          <div>
            <SaveButtonStatus type="submit" disabled={orderLoading}>
              {orderLoading && <Loading />}
              Alterar
            </SaveButtonStatus>
          </div>
        </StatusContainer>
      </Form>
    </Dialog>
  );
}

ChangeStatusDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
