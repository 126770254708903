import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  customerGroups: [],
  customerGroup: {},
  customerGroupErrors: {},
  customerGroupLoading: false,
  customerGroupListLoading: false,
};

const CustomerGroupContext = createContext(INITIAL_STATE);

export function CustomerGroupProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setCustomerGroupData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setCustomerGroupData({ customerGroupListLoading: true });

      const customerGroupData = await actions.index({
        search,
        order_by,
        order,
      });

      if (customerGroupData.customerGroupErrors)
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setCustomerGroupData({
        ...customerGroupData,
        customerGroupListLoading: false,
      });
    },
    [setCustomerGroupData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ customerGroupUuid = '' }) => {
      setCustomerGroupData({ customerGroupLoading: true });

      const customerGroupData = await actions.show({ customerGroupUuid });

      if (customerGroupData.customerGroupErrors) {
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => show({ customerGroupUuid }),
          },
        });

        history.goBack();
      }

      setCustomerGroupData({
        ...customerGroupData,
        customerGroupLoading: false,
      });
    },
    [setCustomerGroupData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ customerGroup = {} }) => {
      setCustomerGroupData({ customerGroupLoading: true });

      const customerGroupData = await actions.store({ customerGroup });

      if (customerGroupData.customerGroupErrors) {
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => store({ customerGroup }),
          },
        });

        setCustomerGroupData({
          ...customerGroupData,
          customerGroupLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          customerGroups: [customerGroupData, ...oldData.customerGroups],
          customerGroupLoading: false,
        }));

        history.goBack();

        toast.success('Grupo de Cliente cadastrado com sucesso!');
      }
    },
    [setCustomerGroupData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ customerGroup = {} }) => {
      setCustomerGroupData({ customerGroupLoading: true });

      const customerGroupData = await actions.update({ customerGroup });

      if (customerGroupData.customerGroupErrors) {
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => update({ customerGroup }),
          },
        });

        setCustomerGroupData({
          ...customerGroupData,
          customerGroupLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          customerGroups: [
            customerGroupData,
            ...oldData.customerGroups.filter(
              auxCustomerGroup => auxCustomerGroup.uuid !== customerGroup.uuid
            ),
          ],
          customerGroupLoading: false,
        }));

        history.goBack();

        toast.success('Grupo de Cliente atualizado com sucesso!');
      }
    },
    [setCustomerGroupData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ customerGroup = {} }) => {
      setCustomerGroupData({ customerGroupListLoading: true });

      const customerGroupData = await actions.destroy({ customerGroup });

      if (customerGroupData.customerGroupErrors) {
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => destroy({ customerGroup }),
          },
        });

        setCustomerGroupData({
          ...customerGroupData,
          customerGroupListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          customerGroups: [
            ...oldData.customerGroups.filter(
              auxCustomerGroup => auxCustomerGroup.uuid !== customerGroup.uuid
            ),
          ],
          customerGroupListLoading: false,
        }));

        toast.success('Grupo de Cliente removido com sucesso!');
      }
    },
    [setCustomerGroupData, setErrorHandlerData]
  );

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setCustomerGroupData({ customerGroupLoading: true });

      const customerGroupData = await actions.indexSelector({ search });

      if (customerGroupData.customerGroupErrors) {
        setErrorHandlerData({
          error: {
            ...customerGroupData.customerGroupErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setCustomerGroupData({ customerGroupLoading: false });

      return customerGroupData.customerGroups;
    },
    [setCustomerGroupData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        customerGroup: {},
        customerGroupErrors: {},
        customerGroupLoading: false,
        customerGroupListLoading: false,
      };
    });
  }, []);

  return (
    <CustomerGroupContext.Provider
      value={{
        ...data,
        setCustomerGroupData,
        index,
        show,
        store,
        update,
        destroy,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </CustomerGroupContext.Provider>
  );
}

export function useCustomerGroup() {
  const context = useContext(CustomerGroupContext);

  if (!context)
    throw new Error(
      'useCustomerGroup must be used within an CustomerGroupProvider'
    );

  return context;
}

CustomerGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
