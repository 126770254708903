import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useUser } from '~/providers/user';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'image', label: 'Imagem', image: true, disablePadding: true },
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'cpf_cnpj', label: 'CPF / CNPJ' },
];

function Users() {
  const history = useHistory();

  const { user, current_accesses } = useAuth();
  const { users, userListLoading, index, show, destroy } = useUser();

  const [loaded, setLoaded] = useState(false);

  const loadUsers = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin || user.is_manager || current_accesses['facin.users.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/users/form', { newUser: true }),
          }
        : null,
    [history, user, current_accesses]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.users/other-infos.get']
    )
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async auxUser => {
          history.push('/users/form', { showUser: true });

          await show({ userUuid: auxUser.uuid });
        },
      });

    if (user.is_admin || user.is_manager || current_accesses['facin.users.put'])
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async auxUser => {
          if (!user.is_admin && !user.is_manager && user.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/users/form', { updateUser: true });

            await show({ userUuid: auxUser.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.users.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: auxUser => {
          if (!user.is_admin && !user.is_manager && user.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ user: auxUser });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && users.length <= 1) loadUsers();

    setLoaded(true);
  }, [users, loadUsers, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Usuários"
        newItem={newItem}
        headCells={headCells}
        data={users}
        loadData={loadUsers}
        loading={userListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Users;
