export function parseFormData(share = {}) {
  return {
    customers: share.customers,
    customer_groups: share.customer_groups,
    freight_tables: share.freight_tables,
    generic_tables: share.generic_tables,
    not_sales: share.not_sales,
    orders: share.orders,
    order_items: share.order_items,
    payment_methods: share.payment_methods,
    price_lists: share.price_lists,
    products: share.products,
    product_groups: share.product_groups,
    product_images: share.product_images,
    unit_of_measures: share.unit_of_measures,
  };
}

export function setFormData({ formRef, formData = {} }) {
  Object.keys(formData).forEach(fieldName => {
    if (!formRef.current) return;

    const inputRef = formRef.current.getFieldRef(fieldName);
    inputRef.handleChange({ target: { checked: formData[fieldName] } });
  });
}
