import { numberMask, valueUnmask, valueMask } from './mask';

export function parseToView(freightTables = []) {
  return freightTables.map(freightTable => ({
    ...freightTable,
    customer_group_name:
      freightTable.customer_group && freightTable.customer_group.name,
    customer_name: freightTable.customer && freightTable.customer.first_name,
    uf_city: `${(freightTable.state && freightTable.state.uf) || ''} / ${
      (freightTable.city && freightTable.city.name) || ''
    }`,
    start_at_view: new Date(freightTable.start_at).toLocaleDateString(),
    end_at_view: new Date(freightTable.end_at).toLocaleDateString(),
    quantity: valueMask(freightTable.quantity),
    value: valueMask(freightTable.value),
    min_weight: valueMask(freightTable.min_weight),
    max_weight: valueMask(freightTable.max_weight),
  }));
}

export function parseDataToApi(freightTable = {}) {
  return {
    branch_key: freightTable.branch_key,
    cities: [],
    city: {},
    city_id: freightTable.city_id,
    company_key: freightTable.company_key,
    customer: {},
    customer_group: {},
    customer_group_name: freightTable.customer_group_name,
    customer_group_uuid: freightTable.customer_group_uuid,
    customer_groups: [],
    customer_name: freightTable.customer_name,
    customer_uuid: freightTable.customer_uuid,
    customers: [],
    description: freightTable.description,
    end_at: freightTable.end_at,
    end_at_view: freightTable.end_at_view,
    legacy_code: freightTable.legacy_code,
    max_weight: valueUnmask(freightTable.max_weight),
    min_weight: valueUnmask(freightTable.min_weight),
    quantity: valueUnmask(freightTable.quantity),
    req_id: freightTable.req_id,
    start_at: freightTable.start_at,
    start_at_view: freightTable.start_at_view,
    state: {},
    state_id: freightTable.state_id,
    states: [],
    status_code: numberMask(freightTable.status_code),
    uf_city: freightTable.uf_city,
    uuid: freightTable.uuid,
    value: valueUnmask(freightTable.value),
  };
}

export function parseFormData(freightTable = {}) {
  return {
    legacy_code: freightTable.legacy_code,
    description: freightTable.description,
    quantity: freightTable.quantity,
    value: freightTable.value,
    min_weight: freightTable.min_weight,
    max_weight: freightTable.max_weight,
  };
}
