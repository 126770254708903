import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useGenericTable } from '~/providers/genericTable';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'table_view', label: 'Tabela' },
  { id: 'name', label: 'Nome' },
];

function GenericTables() {
  const history = useHistory();

  const {
    genericTables,
    genericTableListLoading,
    index,
    show,
    destroy,
  } = useGenericTable();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadGenericTables = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.generic-tables.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/generic-tables/form', { newGenericTable: true }),
          }
        : null,
    [history, user, current_accesses]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async genericTable => {
          history.push('/generic-tables/form', { showGenericTable: true });

          await show({ genericTableUuid: genericTable.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.generic-tables.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async genericTable => {
          if (!user.is_admin && !user.is_manager && genericTable.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/generic-tables/form', { updateGenericTable: true });

            await show({ genericTableUuid: genericTable.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.generic-tables.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: genericTable => {
          if (!user.is_admin && !user.is_manager && genericTable.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ genericTable });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && genericTables.length <= 1) loadGenericTables();

    setLoaded(true);
  }, [genericTables, loadGenericTables, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Tabelas Genéricas"
        newItem={newItem}
        headCells={headCells}
        data={genericTables}
        loadData={loadGenericTables}
        loading={genericTableListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default GenericTables;
