import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function indexSelectorByTableName({
  tableName = '',
  search = '',
}) {
  try {
    const { data } = await apiFacin
      .get(`${tableName}`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return data;
  } catch (error) {
    const indexSelectorErrors = errorHandler({
      error,
      title: `Error ao listar ${tableName}`,
      local: `${tableName}UtilsIndexSelectorByTableName`,
    });

    return { indexSelectorErrors };
  }
}

export function parseToView({
  options = [],
  tableLabel = '',
  tableKey = '',
  frontKey = '',
}) {
  return options.map(option => {
    const parsedOption = {
      ...option,
    };

    if (frontKey === 'uf') {
      parsedOption[`${tableLabel}_view`] = `${option[tableLabel]} - ${
        option[frontKey] || option[tableKey]
      }`;
    } else {
      parsedOption[
        `${tableLabel}_view`
      ] = `${option[tableKey]} - ${option[tableLabel]}`;
    }

    return parsedOption;
  });
}
