import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useParameter } from '~/providers/parameter';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'parameter_view', label: 'Nome' },
  { id: 'value_view', label: 'Valor' },
];

function Parameters() {
  const history = useHistory();

  const { user, current_accesses } = useAuth();
  const {
    parameters,
    parameterListLoading,
    index,
    show,
    destroy,
  } = useParameter();

  const [loaded, setLoaded] = useState(false);

  const loadParameters = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.parameters.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/parameters/form', { newParameter: true }),
          }
        : null,
    [history, user, current_accesses]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.parameters/other-infos.get']
    )
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async auxParamenter => {
          history.push('/parameters/form', { showParameter: true });

          await show({ parameterUuid: auxParamenter.uuid });
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.parameters.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async auxParamenter => {
          if (!user.is_admin && !user.is_manager && user.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/parameters/form', { updateParameter: true });
            await show({ parameterUuid: auxParamenter.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.parameters.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: auxParamenter => {
          if (!user.is_admin && !user.is_manager && user.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ parameter: auxParamenter });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && parameters.length <= 1) loadParameters();

    setLoaded(true);
  }, [parameters, loadParameters, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Parâmetros"
        newItem={newItem}
        headCells={headCells}
        data={parameters}
        loadData={loadParameters}
        loading={parameterListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Parameters;
