import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useErrorHandler } from '../errorHandler';
import { useShare } from '../share';
import { useUser } from '../user';
import { useCustomerGroup } from '../customerGroup';
import { useGenericTable } from '../genericTable';
import { useCustomer } from '../customer';
import { useProductGroup } from '../productGroup';
import { useUnitOfMeasure } from '../unitOfMeasure';
import { useProduct } from '../product';
import { usePaymentMethod } from '../paymentMethod';
import { useFreightTable } from '../freightTable';
import { useOrder } from '../order';
import { useReport } from '../reports';

import apiFacin from '~/config/apiFacin';

import * as actions from './actions';
import * as store from './store';

const INITIAL_STATE = {
  email: '',
  password: '',
  companies: [],
  branches: [],
  signed: false,
  user: {},
  token: {},
  logged_branch: {},
  current_accesses: {},
  authPageIndex: 0,
  authErrors: {},
  authLoading: false,
};

const AuthContext = createContext(INITIAL_STATE);

export function AuthProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();

  const { clearState: clearStateShare } = useShare();
  const { clearState: clearStateUser } = useUser();
  const { clearState: clearStateCustomerGroup } = useCustomerGroup();
  const { clearState: clearStateGenericTable } = useGenericTable();
  const { clearState: clearStateCustomer } = useCustomer();
  const { clearState: clearStateProductGroup } = useProductGroup();
  const { clearState: clearStateUnitOfMeasure } = useUnitOfMeasure();
  const { clearState: clearStateProduct } = useProduct();
  const { clearState: clearStatePaymentMethod } = usePaymentMethod();
  const { clearState: clearStateFreightTable } = useFreightTable();
  const { clearState: clearStateOrder } = useOrder();
  const { clearState: clearStateReport } = useReport();

  const [data, setData] = useState(() => {
    const authData = store.getData();

    if (authData.token && authData.token.token && authData.signed) {
      apiFacin.defaults.headers.authorization = `Bearer ${authData.token.token}`;
      apiFacin.defaults.headers[
        'Refresh-Token'
      ] = `${authData.token.refreshToken}`;

      return { ...INITIAL_STATE, ...authData };
    }

    store.setData(INITIAL_STATE);

    return INITIAL_STATE;
  });

  const setAuthData = useCallback((newData = INITIAL_STATE) => {
    store.setData(newData);

    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const clearAllData = useCallback(() => {
    clearStateShare({ all: true });
    clearStateUser({ all: true });
    clearStateCustomerGroup({ all: true });
    clearStateGenericTable({ all: true });
    clearStateCustomer({ all: true });
    clearStateProductGroup({ all: true });
    clearStateUnitOfMeasure({ all: true });
    clearStateProduct({ all: true });
    clearStatePaymentMethod({ all: true });
    clearStateFreightTable({ all: true });
    clearStateOrder({ all: true });
    clearStateReport({ al: true });
  }, [
    clearStateShare,
    clearStateUser,
    clearStateCustomerGroup,
    clearStateGenericTable,
    clearStateCustomer,
    clearStateProductGroup,
    clearStateUnitOfMeasure,
    clearStateProduct,
    clearStatePaymentMethod,
    clearStateFreightTable,
    clearStateOrder,
    clearStateReport,
  ]);

  const signIn = useCallback(
    async ({ email = '', password = '', branch_key = '', force = false }) => {
      setAuthData({ email, password, authErrors: {}, authLoading: true });

      const authData = await actions.signIn({
        email,
        password,
        branch_key,
        force,
      });

      if (authData.authErrors)
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () =>
              signIn({ email, password, branch_key, force: true }),
          },
        });
      else if (authData.token) authData.signed = true;
      else if (!data.authPageIndex)
        authData.authPageIndex = data.authPageIndex + 1;
      else {
        authData.email = '';
        authData.password = '';
      }

      setAuthData({ ...authData, authLoading: false });
    },
    [data.authPageIndex, setAuthData, setErrorHandlerData]
  );

  const changeBranch = useCallback(
    async ({ branch_key = '', force = false }) => {
      setAuthData({ authErrors: {}, authLoading: true });

      const authData = await actions.changeBranch({ branch_key, force });

      if (authData.authErrors)
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () => changeBranch({ branch_key, force: true }),
          },
        });
      else {
        clearAllData();

        history.goBack();
      }

      setAuthData({ ...authData, authLoading: false });
    },
    [setAuthData, setErrorHandlerData, clearAllData, history]
  );

  const dashboardInfo = useCallback(
    async ({ search = '', order = '', order_by = '' }) => {
      setAuthData({ authLoading: true });

      const authData = await actions.dashboardInfo({ search, order, order_by });

      setAuthData({ authLoading: false });

      if (authData.authErrors) {
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () => dashboardInfo({ search, order, order_by }),
          },
        });

        return {};
      }

      return authData;
    },
    [setAuthData, setErrorHandlerData]
  );

  const changePassword = useCallback(
    async ({ password }) => {
      setAuthData({ authLoading: true });

      const authData = await actions.changePassword({ password });

      setAuthData({ authLoading: false });

      if (authData.authErrors) {
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () => changePassword({ password }),
          },
        });
      } else {
        toast.success('Senha alterada com sucesso!');

        history.goBack();
      }
    },
    [setAuthData, setErrorHandlerData, history]
  );

  const signOut = useCallback(() => {
    store.setData(INITIAL_STATE);

    apiFacin.defaults.headers.authorization = null;
    apiFacin.defaults.headers['Refresh-Token'] = null;

    setAuthData(INITIAL_STATE);

    clearAllData();
  }, [setAuthData, clearAllData]);

  const refreshToken = useCallback(async () => {
    setAuthData({ authLoading: true });

    const authData = await actions.refreshToken();

    if (authData.authErrors) signOut();
    else setAuthData({ ...authData, authLoading: false });
  }, [setAuthData, signOut]);

  return (
    <AuthContext.Provider
      value={{
        ...data,
        setAuthData,
        signIn,
        changeBranch,
        signOut,
        refreshToken,
        dashboardInfo,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
