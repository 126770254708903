import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/orderItem';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      product_uuid: Yup.string().required('Este campo é obrigatório'),
      price_list_uuid: Yup.string().nullable(),
      quantity: Yup.string().required('Este campo é obrigatório'),
      unitary_net_value: Yup.string().required('Este campo é obrigatório'),
      unitary_gross_value: Yup.string().required('Este campo é obrigatório'),
      unitary_discount_value: Yup.string().nullable(),
      unitary_gross_weight: Yup.string().required('Este campo é obrigatório'),
      unitary_net_weight: Yup.string().required('Este campo é obrigatório'),
      total_net_value: Yup.string().required('Este campo é obrigatório'),
      total_gross_value: Yup.string().required('Este campo é obrigatório'),
      total_discount_value: Yup.string().nullable(),
      total_gross_weight: Yup.string().required('Este campo é obrigatório'),
      total_net_weight: Yup.string().required('Este campo é obrigatório'),
      discount_percentage: Yup.string().nullable(),
      ipi_value: Yup.string().nullable(),
      ipi_percentage: Yup.string().nullable(),
      commission_percentage: Yup.string().nullable(),
      commission_value: Yup.string().nullable(),
      deadline: Yup.string().nullable(),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
