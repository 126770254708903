import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled(Autocomplete)`
  width: 200px;
  border-radius: 4px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(TextField)``;

export const Loading = styled(CircularProgress).attrs({ size: 40 })`
  position: absolute;

  top: 8px;
  right: 1px;
`;
