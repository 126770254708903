export function parseToView(priceLists = []) {
  return priceLists.map(priceList => ({
    ...priceList,
    customer_name: priceList.customer.first_name,
    generic_table_name: priceList.generic_table.name,
  }));
}

export function parseFormData(priceList = {}) {
  return {
    legacy_code: priceList.legacy_code,
    observation: priceList.observation,
  };
}
