import { toast } from 'react-toastify';

import apiFacin from '~/config/apiFacin';

export default async function ({ state_id }) {
  try {
    const { data } = await apiFacin
      .get(`/states/${state_id}`)
      .then(res => res.data);

    return data;
  } catch (error) {
    toast.warn('Error ao buscar Cidades do Estado');

    return { cities: [] };
  }
}
