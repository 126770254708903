import { format, parseISO } from 'date-fns';

export function parseToView(parameters = []) {
  return parameters.map(parameter => {
    let value_view = '';
    if (parameter) {
      const value2 = JSON.parse(parameter?.parameter?.value2);

      if (
        value2.component === 'RadioGroup' ||
        value2.component === 'Selector'
      ) {
        const { options } = value2?.props;

        const findedOption = options.find(
          option => option.value === parameter.value
        );

        value_view = findedOption?.label;
      } else if (value2.component === 'Checkbox') {
        value_view = parameter.value === 'true' ? 'Sim' : 'Não';
      } else if (value2.component === 'ValueInput') {
        value_view = `R$ ${parameter.value}`;
      } else if (value2.component === 'DatePicker') {
        value_view = format(parseISO(parameter.value), "dd'/'MM'/'yyyy");
      } else if (value2.component === 'ImagePicker') {
        value_view = 'Imagem';
      } else {
        value_view = parameter.value;
      }
    }
    return {
      ...parameter,
      parameter: parameter.parameter,
      parameter_view: parameter?.parameter?.name,
      value_view,
    };
  });
}

export function parseDataToApi(parameter = {}) {
  return {
    uuid: parameter.uuid,
    parameter: parameter.parameter,
    parameter_uuid: parameter.parameter_uuid,
    value: `${parameter.value}`,
  };
}

export function parseFormData(parameter = {}) {
  return {
    value: parameter.value,
  };
}
