import { numberMask, valueMask, valueUnmask } from './mask';

import { parseToView as priceListParseToView } from './priceList';

export function parseToView(products = []) {
  return products.map(product => ({
    ...product,
    image:
      product.product_images && product.product_images.length
        ? `${product.product_images[0].image}`
        : null,
    unit_of_measure_name: `${product.unit_of_measure.name}`,
    product_group_name: `${product.product_group.name}`,
    price_lists: priceListParseToView(product.price_lists),
    price_list_price: product.price_list
      ? valueMask(product.price_list.price, 6)
      : null,
    price: valueMask(product.price, 6),
    gross_weight: valueMask(product.gross_weight, 2),
    net_weight: valueMask(product.net_weight, 2),
    stock_balance: valueMask(product.stock_balance, 2),
    ipi_percentage: valueMask(product.ipi_percentage, 2),
  }));
}

export function parseDataToApi(product = {}) {
  return {
    branch_key: product.branch_key,
    company_key: product.company_key,
    deadline: numberMask(product.deadline),
    description: product.description || null,
    gross_weight: valueUnmask(product.gross_weight),
    image: product.image,
    ipi_percentage: valueUnmask(product.ipi_percentage),
    legacy_code: product.legacy_code || '',
    manufacturer: product.manufacturer || '',
    name: product.name || '',
    net_weight: valueUnmask(product.net_weight),
    price: valueUnmask(product.price),
    price_list_price: product.price_list_price,
    price_lists: [],
    product_group: {},
    product_group_name: product.product_group_name,
    product_group_uuid: product.product_group_uuid || '',
    product_groups: [],
    product_images: product.image ? [{ image: product.image }] : [],
    reference_code: product.reference_code || null,
    req_id: product.req_id,
    status_code: numberMask(product.status_code),
    stock_balance: valueUnmask(product.stock_balance),
    unit_of_measure: {},
    unit_of_measure_name: product.unit_of_measure_name,
    unit_of_measure_uuid: product.unit_of_measure_uuid || '',
    unit_of_measures: [],
    uuid: product.uuid,
  };
}

export function parseFormData(product = {}) {
  return {
    legacy_code: product.legacy_code,
    reference_code: product.reference_code,
    name: product.name,
    description: product.description,
    manufacturer: product.manufacturer,
    price: product.price,
    gross_weight: product.gross_weight,
    net_weight: product.net_weight,
    stock_balance: product.stock_balance,
    ipi_percentage: product.ipi_percentage,
    deadline: product.deadline,
  };
}
