import apiFacin from '~/config/apiFacin';

import { parseToView, parseDataToApi } from '~/utils/order';
import { valueUnmask } from '~/utils/mask';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    let parsedOrderBy = order_by;

    if (order_by === 'status_view')
      parsedOrderBy = parsedOrderBy.replace('view', 'code');
    else if (order_by === 'payment_method_name' || order_by === 'customer_name')
      parsedOrderBy = parsedOrderBy.replace('_name', '_uuid');

    const { data } = await apiFacin
      .get('/orders', {
        params: { search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { orders: parseToView(data) };
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao listar Pedidos de Venda',
      local: 'OrderActionsIndex',
    });

    return { orderErrors };
  }
}

export async function show({ orderUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/orders/${orderUuid}`)
      .then(res => res.data);

    const { statuses, ...auxData } = { ...data };
    delete auxData.order;

    return {
      order: parseToView([{ ...data.order, ...auxData, statuses }])[0],
    };
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao visualizar Pedido de Venda',
      local: 'OrderActionsShow',
    });

    return { orderErrors };
  }
}

export async function store({ order = {}, orderItem = {} }) {
  try {
    const { data } = await apiFacin
      .post('/order-first', { data: { order, order_item: orderItem } })
      .then(res => res.data);
    return parseToView([
      {
        ...order,
        ...data.order,
        order_items: [{ ...orderItem, ...data.order_item }],
      },
    ])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Pedido de Venda',
      local: 'OrderActionsStore',
    });

    return { orderErrors };
  }
}

export async function update({ order = {} }) {
  try {
    const { data } = await apiFacin
      .put('/orders', {
        data: [{ ...order, status: '' }],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao atualizar Pedido de Venda',
      local: 'OrderActionsUpdate',
    });

    return { orderErrors };
  }
}
export async function updateStatus({ order = {} }) {
  try {
    const { data } = await apiFacin
      .patch('/orders/status', {
        data: [{ ...order }],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao atualizar Status do Pedido de Venda',
      local: 'OrderActionsUpdate',
    });

    return { orderErrors };
  }
}

export async function destroy({ order = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/orders`,
      method: 'delete',
      data: { data: [{ ...order, status: '' }] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao remover Pedido de Venda',
      local: 'OrderActionsDestroy',
    });

    return { orderErrors };
  }
}

export async function sendEmail({ order = {} }) {
  try {
    const { data } = await apiFacin
      .put('/orders/send-email', {
        data: [{ ...order, status: '' }],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao enviar e-mail do Pedido de Venda',
      local: 'OrderActionsSendEmail',
    });

    return { orderErrors };
  }
}

export async function generatePDF({ order = {} }) {
  try {
    const { data } = await apiFacin
      .put('/orders/generate-pdf', {
        data: [{ ...order, status: '' }],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao gerar PDF do Pedido de Venda',
      local: 'OrderActionsGeneratePDF',
    });

    return { orderErrors };
  }
}

export async function generateFreightPDF({ order = {} }) {
  try {
    const { data } = await apiFacin
      .put('/orders/generate-freight-pdf', {
        data: [{ ...order, status: '' }],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao gerar PDF do Pedido de Venda',
      local: 'OrderActionsGenerateFreightPDF',
    });

    return { orderErrors };
  }
}

export async function setDiscount({ order = {} }) {
  try {
    const { data } = await apiFacin
      .put('/orders', {
        data: [
          parseDataToApi({
            ...order,
            status: '',
            header_discount: valueUnmask(order.header_discount),
          }),
        ],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...order, ...data[0].order }])[0];
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao alterar o desconto do Pedido de Venda',
      local: 'OrderActionsSetDiscount',
    });

    return { orderErrors };
  }
}
export async function statusIndexSelector({ search = '' }) {
  try {
    const { data: status } = await apiFacin
      .get(`/generic-tables`, {
        params: { search, for_selector: true, table: '0000000007' },
      })
      .then(res => res.data);
    return { status } || '';
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao listar Status do Pedido',
      local: 'ordersActionsIndexSelector',
    });

    return { orderErrors };
  }
}
