import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useCustomer } from '~/providers/customer';
import { useOrder } from '~/providers/order';
import { useNavigation } from '~/providers/navigation';

import { parseDataToApi } from '~/utils/order';

import CustomerDialog from '~/components/Dialogs/Customer';
import NotSaleDialog from '~/components/Dialogs/NotSale';
import OrderHeader from '~/components/OrderHeader';

import {
  Container,
  Button,
  NavigationContent,
  CustomerList,
  CustomerListItem,
  CustomerInfo,
  PersonAdd,
  Block,
  CheckCircle,
  AccountCircle,
  AttachMoney,
  Warning,
  Tooltip,
  IconButton,
  CircularProgress,
} from './styles';

function Customers({ changing }) {
  const { customers, customerListLoading, index, show } = useCustomer();
  const { order, setOrderData, update } = useOrder();
  const { pageIndex, setPageIndex } = useNavigation();

  const [showCustomerDialog, setShowCustomerDialog] = useState(false);
  const [showNotSaleDialog, setShowNotSaleDialog] = useState(false);

  const notSaleDialogRef = useRef(null);

  async function handleSeeCustomer(customer) {
    setShowCustomerDialog(true);

    await show({ customerUuid: customer.uuid });
  }

  async function handleSelectCustomer(customer) {
    if (customer.status_code !== 1) {
      toast.warn('Só é possível selecionar Clientes ativos');
    } else if (changing === 'customer') {
      setPageIndex({ newPageIndex: 1 });

      await update({
        order: { ...parseDataToApi(order), customer_uuid: customer.uuid },
      });
    } else {
      setPageIndex({ newPageIndex: 2 });

      setOrderData({ order: { ...order, customer } });
    }
  }

  function handleNewNotSale(customer) {
    if (notSaleDialogRef.current) {
      notSaleDialogRef.current.handleLoadData();
      notSaleDialogRef.current.setCanEdit(true);
      notSaleDialogRef.current.setCurrentCustomer(customer);

      setShowNotSaleDialog(true);
    }
  }

  const loadCustomers = useCallback(
    async (search = '', order_by = 'id', auxOrder = 'asc') => {
      await index({ search, order_by, order: auxOrder });
    },
    [index]
  );

  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  return (
    <Container>
      <OrderHeader
        changing={changing}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        search={loadCustomers}
        loading={customerListLoading}
      />
      <CustomerDialog
        showDialog={showCustomerDialog}
        setShowDialog={setShowCustomerDialog}
      />
      <NotSaleDialog
        showDialog={showNotSaleDialog}
        setShowDialog={setShowNotSaleDialog}
        ref={notSaleDialogRef}
      />
      {customerListLoading && (
        <div className="loading-wrapper">
          <CircularProgress />
        </div>
      )}
      <NavigationContent>
        <h2>Selecione um cliente</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowCustomerDialog(true)}
        >
          <p>Novo Cliente</p>
          <PersonAdd />
        </Button>
      </NavigationContent>
      <CustomerList>
        {customers.map(customer => (
          <CustomerListItem
            key={customer.uuid}
            status={customer.status_code_view}
            status_buy={customer.status_buy_code}
          >
            <CustomerInfo onClick={() => handleSelectCustomer(customer)}>
              <h1>{customer.first_name}</h1>
              <p>{customer.address}</p>
              <p>{customer.cpf_cnpj}</p>
              <div className="customer-info-detail">
                <p>Código Facin: {customer.id}</p>
                {customer.legacy_code && (
                  <p>Código ERP: {customer.legacy_code}</p>
                )}
              </div>
            </CustomerInfo>
            <div className="customer-details">
              <div className="customer-detail">
                <span className="span-1">
                  <Tooltip title={customer.status_text_view}>
                    {customer.status_code_view === 1 ||
                    customer.status_code_view === 3 ? (
                      <CheckCircle />
                    ) : (
                      <Block />
                    )}
                  </Tooltip>
                </span>
                <span className="span-2">
                  <Tooltip title="Informações">
                    <IconButton
                      size="small"
                      onClick={() => handleSeeCustomer(customer)}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
              <div className="customer-detail">
                <span className="span-3">
                  <Tooltip title={customer.status_buy_text}>
                    <AttachMoney />
                  </Tooltip>
                </span>
                <span className="span-4">
                  <Tooltip title="Não Venda">
                    <IconButton
                      size="small"
                      onClick={() => handleNewNotSale(customer)}
                    >
                      <Warning />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </div>
          </CustomerListItem>
        ))}
      </CustomerList>
    </Container>
  );
}

export default Customers;

Customers.propTypes = {
  changing: PropTypes.string,
};

Customers.defaultProps = {
  changing: '',
};
