import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
  FormControl,
  Visibility,
  VisibilityOff,
} from './styles';

function TextInput({
  label,
  error,
  setError,
  style,
  icon,
  password,
  inputRef,
  ...rest
}) {
  const [auxError, setAuxError] = useState(error);
  const [showPassword, setShowPassword] = useState(false);

  function handleChange() {
    setAuxError('');

    if (setError) setError('');
  }

  useEffect(() => setAuxError(error), [error]);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item style={{ display: 'flex', flex: 1, width: 250, ...style }}>
        <FormControl fullWidth error={!!auxError}>
          <InputLabel error={!!auxError}>{label}</InputLabel>
          <Input
            inputRef={inputRef}
            defaultValue={inputRef.current && inputRef.current.value}
            onChange={handleChange}
            type={password && !showPassword ? 'password' : 'text'}
            endAdornment={
              password && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
            {...rest}
          />
          <FormHelperText error>{auxError || ' '}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
  inputRef: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  password: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TextInput.defaultProps = {
  error: '',
  setError: null,
  inputRef: {},
  style: {},
  password: false,
  icon: null,
};
