import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ChangeStatusDialog from '~/components/Dialogs/ChangeStatusDialog';
import TableList, { WrapperContainer } from '~/components/TableList';
import { useAuth } from '~/providers/auth';
import { useOrder } from '~/providers/order';
import { parseDataToApi } from '~/utils/order';

import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Settings from '@material-ui/icons/Settings';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ListItemText } from './styles';
import ShowOrderMessageDialog from '~/components/Dialogs/ShowOrderMessage';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'customer_name', label: 'Cliente' },
  { id: 'payment_method_name', label: 'Forma de Pagamento' },
  { id: 'total_sale_value', label: 'Valor da Venda' },
  { id: 'status_view', label: 'Status' },
];

const otherCells = [
  { id: 'freight_value', label: 'Valor Frete' },
  { id: 'ipi_value', label: 'Valor IPI' },
  { id: 'gross_weight', label: 'Peso Bruto' },
];

function Orders() {
  const history = useHistory();
  const { orders, orderListLoading, index, show, destroy, update } = useOrder();
  const { user, current_accesses } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  const ordersParsed = useMemo(
    () =>
      orders.map(order => {
        const style = {
          display: 'flex',
          alignItems: 'flex-end',
          color: '#ffa500',
          fontWeight: 'bold',
          borderRadius: 4,
        };
        let label = 'Pendente';
        let icon = <DoneIcon />;

        if (order.status_code === 2) {
          style.color = '#2fc145';
          label = 'Liberado';
          icon = <DoneAllIcon />;
        } else if (order.status_code === 3) {
          style.color = '#1f7ecc';
          label = 'Aprovado';
          icon = <CheckCircleIcon />;
        } else if (order.status_code === 4) {
          style.color = '#d50000';
          label = 'Recusado';
          icon = <BlockIcon />;
        } else if (order.status_code === 5) {
          style.color = '#0eed60';
          label = 'Faturado';
          icon = <CheckCircleIcon />;
        } else if (order.status_code === 9) {
          style.color = '#d50000';
          label = 'Cancelado ERP';
          icon = <RemoveCircleOutlineIcon />;
        }

        return {
          ...order,
          status_view: (
            <ListItemText
              primary={order.status_uuid_view}
              secondary={
                <div style={style}>
                  {label} {icon}
                </div>
              }
            />
          ),
        };
      }),
    [orders]
  );

  const loadOrders = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin || user.is_manager || current_accesses['facin.orders.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/orders/form', { newOrder: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async order => {
          history.push('/orders/form', { showOrder: true });

          await show({ orderUuid: order.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.orders.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async order => {
          if (
            !user.is_admin &&
            !user.is_manager &&
            !current_accesses['facin.orders/finalize.all'] &&
            (order.legacy_code || order.status_code !== 1)
          )
            toast.warn(
              'Sem permissão para alterar registros Finalizados ou com Código Legado preenchido'
            );
          else {
            history.push('/orders/form', { updateOrder: true });

            await show({ orderUuid: order.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.orders.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: order => {
          if (
            !user.is_admin &&
            !user.is_manager &&
            !current_accesses['facin.orders/finalize.all'] &&
            (order.legacy_code || order.status_code !== 1)
          )
            toast.warn(
              'Sem permissão para alterar registros Finalizados ou com Código Legado preenchido'
            );
          else destroy({ order });
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.orders/finalize.all']
    )
      auxActions.push({
        label: 'Aprovar',
        icon: <ThumbUpIcon fontSize="small" />,
        handleFunction: async order => {
          if (order.status_code === 2) {
            await update({
              order: {
                ...parseDataToApi(order),
                status_code: 3,
              },
            });
          } else toast.warn('Somente é possível aprovar pedidos liberados');
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.orders/finalize.all']
    )
      auxActions.push({
        label: 'Recusar',
        icon: <ThumbDownIcon fontSize="small" />,
        handleFunction: async order => {
          if (order.status_code === 2) {
            await update({
              order: {
                ...parseDataToApi(order),
                status_code: 4,
              },
            });
          } else toast.warn('Somente é possível recusar pedidos liberados');
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.orders.put']
    )
      auxActions.push({
        label: 'Alterar Status',
        icon: <Settings fontSize="small" />,
        handleFunction: async order => {
          setShowStatusDialog(true);
          await show({ orderUuid: order.uuid });
        },
      });

    auxActions.push({
      label: 'Mensagem do ERP',
      icon: <SearchIcon fontSize="small" />,
      handleFunction: async order => {
        setShowMessageDialog(true);
        await show({ orderUuid: order.uuid });
      },
    });

    return auxActions;
  }, [user, current_accesses, history, show, destroy, update]);

  useEffect(() => {
    if (!loaded && orders.length <= 1) loadOrders();

    setLoaded(true);
  }, [orders, loadOrders, loaded]);

  return (
    <WrapperContainer>
      <ChangeStatusDialog
        showDialog={showStatusDialog}
        setShowDialog={setShowStatusDialog}
      />
      <ShowOrderMessageDialog
        showDialog={showMessageDialog}
        setShowDialog={setShowMessageDialog}
      />
      <TableList
        title="Pedidos de Vendas"
        newItem={newItem}
        headCells={headCells}
        otherCells={otherCells}
        data={ordersParsed}
        loadData={loadOrders}
        loading={orderListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Orders;
