import styled from 'styled-components';
import IcBtn from '@material-ui/core/IconButton';
import Grd from '@material-ui/core/Grid';
import Ipt from '@material-ui/core/Input';
import IptLb from '@material-ui/core/InputLabel';
import IptAdmt from '@material-ui/core/InputAdornment';
import FmHpTxt from '@material-ui/core/FormHelperText';
import FmCtrl from '@material-ui/core/FormControl';
import Vblt from '@material-ui/icons/Visibility';
import VbltOf from '@material-ui/icons/VisibilityOff';
import Phone from '@material-ui/icons/Phone';
import MskdInput from 'react-text-mask';

export const IconButton = styled(IcBtn)``;

export const Grid = styled(Grd)``;

export const Input = styled(Ipt)``;

export const InputLabel = styled(IptLb)``;

export const InputAdornment = styled(IptAdmt)``;

export const FormHelperText = styled(FmHpTxt)``;

export const FormControl = styled(FmCtrl)``;

export const Visibility = styled(Vblt)``;

export const VisibilityOff = styled(VbltOf)``;

export const IconPhone = styled(Phone)``;

export const MaskedInput = styled(MskdInput)``;
