import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { usePaymentMethod } from '~/providers/paymentMethod';
import { useGenericTable } from '~/providers/genericTable';

import { validate } from '~/validators/paymentMethod';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/paymentMethod';

import {
  Form,
  Button,
  Loading,
  TextInput,
  Selector,
  ValueInput,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
  ButtonContent,
  FormScroll,
} from './styles';

function PaymentMethodForm({ tablecolor }) {
  const location = useLocation();
  const history = useHistory();

  const {
    paymentMethod,
    paymentMethodLoading,
    paymentMethodErrors,
    store,
    update,
    clearState,
    newPaymentMethod,
  } = usePaymentMethod();
  const { logged_branch, user } = useAuth();
  const { indexSelector: genericTableIndexSelector } = useGenericTable();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);
  const [types, setTypes] = useState([]);

  const loadTypes = useCallback(
    async (search = '') => {
      const auxTypes = await genericTableIndexSelector({
        search,
        table: '0000000004',
      });

      setTypes(auxTypes);
    },
    [genericTableIndexSelector]
  );

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedPaymentMethod = {
        ...parsedData,
        req_id: '1',
      };

      if (paymentMethod.uuid)
        await update({
          paymentMethod: { ...paymentMethod, ...parsedPaymentMethod },
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedPaymentMethod.company_key = company_key;
        parsedPaymentMethod.branch_key = branch_key;

        await store({ paymentMethod: parsedPaymentMethod });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(paymentMethodErrors).length &&
      paymentMethodErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(paymentMethodErrors.validations);
  }, [paymentMethodErrors]);

  useEffect(() => {
    async function loadData() {
      const { types: auxTypes } = await newPaymentMethod();

      setTypes(auxTypes);
    }

    if (paymentMethod.uuid) {
      const auxPaymentMethod = { ...paymentMethod };
      const {
        types: auxTypes,
        type_uuid,
        ...paymentMethodData
      } = auxPaymentMethod;

      const findedType = auxTypes.find(auxType => auxType.uuid === type_uuid);

      if (findedType) {
        const typeInputRef = formRef.current.getFieldRef('type_uuid');
        typeInputRef.handleChange(findedType);
      }

      const formData = parseFormData(paymentMethodData);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showPaymentMethod) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newPaymentMethod) {
      setCanEdit(true);
    }
    loadData();
  }, [paymentMethod, location, newPaymentMethod]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormScroll>
            <h2>Especificações</h2>
            <FormContainer>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="legacy_code"
                    label="Código Legado"
                    maxLength={10}
                    disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                  />
                  <TextInput
                    name="name"
                    label="Nome"
                    required
                    disabled={!canEdit}
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <Selector
                    name="type_uuid"
                    label="Tipo"
                    idColumn="uuid"
                    nameColumn="name"
                    required
                    options={types}
                    loading={paymentMethodLoading}
                    loadData={loadTypes}
                    disabled={!canEdit}
                  />
                </FormSingleContent>
              </FormContent>
            </FormContainer>
            <FormContainer>
              <h2>Descontos e Valores</h2>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <ValueInput
                    name="start_discount_percentage"
                    label="Desconto Inicial %"
                    disabled={!canEdit}
                    prefix="% "
                  />
                  <ValueInput
                    name="max_discount_percentage"
                    label="Máximo Desconto %"
                    disabled={!canEdit}
                    prefix="% "
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <ValueInput
                    name="quantity_installment"
                    label="Quantidade de Parcelas"
                    disabled={!canEdit}
                    inputProps={{ prefix: 'QTD ', decimalScale: 0 }}
                  />
                  <TextInput
                    name="term_installment"
                    label="Prazo das Parcelas"
                    helperText="Exemplo 3 parcelas: 40,60,80"
                    disabled={!canEdit}
                  />
                  <ValueInput
                    name="min_per_installment"
                    label="Mínimo por Parcela"
                    disabled={!canEdit}
                    prefix="R$ "
                  />
                </FormSingleContent>
              </FormContent>
            </FormContainer>
          </FormScroll>
          <ButtonContent>
            <Button
              disabled={paymentMethodLoading || !canEdit}
              type="button"
              variant="outlined"
              color="default"
              onClick={() => history.goBack()}
            >
              Cancelar
              {paymentMethodLoading && <Loading />}
            </Button>
            <Button
              disabled={paymentMethodLoading || !canEdit}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Confirmar
              {paymentMethodLoading && <Loading />}
            </Button>
          </ButtonContent>
        </Form>
      </DataContent>
    </Container>
  );
}

export default PaymentMethodForm;

PaymentMethodForm.propTypes = {
  tablecolor: PropTypes.bool,
};

PaymentMethodForm.defaultProps = {
  tablecolor: true,
};
