export function setTextAreaData({ inputRef, value }) {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLTextAreaElement.prototype,
    'value'
  ).set;
  nativeInputValueSetter.call(inputRef, value || '');

  const ev2 = new Event('input', { bubbles: true });
  inputRef.dispatchEvent(ev2);

  setTimeout(() => inputRef.blur(), 0);
}
