import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { useOrder } from '~/providers/order';
import { useNavigation } from '~/providers/navigation';
import CustomersContent from './Customers';
import PaymentMethodsContent from './PaymentMethods';
import ProductsContent from './Products';
import OrderContent from './Content';

import { Container, SwipeableViews } from './styles';

function OrderForm() {
  const location = useLocation();

  const { user, current_accesses } = useAuth();
  const { clearState, order } = useOrder();
  const { pageIndex, setPageIndex, clearNavigationState } = useNavigation();

  const [canEdit, setCanEdit] = useState(false);
  const [changing, setChanging] = useState('');

  function handleSetPageIndex(newPageIndex) {
    setPageIndex({ newPageIndex });
  }

  useEffect(() => () => clearState({}), [clearState]);

  useEffect(() => () => clearNavigationState({}), [clearNavigationState]);

  useEffect(() => {
    if (
      (order.status_code === 2 || order.status_code === 3) &&
      !user.is_admin &&
      !user.is_manager &&
      !current_accesses[`facin.orders/finalize.all`]
    )
      setCanEdit(false);
    else if (
      !location.state ||
      location.state.newOrder ||
      location.state.updateOrder
    )
      setCanEdit(true);

    window.history.replaceState(null, '');
  }, [location, order, user, current_accesses]);

  return (
    <Container>
      <SwipeableViews index={pageIndex}>
        {changing === 'customer' ? (
          <CustomersContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
            changing={changing}
          />
        ) : (
          <PaymentMethodsContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
            changing={changing}
          />
        )}
        {!order.uuid && (!location.state || location.state.newOrder) ? (
          <CustomersContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
          />
        ) : (
          <OrderContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
            canEdit={canEdit}
            setChanging={setChanging}
          />
        )}
        {!order.uuid && (!location.state || location.state.newOrder) ? (
          <PaymentMethodsContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
          />
        ) : (
          <ProductsContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
          />
        )}
        {!order.uuid && (!location.state || location.state.newOrder) ? (
          <ProductsContent
            pageIndex={pageIndex}
            setPageIndex={handleSetPageIndex}
          />
        ) : (
          <></>
        )}
      </SwipeableViews>
    </Container>
  );
}

export default OrderForm;
