import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useReport } from '~/providers/reports';

import { generatePdfValidate } from '~/validators/report';

import { Form, Grid, Loading } from '../Form';
import { DinamicInput } from '../DinamicInput';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from './styles';

function GeneratePdfDialog({ showDialog, setShowDialog }) {
  const { report, reportLoading, reportErrors, generatePdf } = useReport();

  const [report_queries, setReportQueries] = useState([]);

  const formRef = useRef(null);

  function handleClose() {
    setShowDialog(false);
  }

  async function handleSubmit() {
    const data = formRef.current.getData();

    const { errorMessages, ...parsedData } = await generatePdfValidate(
      data,
      report_queries
    );

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      await generatePdf({ reportUuid: report.uuid, report_query: parsedData });
    }
  }

  useEffect(() => {
    if (
      Object.keys(reportErrors).length &&
      reportErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(reportErrors.validations);
  }, [reportErrors, report]);

  useEffect(() => {
    function getQueries() {
      const { report_queries: auxReportQueries } = report;

      if (auxReportQueries) {
        const filterQueries = auxReportQueries
          ?.filter(report_query => report_query.filter_key)
          .sort((prev, curr) => prev.sequence - curr.sequence);

        setReportQueries(filterQueries);
      }
    }

    getQueries();
  }, [report]);

  return (
    <>
      <iframe style={{ display: 'none' }} id="Iframe" title="Iframe" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={!reportLoading ? handleClose : () => {}}
      >
        <DialogTitle>Filtros</DialogTitle>
        <DialogContent>Filtros para gerar o relatório.</DialogContent>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          style={{ padding: '0 22px' }}
        >
          <Grid container justify="center">
            {report_queries.map(report_query => (
              <Grid key={report_query.id} style={{ width: '50%' }} item>
                <DinamicInput
                  width="280px"
                  key={report_query.id}
                  reportQuery={report_query}
                />
              </Grid>
            ))}
          </Grid>
        </Form>
        <DialogActions>
          <Button
            disabled={reportLoading}
            onClick={handleClose}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            disabled={reportLoading}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
          >
            Gerar
            {reportLoading && <Loading />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GeneratePdfDialog;

GeneratePdfDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
