import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/product';
import errorHandler from '~/utils/errorHandler';

export async function index({
  search = '',
  order_by = '',
  order = '',
  customer_uuid = '',
}) {
  try {
    let parsedOrderBy = order_by;

    if (
      order_by === 'unit_of_measure_name' ||
      order_by === 'product_group_name'
    )
      parsedOrderBy = parsedOrderBy.replace('_name', '_uuid');

    const { data } = await apiFacin
      .get('/products', {
        params: { search, order_by: parsedOrderBy, order, customer_uuid },
      })
      .then(res => res.data);

    return { products: parseToView(data) };
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao listar Produtos',
      local: 'ProductActionsIndex',
    });

    return { productErrors };
  }
}

export async function show({ productUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/products/${productUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.product;

    return { product: parseToView([{ ...data.product, ...auxData }])[0] };
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao visualizar Produto',
      local: 'ProductActionsShow',
    });

    return { productErrors };
  }
}

export async function store({ product = {} }) {
  try {
    const { data } = await apiFacin
      .post('/products', {
        data: [product],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...product, ...data[0].product }])[0];
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Produto',
      local: 'ProductActionsStore',
    });

    return { productErrors };
  }
}

export async function update({ product = {} }) {
  try {
    const { data } = await apiFacin
      .put('/products', {
        data: [product],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...product, ...data[0].product }])[0];
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao atualizar Produto',
      local: 'ProductActionsUpdate',
    });

    return { productErrors };
  }
}

export async function destroy({ product = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/products`,
      method: 'delete',
      data: { data: [product] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao remover Produto',
      local: 'ProductActionsDestroy',
    });

    return { productErrors };
  }
}

export async function newProduct() {
  try {
    const { data } = await apiFacin.get('/new-product').then(res => res.data);

    return data;
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo Produto',
      local: 'ProductActionsNewProduct',
    });

    return { productErrors };
  }
}

export async function productPrice({ customer_uuid, product_uuid, quantity }) {
  try {
    const { data } = await apiFacin
      .get('/product-price', {
        params: { customer_uuid, product_uuid, quantity },
      })
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.product;

    return { product: parseToView([{ ...data.product, ...auxData }])[0] };
  } catch (error) {
    const productErrors = errorHandler({
      error,
      title: 'Error ao visualizar Produto',
      local: 'ProductActionsShow',
    });

    return { productErrors };
  }
}
