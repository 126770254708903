export async function base64toPDF(b64PDF = '') {
  await new Promise(resolve => {
    function b64toBlob(b64Data, sliceSize = 512) {
      const byteChar = atob(b64Data);

      const byteArr = [];

      for (let offset = 0; offset < byteChar.length; offset += sliceSize) {
        const slice = byteChar.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArr.push(byteArray);
      }

      const blob = new Blob(byteArr, { type: 'application/pdf' });
      return blob;
    }

    const blob = b64toBlob(b64PDF);
    const blobUrl = URL.createObjectURL(blob);

    const iframeEle = document.getElementById('Iframe');
    if (iframeEle) {
      iframeEle.src = blobUrl;

      setTimeout(() => {
        iframeEle.contentWindow.print();
        resolve();
      }, 1000);
    }
  });
}
