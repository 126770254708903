import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import Lst from '@material-ui/core/List';
import LstItem from '@material-ui/core/ListItem';

export { default as InputAdornment } from '@material-ui/core/InputAdornment';
export { default as FormControl } from '@material-ui/core/FormControl';
export { default as InputLabel } from '@material-ui/core/InputLabel';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Breadcrumbs } from '@material-ui/core/Breadcrumbs';
export { default as OutlinedInput } from '@material-ui/core/OutlinedInput';

export { default as NavigateNextIcon } from '@material-ui/icons/NavigateNext';

export const Nav = styled.header`
  display: flex;
  padding: 1em;
  align-items: center;
  justify-content: space-between;
  background: #ffff;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
`;

export const CartContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1em;
  gap: 20px;
`;

export const FinalizeCartContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CartListContainer = styled.div``;

export const List = styled(Lst).attrs({})``;

export const ListItem = styled(LstItem).attrs({})``;

export const Button = styled(Btn).attrs({})``;
