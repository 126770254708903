import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useShare } from '~/providers/share';

import { validate } from '~/validators/share';

import { setFormData as setShareFormData, parseFormData } from '~/utils/share';
import { setFormData } from '~/utils/form';

import {
  Form,
  Grid,
  Button,
  Loading,
  TextInput,
  Checkbox,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

function ShareForm({ tablecolor }) {
  const location = useLocation();

  const {
    share,
    shareLoading,
    shareErrors,
    store,
    update,
    clearState,
  } = useShare();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedShare = {
        ...parsedData,
        req_id: '1',
      };

      if (share.uuid) await update({ share: { ...share, ...parsedShare } });
      else await store({ share: parsedShare });
    }
  }

  useEffect(() => {
    if (
      Object.keys(shareErrors).length &&
      shareErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(shareErrors.validations);
  }, [shareErrors]);

  useEffect(() => {
    if (share.uuid) {
      const auxShare = { ...share };

      const { company_key, ...shareData } = auxShare;

      const formData = parseFormData(shareData);

      setFormData({ formRef, formData: { company_key } });
      setShareFormData({ formRef, formData });

      if (location.state && !location.state.showShare) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newShare) {
      setCanEdit(true);
    }
  }, [share, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContent>
            <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
              <TextInput
                name="company_key"
                label="CPF / CNPJ Raiz"
                maxLength={8}
                disabled={!canEdit}
                required
              />
            </FormSingleContent>
          </FormContent>
          <FormContainer>
            <FormContent>
              <FormSingleContent>
                <Checkbox
                  name="customers"
                  label="Cliente"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox
                  name="customer_groups"
                  label="Grupo de Cliente"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <Checkbox
                  name="freight_tables"
                  label="Tabela de Frete"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox
                  name="generic_tables"
                  label="Tabela Genérica"
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
            <FormContent>
              <FormSingleContent>
                <Checkbox
                  name="not_sales"
                  label="Não Venda"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox
                  name="orders"
                  label="Pedido de Venda"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <Checkbox
                  name="order_items"
                  label="Pedido de Venda Item"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox
                  name="payment_methods"
                  label="Forma de Pagamento"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <Checkbox
                  name="price_lists"
                  label="Tabela de Preço"
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox name="products" label="Produto" disabled={!canEdit} />
              </FormSingleContent>
              <FormSingleContent>
                <Checkbox
                  name="product_groups"
                  label="Grupo de Produto"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Checkbox
                  name="product_images"
                  label="Imagem de Produto"
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <Checkbox
                  name="unit_of_measures"
                  label="Unidade de Medida"
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>

          <Grid container justify="flex-start">
            <Grid item>
              <Button
                disabled={shareLoading || !canEdit}
                type="submit"
                variant="contained"
                color="secondary"
              >
                Confirmar
                {shareLoading && <Loading />}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DataContent>
    </Container>
  );
}

export default ShareForm;
ShareForm.propTypes = {
  tablecolor: PropTypes.bool,
};

ShareForm.defaultProps = {
  tablecolor: true,
};
