import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useLocation } from 'react-router-dom';
import ImageContainer from '../ImageContainer';

import { debounceEvent } from '~/utils/debounce';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '200%',
  },
  closeButton: {
    position: 'absolute',
  },
}));

function TableListToolbar({
  title,
  newItem,
  searchRef,
  handleChangeSearchText,
  canEdit,
}) {
  const classes = useToolbarStyles();

  const handleChangeText = event => {
    handleChangeSearchText(event.target.value);
  };

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" component="div">
        {title}
        {newItem && (
          <Button
            variant="contained"
            color="primary"
            onClick={newItem.handleFunction}
            disabled={!canEdit}
            style={{ marginLeft: 10 }}
          >
            {newItem.label}
          </Button>
        )}
      </Typography>
      <div
        style={{
          width: 500,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Buscar..."
          variant="outlined"
          inputRef={searchRef}
          size="small"
          onChange={handleChangeText}
        />
        {searchRef.current && searchRef.current.value && (
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => handleChangeSearchText('')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Tooltip title="Filtrar">
        <IconButton
          onClick={() =>
            handleChangeSearchText(searchRef.current && searchRef.current.value)
          }
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

const newItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
});

TableListToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  searchRef: PropTypes.shape().isRequired,
  handleChangeSearchText: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

TableListToolbar.defaultProps = {
  newItem: null,
  canEdit: true,
};

function TableListHead({
  classes,
  order,
  orderBy,
  onRequestSort,
  headCells,
  actions,
  select,
}) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.image ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {select ? (
          <TableCell align="center">Selecionar</TableCell>
        ) : (
          actions && <TableCell align="center">Ações</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

const actionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    handleFunction: PropTypes.func.isRequired,
  })
);

const customStatusPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  component: PropTypes.elementType,
});

const selectPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
});

const cellsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    image: PropTypes.bool,
  })
);

TableListHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: cellsPropType,
  actions: actionsPropType,
  customStatus: customStatusPropTypes,
  select: selectPropType,
};

TableListHead.defaultProps = {
  headCells: null,
  actions: null,
  customStatus: null,
  select: null,
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row({ row, headCells, otherCells, actions, select, customStatus }) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = action => {
    action.handleFunction(row);
    handleClose();
  };

  const handleSelectItem = () => {
    select.handleFunction(row);
  };

  return (
    <>
      <TableRow hover className={classes.root}>
        {otherCells ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <div
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {headCell.image ? (
                <ImageContainer
                  src={[row[headCell.id]]}
                  style={{ borderRadius: 4 }}
                />
              ) : (
                row[headCell.id]
              )}
            </div>
          </TableCell>
        ))}
        {select ? (
          <TableCell align="center">
            <IconButton onClick={handleSelectItem}>
              {row.icon || select.icon}
            </IconButton>
          </TableCell>
        ) : (
          actions && (
            <TableCell align="center">
              <IconButton onClick={handleOpenMenu}>
                <MenuOpenIcon />
              </IconButton>
              {!!actions.length && (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.label}
                      onClick={() => handleSelectMenu(action)}
                      disabled={!!action.disabled}
                    >
                      {action.icon}
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      {otherCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (otherCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Outras Informações
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {otherCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row.others[otherCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape().isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  actions: actionsPropType,
  customStatus: customStatusPropTypes,
  select: selectPropType,
};

Row.defaultProps = {
  otherCells: null,
  actions: null,
  customStatus: null,
  select: null,
};

function TableListBody({
  rows,
  page,
  rowsPerPage,
  headCells,
  otherCells,
  actions,
  customStatus,
  select,
}) {
  return (
    <TableBody>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => (
          <Row
            key={row.id}
            row={row}
            headCells={headCells}
            otherCells={otherCells}
            actions={actions}
            customStatus={customStatus}
            selec
            select={select}
          />
        ))}
    </TableBody>
  );
}

TableListBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  actions: actionsPropType,
  customStatus: customStatusPropTypes,
  select: selectPropType,
};

TableListBody.defaultProps = {
  otherCells: null,
  rows: [],
  actions: null,
  customStatus: null,
  select: true,
};

const useTableStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: 700,
    display: 'flex',
    height: '99%',
    position: 'relative',
  },
  loadingWrapper: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    top: 70,
    bottom: 70,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: 'calc(100vh - 230px)',
  },
  tableContainerDash: {
    height: 'calc(50vh - 80px)',
  },
  table: {
    minWidth: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableList({
  title,
  newItem,
  headCells,
  otherCells,
  data,
  loadData,
  loading,
  actions,
  customStatus,
  select,
  canEdit,
}) {
  const classes = useTableStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation();

  const searchRef = useRef({ value: '' });

  const rows = useMemo(
    () =>
      data.map(item => {
        const itemParsed = { ...item, others: {} };

        if (otherCells)
          otherCells.forEach(otherCell => {
            itemParsed.others[otherCell.id] = item[otherCell.id];
          });
        else delete itemParsed.others;

        return itemParsed;
      }),
    [otherCells, data]
  );

  const handleChangeSearchText = text => {
    if (searchRef.current) searchRef.current.value = text;
    debounceEvent(() => loadData(text.trim(), orderBy, order), 500)();
  };

  const handleRequestSort = (event, property) => {
    const orderAux = orderBy === property && order === 'asc' ? 'desc' : 'asc';

    setOrder(orderAux);
    setOrderBy(property);

    loadData(searchRef.current && searchRef.current.value, property, orderAux);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      )}
      <Paper className={classes.paper}>
        <TableListToolbar
          title={title}
          newItem={newItem}
          searchRef={searchRef}
          handleChangeSearchText={handleChangeSearchText}
          canEdit={canEdit}
        />
        <TableContainer
          className={
            location.pathname !== '/'
              ? classes.tableContainer
              : classes.tableContainerDash
          }
        >
          <Table stickyHeader size="small" className={classes.table}>
            <TableListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              actions={actions}
              select={select}
            />
            <TableListBody
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              headCells={headCells}
              otherCells={otherCells}
              actions={actions}
              customStatus={customStatus}
              select={select}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default TableList;
export { WrapperContainer } from './styles';

TableList.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  actions: actionsPropType,
  customStatus: actionsPropType,
  select: selectPropType,
  canEdit: PropTypes.bool,
};

TableList.defaultProps = {
  newItem: null,
  otherCells: null,
  data: [],
  loadData: () => {},
  loading: false,
  select: null,
  actions: null,
  customStatus: null,
  canEdit: true,
};
