import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, MessageContainer } from './styles';
import { useOrder } from '~/providers/order';

export default function ShowOrderMessageDialog({ showDialog, setShowDialog }) {
  const { order } = useOrder();

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <MessageContainer>
        <h3>
          {order.erp_message ||
            'Não há informações de status detalhadas neste Pedido de Venda.'}
        </h3>
      </MessageContainer>
    </Dialog>
  );
}

ShowOrderMessageDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
