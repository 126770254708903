import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '~/providers/auth';

import {
  adminPermissions,
  managerPermissions,
} from '~/utils/constantVariables';

import { Container } from './styles';

import Navigation from '~/components/Navigation';

import Profile from '~/pages/Profile';
import Dashboard from '~/pages/Dashboard';
import CompanyBranches from '~/pages/CompanyBranches';
import Shares from '~/pages/Shares';
import SharesForm from '~/pages/Shares/Form';
import Users from '~/pages/Users';
import UsersForm from '~/pages/Users/Form';
import CustomerGroups from '~/pages/CustomerGroups';
import CustomerGroupsForm from '~/pages/CustomerGroups/Form';
import Customers from '~/pages/Customers';
import CustomersForm from '~/pages/Customers/Form';
import ProductGroups from '~/pages/ProductGroups';
import ProductGroupsForm from '~/pages/ProductGroups/Form';
import UnitOfMeasures from '~/pages/UnitOfMeasures';
import UnitOfMeasuresForm from '~/pages/UnitOfMeasures/Form';
import Products from '~/pages/Products';
import ProductsForm from '~/pages/Products/Form';
import PaymentMethods from '~/pages/PaymentMethods';
import PaymentMethodsForm from '~/pages/PaymentMethods/Form';
import GenericTables from '~/pages/GenericTables';
import GenericTablesForm from '~/pages/GenericTables/Form';
import FreightTables from '~/pages/FreightTables';
import FreightTablesForm from '~/pages/FreightTables/Form';
import Orders from '~/pages/Orders';
import OrdersForm from '~/pages/Orders/Form';
import Parameters from '~/pages/Parameters';
import ParametersForm from '~/pages/Parameters/Form';
import Reports from '~/pages/Reports';
import ReportsForm from '~/pages/Reports/Form';

function Onn() {
  const location = useLocation();
  const history = useHistory();

  const { user, current_accesses } = useAuth();

  useEffect(() => {
    const pathname = location.pathname.split('/');
    let currentPath = '';
    let currentPath2 = '';
    let currentPath3 = '';
    let currentPath4 = '';

    if (pathname.length === 2) {
      currentPath = `facin.${pathname[1]}.get`;
      currentPath2 = `facin.${pathname[1]}/all.get`;
      currentPath3 = `facin.${pathname[1]}/own-seller.get`;
      currentPath4 = `facin.${pathname[1]}/without-seller.get`;
    } else if (pathname.length === 3) {
      if (
        !location.state ||
        (location.state &&
          Object.keys(location.state || {}).some(state =>
            state.startsWith('new')
          ))
      )
        currentPath = `facin.${pathname[1]}.post`;
      if (
        location.state ||
        Object.keys(location.state || {}).some(state =>
          state.startsWith('show')
        )
      ) {
        currentPath = `facin.${pathname[1]}.get`;
        currentPath2 = `facin.${pathname[1]}/all.get`;
        currentPath3 = `facin.${pathname[1]}/own-seller.get`;
        currentPath4 = `facin.${pathname[1]}/without-seller.get`;
      } else if (
        location.state &&
        Object.keys(location.state || {}).some(state =>
          state.startsWith('update')
        )
      )
        currentPath = `facin.${pathname[1]}.put`;
    }

    if (
      (!user.is_admin &&
        (adminPermissions[currentPath] ||
          adminPermissions[currentPath2] ||
          adminPermissions[currentPath3] ||
          adminPermissions[currentPath4])) ||
      (!user.is_admin &&
        !user.is_manager &&
        (managerPermissions[currentPath] ||
          managerPermissions[currentPath2] ||
          managerPermissions[currentPath3] ||
          managerPermissions[currentPath4]))
    )
      history.push('/');

    if (
      !user.is_admin &&
      !user.is_manager &&
      !current_accesses[currentPath] &&
      !current_accesses[currentPath2] &&
      !current_accesses[currentPath3] &&
      !current_accesses[currentPath4] &&
      pathname.length > 1 &&
      pathname[1] !== 'company-branches' &&
      pathname[1] !== 'profile'
    )
      history.push('/');
  }, [current_accesses, history, location.pathname, location.state, user]);

  return (
    <Switch>
      <Route exact path="/company-branches" component={CompanyBranches} />
      <Navigation>
        <Container>
          <Switch>
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/shares" component={Shares} />
            <Route exact path="/shares/form" component={SharesForm} />
            <Route exact path="/generic-tables" component={GenericTables} />
            <Route
              exact
              path="/generic-tables/form"
              component={GenericTablesForm}
            />
            <Route exact path="/users" component={Users} />
            <Route exact path="/users/form" component={UsersForm} />
            <Route exact path="/customer-groups" component={CustomerGroups} />
            <Route
              exact
              path="/customer-groups/form"
              component={CustomerGroupsForm}
            />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/customers/form" component={CustomersForm} />
            <Route exact path="/product-groups" component={ProductGroups} />
            <Route
              exact
              path="/product-groups/form"
              component={ProductGroupsForm}
            />

            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports/form" component={ReportsForm} />
            <Route exact path="/unit-of-measures" component={UnitOfMeasures} />
            <Route
              exact
              path="/unit-of-measures/form"
              component={UnitOfMeasuresForm}
            />
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/form" component={ProductsForm} />
            <Route exact path="/payment-methods" component={PaymentMethods} />
            <Route
              exact
              path="/payment-methods/form"
              component={PaymentMethodsForm}
            />
            <Route exact path="/freight-tables" component={FreightTables} />
            <Route
              exact
              path="/freight-tables/form"
              component={FreightTablesForm}
            />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/orders/form" component={OrdersForm} />

            <Route exact path="/parameters" component={Parameters} />
            <Route exact path="/parameters/form" component={ParametersForm} />

            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports/form" component={ReportsForm} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Container>
      </Navigation>
    </Switch>
  );
}

export default Onn;
