import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { useProductGroup } from '~/providers/productGroup';

import { validate } from '~/validators/productGroup';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/productGroup';

import { Form, Button, Loading, TextInput } from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

function ProductGroupForm({ tablecolor }) {
  const location = useLocation();

  const {
    productGroup,
    productGroupLoading,
    productGroupErrors,
    store,
    update,
    clearState,
  } = useProductGroup();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedProductGroup = {
        ...parsedData,
        req_id: '1',
      };

      if (productGroup.uuid)
        await update({
          productGroup: { ...productGroup, ...parsedProductGroup },
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedProductGroup.company_key = company_key;
        parsedProductGroup.branch_key = branch_key;

        await store({ productGroup: parsedProductGroup });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(productGroupErrors).length &&
      productGroupErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(productGroupErrors.validations);
  }, [productGroupErrors]);

  useEffect(() => {
    if (productGroup.uuid) {
      const auxProductGroup = { ...productGroup };

      const formData = parseFormData(auxProductGroup);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showProductGroup) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newProductGroup) {
      setCanEdit(true);
    }
  }, [productGroup, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
              </FormSingleContent>
              <FormSingleContent>
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <Button
            disabled={productGroupLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {productGroupLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default ProductGroupForm;
ProductGroupForm.propTypes = {
  tablecolor: PropTypes.bool,
};

ProductGroupForm.defaultProps = {
  tablecolor: true,
};
