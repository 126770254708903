import { numberMask, valueMask, valueUnmask } from './mask';

export function parseToView(paymentMethods = []) {
  return paymentMethods.map(paymentMethod => {
    const paymentsDict = {
      cash: 'attach_money',
      bank_transfers: 'account_balance',
      credit_card: 'credit_card',
      debit_card: 'payment',
      billet: 'insert_drive_file',
    };

    const icon = paymentsDict[paymentMethod.type] || 'local_atm';

    return {
      ...paymentMethod,
      start_discount_percentage: valueMask(
        paymentMethod.start_discount_percentage,
        2
      ),
      max_discount_percentage: valueMask(
        paymentMethod.max_discount_percentage,
        2
      ),
      quantity_installment: numberMask(paymentMethod.quantity_installment, 2),
      min_per_installment: valueMask(paymentMethod.min_per_installment, 2),
      icon,
    };
  });
}

export function parseDataToApi(paymentMethod = {}) {
  return {
    ...paymentMethod,
    start_discount_percentage: valueUnmask(
      paymentMethod.start_discount_percentage
    ),
    max_discount_percentage: valueUnmask(paymentMethod.max_discount_percentage),
    quantity_installment: numberMask(paymentMethod.quantity_installment),
    min_per_installment: valueUnmask(paymentMethod.min_per_installment),
  };
}

export function parseFormData(paymentMethod = {}) {
  return {
    legacy_code: paymentMethod.legacy_code,
    name: paymentMethod.name,
    start_discount_percentage: paymentMethod.start_discount_percentage,
    max_discount_percentage: paymentMethod.max_discount_percentage,
    quantity_installment: paymentMethod.quantity_installment,
    term_installment: paymentMethod.term_installment,
    min_per_installment: paymentMethod.min_per_installment,
  };
}
