import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';
import { useProduct } from '../product';

import * as actions from './actions';

const INITIAL_STATE = {
  orderItems: [],
  orderItem: {},
  orderItemErrors: {},
  orderItemLoading: false,
  orderItemListLoading: false,
};

const OrderItemContext = createContext(INITIAL_STATE);

export function OrderItemProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const { setProductData } = useProduct();

  const [data, setData] = useState(INITIAL_STATE);

  const setOrderItemData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({
      orderItems: newData.orderItems || oldData.orderItems,
      orderItem: newData.orderItem || oldData.orderItem,
      orderItemErrors: newData.orderItemErrors || oldData.orderItemErrors,
      orderItemLoading:
        newData.orderItemLoading || newData.orderItemLoading === false
          ? newData.orderItemLoading
          : oldData.orderItemLoading,
      orderItemListLoading:
        newData.orderItemListLoading || newData.orderItemListLoading === false
          ? newData.orderItemListLoading
          : oldData.orderItemListLoading,
    }));
  }, []);

  const index = useCallback(
    async ({ order_uuid = '', search = '', order_by = '', order = '' }) => {
      setOrderItemData({ orderItemListLoading: true });

      const orderItemData = await actions.index({
        order_uuid,
        search,
        order_by,
        order,
      });

      if (orderItemData.orderItemErrors)
        setErrorHandlerData({
          error: {
            ...orderItemData.orderItemErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setOrderItemData({
        ...orderItemData,
        orderItemListLoading: false,
      });
    },
    [setOrderItemData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ orderItemUuid = '' }) => {
      setOrderItemData({ orderItemLoading: true });

      const orderItemData = await actions.show({ orderItemUuid });

      if (orderItemData.orderItemErrors) {
        setErrorHandlerData({
          error: {
            ...orderItemData.orderItemErrors,
            resolveFunction: () => show({ orderItemUuid }),
          },
        });
      } else
        setProductData({
          product: orderItemData.orderItem.product,
        });

      setOrderItemData({
        ...orderItemData,
        orderItemLoading: false,
      });
    },
    [setOrderItemData, setErrorHandlerData, setProductData]
  );

  const store = useCallback(
    async ({ orderItem = {} }) => {
      setOrderItemData({ orderItemLoading: true });

      const orderItemData = await actions.store({ orderItem });

      if (orderItemData.orderItemErrors) {
        setErrorHandlerData({
          error: {
            ...orderItemData.orderItemErrors,
            resolveFunction: () => store({ orderItem }),
          },
        });

        setOrderItemData({
          ...orderItemData,
          orderItemLoading: false,
        });

        return null;
      }

      setOrderItemData({ orderItemLoading: false });

      toast.success('Item do pedido cadastrado com sucesso!');

      return orderItemData;
    },
    [setOrderItemData, setErrorHandlerData]
  );

  const update = useCallback(
    async ({ orderItem = {} }) => {
      setOrderItemData({ orderItemLoading: true });

      const orderItemData = await actions.update({ orderItem });

      if (orderItemData.orderItemErrors) {
        setErrorHandlerData({
          error: {
            ...orderItemData.orderItemErrors,
            resolveFunction: () => update({ orderItem }),
          },
        });

        setOrderItemData({
          ...orderItemData,
          orderItemLoading: false,
        });

        return null;
      }

      setOrderItemData({ orderItemLoading: false });

      toast.success('Item do pedido atualizado com sucesso!');

      return orderItemData;
    },
    [setOrderItemData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ orderItem = {} }) => {
      setOrderItemData({ orderItemLoading: true });

      const orderItemData = await actions.destroy({ orderItem });

      if (orderItemData.orderItemErrors) {
        setErrorHandlerData({
          error: {
            ...orderItemData.orderItemErrors,
            resolveFunction: () => destroy({ orderItem }),
          },
        });

        setOrderItemData({
          ...orderItemData,
          orderItemLoading: false,
        });

        return false;
      }

      setOrderItemData({ orderItemLoading: false });

      toast.success('Item do pedido removido com sucesso!');

      return true;
    },
    [setOrderItemData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        orderItem: {},
        orderItemErrors: {},
        orderItemLoading: false,
        orderItemListLoading: false,
      };
    });
  }, []);

  return (
    <OrderItemContext.Provider
      value={{
        ...data,
        setOrderItemData,
        index,
        show,
        store,
        update,
        destroy,
        clearState,
      }}
    >
      {children}
    </OrderItemContext.Provider>
  );
}

export function useOrderItem() {
  const context = useContext(OrderItemContext);

  if (!context)
    throw new Error('useOrderItem must be used within an OrderItemProvider');

  return context;
}

OrderItemProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
