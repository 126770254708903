import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import SwipViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';

import PaperImport from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';

export { default as Tabs } from '@material-ui/core/Tabs';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const SwipeableViews = styled(SwipViews).attrs({})``;

export const RegisterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const DataContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 1em;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }

  form {
    display: flex;
    fieldset {
      align-items: start;
      label {
        margin: 0;
        flex-direction: row;
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 1em;
  gap: 20px;
`;

export const DataFormContainer = styled.div`
  display: flex;
  align-items: start;
`;

export const FormContent = styled.ul`
  width: 100%;
`;

export const FormSingleContent = styled(Grid)`
  text-align: left;
  background: ${props =>
    props.table ? props.theme.colors.backFirst : props.theme.colors.backSecond};
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  padding: 1em 0.3em 0 0.3em;
  display: flex;
  align-items: start;
  gap: 0 50px;
  justify-content: start;
  flex: 1;
`;

export const Paper = styled(PaperImport).attrs({})`
  background: ${props =>
    props.formulary
      ? props.theme.colors.secondary
      : props.theme.colors.primary};
  margin-bottom: 4px;
  box-shadow: none;
`;

export const TabText = styled(Tab)`
  color: ${props =>
    props.formulary
      ? props.theme.colors.backFirst
      : props.theme.colors.backFirst} !important;

  .MuiTab-wrapper {
    flex-direction: row;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const NavigationContent = styled.div`
  background: ${props => props.theme.colors.secondary};
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
`;

export const ContentContainer = styled.div`
  height: calc(90vh - 20px);
`;
