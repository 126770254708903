import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  shares: [],
  share: {},
  shareErrors: {},
  shareLoading: false,
  shareListLoading: false,
};

const ShareContext = createContext(INITIAL_STATE);

export function ShareProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setShareData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setShareData({ shareListLoading: true });

      const shareData = await actions.index({
        search,
        order_by,
        order,
      });

      if (shareData.shareErrors)
        setErrorHandlerData({
          error: {
            ...shareData.shareErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setShareData({ ...shareData, shareListLoading: false });
    },
    [setShareData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ shareUuid = '' }) => {
      setShareData({ shareLoading: true });

      const shareData = await actions.show({ shareUuid });

      if (shareData.shareErrors) {
        setErrorHandlerData({
          error: {
            ...shareData.shareErrors,
            resolveFunction: () => show({ shareUuid }),
          },
        });

        history.goBack();
      }

      setShareData({ ...shareData, shareLoading: false });
    },
    [setShareData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ share = {} }) => {
      setShareData({ shareLoading: true });

      const shareData = await actions.store({ share });

      if (shareData.shareErrors) {
        setErrorHandlerData({
          error: {
            ...shareData.shareErrors,
            resolveFunction: () => store({ share }),
          },
        });

        setShareData({ ...shareData, shareLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          shares: [shareData, ...oldData.shares],
          shareLoading: false,
        }));

        history.goBack();
      }
    },
    [setShareData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ share = {} }) => {
      setShareData({ shareLoading: true });

      const shareData = await actions.update({ share });

      if (shareData.shareErrors) {
        setErrorHandlerData({
          error: {
            ...shareData.shareErrors,
            resolveFunction: () => update({ share }),
          },
        });

        setShareData({ ...shareData, shareLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          shares: [
            shareData,
            ...oldData.shares.filter(auxShare => auxShare.uuid !== share.uuid),
          ],
          shareLoading: false,
        }));

        history.goBack();
      }
    },
    [setShareData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ share = {} }) => {
      setShareData({ shareListLoading: true });

      const shareData = await actions.destroy({ share });

      if (shareData.shareErrors) {
        setErrorHandlerData({
          error: {
            ...shareData.shareErrors,
            resolveFunction: () => destroy({ share }),
          },
        });

        setShareData({ ...shareData, shareListLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          shares: [
            ...oldData.shares.filter(auxShare => auxShare.uuid !== share.uuid),
          ],
          shareListLoading: false,
        }));
      }
    },
    [setShareData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        share: {},
        shareErrors: {},
        shareLoading: false,
        shareListLoading: false,
      };
    });
  }, []);

  return (
    <ShareContext.Provider
      value={{
        ...data,
        setShareData,
        index,
        show,
        store,
        update,
        destroy,
        clearState,
      }}
    >
      {children}
    </ShareContext.Provider>
  );
}

export function useShare() {
  const context = useContext(ShareContext);

  if (!context)
    throw new Error('useShare must be used within an ShareProvider');

  return context;
}

ShareProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
