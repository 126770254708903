import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useParameter } from '~/providers/parameter';
import { useGenericTable } from '~/providers/genericTable';

import errorHandler from '~/utils/errorHandler';
import { validate } from '~/validators/parameter';

import {
  Form,
  Button,
  Loading,
  Selector,
  TextInput,
  Checkbox,
  ValueInput,
  DatePicker,
  RadioGroup,
  ImagePicker,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
  ButtonContent,
} from './styles';

function ParametersForm({ tablecolor }) {
  const location = useLocation();
  const history = useHistory();

  const {
    parameter,
    parameterLoading,
    parameterErrors,
    store,
    update,
    newParameter,
    clearState,
  } = useParameter();
  const { indexSelector: genericTableIndexSelector } = useGenericTable();
  const { logged_branch } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);
  const [genericTableLoading, setGenericTableLoading] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [parameterSelect, setParameterSelect] = useState({});
  const [checkedInput, setCheckedInput] = useState(false);
  const [radioGroupInput, setRadioGroupInput] = useState('');

  const InputParsed = useCallback(() => {
    const valueInput = formRef?.current?.getFieldValue('value');

    const defaultProps = {
      helper: parameterSelect?.value1,
      disabled: !canEdit,
      options: [],
      label: 'Valor',
      name: 'value',
      defaultValue: valueInput,
      ...parameterSelect.value2?.props,
    };

    const value2 = parameterSelect?.value2;
    const options = value2?.props?.options;

    const defaultValue = options?.find(
      option => option.value === parameter.value
    );

    const inputDict = {
      ValueInput: <ValueInput style={{ width: '500px' }} {...defaultProps} />,
      Checkbox: <Checkbox {...defaultProps} defaultValue={checkedInput} />,
      DatePicker: <DatePicker {...defaultProps} />,
      RadioGroup: (
        <RadioGroup {...defaultProps} defaultValue={radioGroupInput} />
      ),
      TextInput: <TextInput style={{ width: '500px' }} {...defaultProps} />,
      Selector: (
        <Selector
          style={{ width: '500px' }}
          idColumn="value"
          nameColumn="label"
          {...defaultProps}
          defaultValue={defaultValue}
        />
      ),
      ImagePicker: <ImagePicker {...defaultProps} />,
    };

    return (
      inputDict[parameterSelect.value2?.component] || (
        <TextInput
          style={{ width: '500px' }}
          name="value"
          label="Valor"
          disabled
          helper="Selecione um Parâmetro antes"
        />
      )
    );
  }, [parameterSelect, checkedInput, radioGroupInput, parameter, canEdit]);

  const loadParameters = useCallback(
    async (search = '') => {
      setGenericTableLoading(true);
      const auxParameters = await genericTableIndexSelector({
        search,
        table: '0000000005',
      });
      setGenericTableLoading(false);
      setParameters(auxParameters);
    },
    [genericTableIndexSelector]
  );

  const handleChangeParameter = useCallback(auxParameter => {
    if (formRef.current) {
      formRef.current.setFieldValue('value', '');
    }

    if (auxParameter) {
      try {
        const value2 = JSON.parse(auxParameter.value2);
        const value3 = JSON.parse(auxParameter.value3);

        setParameterSelect({ ...auxParameter, value2, value3 });
      } catch (err) {
        errorHandler({
          err,
          title: 'Error ao selecionar um parâmetro.',
          local: 'ParametersPagesHandleChangeParameter',
        });
      }
    } else setParameterSelect({});
  }, []);

  async function handleSubmit(data) {
    const dataToValidade = {
      ...parameter,
      ...data,
      parameter: parameterSelect,
    };

    const { errorMessages, ...parsedData } = await validate(dataToValidade);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedParameter = {
        ...parsedData,
        req_id: '1',
        company_key: logged_branch.company_key,
        branch_key: logged_branch.branch_key,
      };

      if (parameter.uuid) await update({ parameter: parsedParameter });
      else await store({ parameter: parsedParameter });
    }
  }

  useEffect(() => {
    if (
      Object.keys(parameterErrors).length &&
      parameterErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(parameterErrors.validations);
  }, [parameterErrors]);

  useEffect(() => {
    async function loadData() {
      const { parameters: auxParameters } = await newParameter();

      setParameters(auxParameters);
    }

    if (parameter.uuid) {
      const { parameter: auxParameter, parameters: auxParameters } = parameter;

      setParameters(auxParameters);
      setParameterSelect(auxParameter);

      const typeInputRef = formRef.current.getFieldRef('parameter_uuid');
      typeInputRef.handleChange(auxParameter);

      formRef.current.setFieldValue('value', parameter.value);
      setCheckedInput(parameter?.value === 'true');
      setRadioGroupInput(parameter?.value);

      if (location.state && !location.state.showParameter) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newParameter) {
      setCanEdit(true);
    }
    loadData();
  }, [location, parameter, newParameter]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Selector
                  name="parameter_uuid"
                  label="Parâmetro"
                  idColumn="uuid"
                  nameColumn="name"
                  onChange={handleChangeParameter}
                  options={parameters}
                  loadData={loadParameters}
                  loading={genericTableLoading || parameterLoading}
                  required
                  disabled={!canEdit}
                  style={{ width: '500px' }}
                />
                <InputParsed />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <ButtonContent>
            <Button
              disabled={parameterLoading || !canEdit}
              type="button"
              variant="outlined"
              color="default"
              onClick={() => history.goBack()}
            >
              Cancelar
              {parameterLoading && <Loading />}
            </Button>
            <Button
              disabled={parameterLoading || !canEdit}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Confirmar
              {parameterLoading && <Loading />}
            </Button>
          </ButtonContent>
        </Form>
      </DataContent>
    </Container>
  );
}

export default ParametersForm;

ParametersForm.propTypes = {
  tablecolor: PropTypes.bool,
};

ParametersForm.defaultProps = {
  tablecolor: true,
};
