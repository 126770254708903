import React from 'react';
import { ThemeProvider as StyledTheme } from 'styled-components';
import {
  ThemeProvider as MaterialTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

import 'react-toastify/dist/ReactToastify.min.css';
import './config/reactotron';
import 'date-fns';

import { useTheme } from '~/providers/theme';

import Routes from './routes';
import GlobalStyle from './styles/global';

import lightTheme from './styles/themes/light';
import darkTheme from './styles/themes/dark';

import ErrorHandler from './components/ErrorHandler';

function App() {
  const { theme } = useTheme();
  const themeStyled = theme === 'light' ? lightTheme : darkTheme;
  const themeMaterial = createMuiTheme(
    {
      palette: {
        type: theme === 'light' ? 'light' : 'dark',
        primary: { main: theme === 'light' ? '#1f7ecc' : '#061928' },
        secondary: { main: theme === 'light' ? '#061928' : '#1f7ecc' },
      },
    },
    ptBR
  );

  return (
    <StyledTheme theme={themeStyled}>
      <MaterialTheme theme={themeMaterial}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
          <Routes />
          <GlobalStyle />
          <ToastContainer
            hideProgressBar
            closeOnClick
            style={{ borderRadius: '10px' }}
          />
          <ErrorHandler />
        </MuiPickersUtilsProvider>
      </MaterialTheme>
    </StyledTheme>
  );
}

export default App;
