import { numberMask } from './mask';

export function parseToView(genericTables = []) {
  return genericTables.map(genericTable => {
    let table_view = genericTable.table;

    if (table_view === '00') table_view = 'Cabeçalho';
    else if (table_view === '01') table_view = 'Não Venda';

    return {
      ...genericTable,
      table_view,
    };
  });
}

export function parseDataToApi(genericTable = {}) {
  return {
    ...genericTable,
    level: numberMask(genericTable.level),
  };
}

export function parseFormData(genericTable = {}) {
  return {
    legacy_code: genericTable.legacy_code,
    table_key: genericTable.table_key,
    name: genericTable.name,
    value1: genericTable.value1,
    value2: genericTable.value2,
    value3: genericTable.value3,
    value4: genericTable.value4,
    value5: genericTable.value5,
  };
}
