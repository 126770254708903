import styled from 'styled-components';
import Icn from '@material-ui/core/Icon';
import ButtonBase from '@material-ui/core/ButtonBase';

export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as TextField } from '@material-ui/core/TextField';

export { default as ArrowBack } from '@material-ui/icons/ArrowBack';
export { default as Block } from '@material-ui/icons/Block';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1px;

  height: 100vh;

  .loading-wrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 70px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 1em 2em;

  border-bottom: 1px solid #ececec;
`;

export const PaymentList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  width: 100%;

  padding: 1em 2em;
  gap: 20px;
`;

export const PaymentListItem = styled(ButtonBase).attrs({
  component: 'li',
})`
  &.MuiButtonBase-root {
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    justify-content: space-between;

    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);

    max-width: 300px;
    line-height: 1.5;
    padding: 1em;

    border-radius: 10px;

    cursor: pointer;

    .payment-info {
      display: flex;
      justify-content: space-between;

      width: 100%;

      border-bottom: 1px solid #ececec;

      padding-bottom: 0.5em;
      margin-bottom: 0.5em;

      h1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        text-overflow: ellipsis;
        font-size: 1rem;
      }
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      width: 100%;
    }
  }
`;

export const Icon = styled(Icn).attrs({
  color: 'primary',
})``;
