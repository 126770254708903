import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';
import { parseToView } from '~/utils/parameter';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    let parsedOrderBy = order_by;

    if (order_by.endsWith('_view')) {
      if (order_by === 'parameter_view') {
        parsedOrderBy = parsedOrderBy.replace('_view', '_uuid');
      } else {
        parsedOrderBy = parsedOrderBy.replace('_view', '');
      }
    }

    const { data } = await apiFacin
      .get('/parameters', {
        params: { search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { parameters: parseToView(data) };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao listar Parâmetros',
      local: 'ParametersActionsIndex',
    });

    return { parameterErrors };
  }
}

export async function show({ parameterUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/parameters/${parameterUuid}`)
      .then(res => res.data);

    const { parameter, parameters } = data;

    return { parameter: { ...parseToView([{ ...parameter }])[0], parameters } };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao visualizar Parâmetros',
      local: 'ParametersActionsShow',
    });

    return { parameterErrors };
  }
}

export async function store({ parameter = {} }) {
  try {
    const { data } = await apiFacin
      .post('/parameters', {
        data: [parameter],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    const parsedParameter = {
      ...parameter,
      parameter: {
        ...parameter.parameter,
        value2: JSON.stringify(parameter?.parameter?.value2),
      },
    };

    return parseToView([{ ...parsedParameter, ...data[0].parameter }])[0];
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Parâmetros',
      local: 'ParametersActionsStore',
    });

    return { parameterErrors };
  }
}

export async function update({ parameter = {} }) {
  try {
    const { data } = await apiFacin
      .put('/parameters', {
        data: [parameter],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    const parsedParameter = {
      ...parameter,
      parameter: {
        ...parameter.parameter,
        value2: JSON.stringify(parameter?.parameter?.value2),
      },
    };

    return parseToView([{ ...parsedParameter, ...data[0].parameter }])[0];
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao atualizar Parâmetros',
      local: 'ParametersActionsUpdate',
    });

    return { parameterErrors };
  }
}

export async function destroy({ parameter = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/parameters`,
      method: 'delete',
      data: { data: [parameter] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao remover Parâmetros',
      local: 'ParametersActionsDestroy',
    });

    return { parameterErrors };
  }
}

export async function newParameter() {
  try {
    const { data } = await apiFacin.get('/new-parameter').then(res => res.data);

    return data;
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo Parâmetro',
      local: 'ParameterActionsNewParameter',
    });

    return { parameterErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: parameters } = await apiFacin
      .get(`/parameters`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { parameters };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao listar Parâmetros',
      local: 'ParametersActionsIndexSelector',
    });

    return { parameterErrors };
  }
}
