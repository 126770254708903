import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/genericTable';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      table: Yup.string()
        .max(10, 'Máximo de 10 caracteres')
        .required('Este campo é obrigatório'),
      table_key: Yup.string()
        .max(10, 'Máximo de 10 caracteres')
        .required('Este campo é obrigatório'),
      name: Yup.string().required('Este campo é obrigatório'),
      value1: Yup.string().nullable(),
      value2: Yup.string().nullable(),
      value3: Yup.string().nullable(),
      value4: Yup.string().nullable(),
      value5: Yup.string().nullable(),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
