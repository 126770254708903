import React, { useCallback, useRef, useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useOrder } from '~/providers/order';
import { useOrderItem } from '~/providers/orderItem';
import { useFreightTable } from '~/providers/freightTable';
import { useNavigation } from '~/providers/navigation';

import { valueUnmask } from '~/utils/mask';
import { setTextAreaData } from '~/utils/textArea';
import { parseDataToApi } from '~/utils/order';

import noImage from '~/assets/images/noImage.png';
import Caixa from '~/assets/images/caixa.png';

import CartDetailDialog from '~/components/Dialogs/CartDetailDialog';
import OrderHeader from '~/components/OrderHeader';
import OrderItemDialog from '~/components/Dialogs/OrderItem/index';
import { Form, Selector } from '~/components/Form';
import {
  StatusContainer,
  Container,
  CircularProgress,
  ShoppingCart,
  CartList,
  CartItems,
  CartButton,
  Delete,
  CartResumeInfo,
  Icon,
  Radio,
  ShipSelectForm,
  FormControlLabel,
  ProductInfo,
  ProductPrice,
  Tooltip,
  IconButton,
  ProductImageInfo,
  Visibility,
  OrderDetails,
  SendEmail,
  Email,
  ApproveButton,
  RefuseButton,
  SaveButton,
  SaveButtonStatus,
  SwipeableViews,
  ArrowBack,
  TextField,
  DetailsContainer,
  PdfIcon,
  EmptyBoxContainer,
  HeaderContainer,
  Menu,
  MenuItem,
  Typography,
  DescriptionIcon,
  DescriptionOutlinedIcon,
} from './styles';

function EmptyBox() {
  return (
    <EmptyBoxContainer>
      <h2>Ops!</h2>
      <img src={Caixa} alt="Caixa Vazia" />
      <p>Seu carrinho está vazio</p>
    </EmptyBoxContainer>
  );
}

function Content({ canEdit, setChanging }) {
  const {
    order,
    show,
    update,
    updateStatus,
    orderLoading,
    sendEmail,
    generatePDF,
    generateFreightPDF,
    statusIndexSelector,
  } = useOrder();

  const {
    orderItems,
    destroy: orderItemDestroy,
    show: orderItemShow,
  } = useOrderItem();

  const formRef = useRef(null);
  const { freightTableValue } = useFreightTable();
  const { setPageIndex, pageIndex } = useNavigation();
  const [orderStatusLoading, setOrderStatusLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(undefined);

  const loadOrderStatus = useCallback(
    async (search = '') => {
      setOrderStatusLoading(true);
      const auxOrderStatus = await statusIndexSelector({ search });
      setOrderStatusLoading(false);
      setOrderStatus(auxOrderStatus);
    },
    [statusIndexSelector]
  );

  const [showProductDialog, setShowProductDialog] = useState(false);

  const [showDetailDialog, setShowDetailDialog] = useState(false);

  const [pageOrderIndex, setPageOrderIndex] = useState(0);
  const [freight_type, setFreightType] = useState('SEM');
  const [emailSended, setEmailSended] = useState(false);

  const observationRef = useRef({ value: '' });

  function handleChange(changing) {
    setChanging(changing);

    setPageIndex({ newPageIndex: 0 });
  }

  function handleDetailDialog() {
    setShowDetailDialog(true);
  }

  async function handleSelectOrderItem(orderItem) {
    setShowProductDialog(true);

    await orderItemShow({ orderItemUuid: orderItem.uuid });
  }
  async function handleSave() {
    if (orderItems.length === 0) {
      toast.warn('Não é possível salvar um pedido sem itens no carrinho.');
    } else {
      await update({
        order: {
          ...parseDataToApi(order),
          observation: observationRef.current.value,
          status_uuid: formRef.current.getFieldRef('status_uuid').value,
        },
        goBack: true,
      });
    }
  }

  async function handleFinalize() {
    if (orderItems.length === 0) {
      toast.warn('Não é possível finalizar um pedido sem itens no carrinho.');
    } else {
      await update({
        order: {
          ...parseDataToApi(order),
          observation: observationRef.current.value,
          status_code: 2,
        },
        goBack: true,
      });
    }
  }

  async function handleApprove() {
    await update({
      order: {
        ...parseDataToApi(order),
        observation: observationRef.current.value,
        status_code: 3,
      },
      goBack: true,
    });
  }

  async function handleDecline() {
    await update({
      order: {
        ...parseDataToApi(order),
        observation: observationRef.current.value,
        status_code: 4,
      },
      goBack: true,
    });
  }

  async function handleSendMail() {
    setEmailSended(true);

    await sendEmail({
      order: {
        ...parseDataToApi(order),
      },
    });
  }

  function handleCloseMenu() {
    setIsMenuOpen(undefined);
  }

  function handleOpenMenu(ref) {
    setIsMenuOpen(ref);
  }

  async function handleGeneratePDF() {
    handleCloseMenu();

    await generatePDF({
      order: {
        ...parseDataToApi(order),
      },
    });
  }
  async function handleGenerateFreightPDF() {
    handleCloseMenu();

    await generateFreightPDF({
      order: {
        ...parseDataToApi(order),
      },
    });
  }

  async function handleChangeStatus(data) {
    if (orderItems.length === 0) {
      toast.warn(
        'Não é possível alterar o status de um pedido sem itens no carrinho.'
      );
    } else {
      await updateStatus({
        order: {
          ...parseDataToApi({ ...order, status_uuid: data.status_uuid }),
        },
        goBack: false,
      });
    }
  }

  async function handleChangeFreightType(event) {
    const type = event.target.value;
    const oldType = freight_type;

    setFreightType(type);

    if (type !== 'FOB') {
      await update({
        order: {
          ...parseDataToApi(order),
          freight_type: type === 'SEM' ? null : type,
          freight_table_uuid: null,
          freight_table_value: null,
          observation: observationRef.current.value,
        },
      });
    } else {
      const gross_weight = valueUnmask(order.gross_weight);

      const response = await freightTableValue({
        customer_uuid: order.customer_uuid,
        gross_weight,
      });

      if (response.freightTable) {
        const { uuid, value } = response.freightTable;

        const freight_value = (gross_weight / 1000) * value;

        await update({
          order: {
            ...parseDataToApi(order),
            freight_type: type,
            freight_table_uuid: uuid,
            freight_value,
            observation: observationRef.current.value,
          },
        });
      } else {
        setFreightType(oldType);

        if (!response.freightTableErrors)
          toast.warn('Não foi possível encontra uma tabela de frete');
      }
    }
  }

  async function handleDestroyOrderItem(orderItem) {
    if (await orderItemDestroy({ orderItem }))
      await show({ orderUuid: orderItem.order_uuid });
  }
  useEffect(() => {
    if (order.uuid) {
      if (order.statuses) {
        setOrderStatus(order.statuses);
      }

      if (!observationRef.current.value && order.observation)
        setTextAreaData({
          inputRef: observationRef.current,
          value: order.observation,
        });

      setFreightType(order.freight_type || 'SEM');
      if (formRef.current && order.status_uuid !== '') {
        const statusInputRef = formRef.current.getFieldRef('status_uuid');
        statusInputRef.handleChange(order.status);
      }
    }
  }, [order, orderItems]);

  return (
    <Container>
      <iframe style={{ display: 'none' }} id="Iframe" title="Iframe" />
      <HeaderContainer>
        <OrderHeader pageIndex={pageIndex} setPageIndex={setPageIndex} />
        {orderLoading && (
          <div className="loading-wrapper">
            <CircularProgress />
          </div>
        )}
      </HeaderContainer>
      <OrderItemDialog
        showDialog={showProductDialog}
        setShowDialog={setShowProductDialog}
      />
      <CartDetailDialog
        order={order}
        showDetailDialog={showDetailDialog}
        setShowDetailDialog={setShowDetailDialog}
      />
      <ShoppingCart>
        <CartList>
          {orderItems.map(orderItem => (
            <CartItems key={orderItem.uuid}>
              <ProductImageInfo
                disabled={
                  !canEdit ||
                  orderLoading ||
                  (order.status_code !== 1 && order.status_code !== 4)
                }
                onClick={() => handleSelectOrderItem(orderItem)}
              >
                <img
                  src={
                    orderItem.product &&
                    orderItem.product.product_images &&
                    orderItem.product.product_images.length
                      ? orderItem.product.product_images[0].image
                      : noImage
                  }
                  alt={orderItem.product ? orderItem.product.name : '###'}
                />
                <div className="name-info">
                  <h1>{orderItem.product && orderItem.product.name}</h1>
                  <p>
                    REF: {orderItem.product && orderItem.product.reference_code}
                  </p>
                  <p>Quantidade: {orderItem.product && orderItem.quantity}</p>
                  <p>
                    Desconto:{' '}
                    {(orderItem.product && orderItem.discount_percentage) ||
                      '0,00'}{' '}
                    %
                  </p>
                </div>
              </ProductImageInfo>
              <div className="product-subtotal">
                <p>Subtotal: R$ {orderItem.total_net_value}</p>
                <Tooltip title="Remover">
                  <span>
                    <IconButton
                      size="small"
                      disabled={
                        !canEdit ||
                        orderLoading ||
                        (order.status_code !== 1 && order.status_code !== 4)
                      }
                      onClick={() => handleDestroyOrderItem(orderItem)}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </CartItems>
          ))}
          {orderItems.length === 0 && (
            <div style={{ padding: '1em', textAlign: 'center' }}>
              <h2 style={{ fontWeight: '400' }}>
                {orderLoading ? '' : <EmptyBox />}
              </h2>
            </div>
          )}
        </CartList>
        <CartResumeInfo>
          <SwipeableViews index={pageOrderIndex}>
            <div className="wrapper">
              <h1>Resumo da Venda</h1>
              <ProductInfo
                disabled={
                  !canEdit ||
                  orderLoading ||
                  (order.status_code !== 1 && order.status_code !== 4)
                }
                onClick={() => handleChange('customer')}
              >
                <h2>{order.customer ? order.customer.first_name : '###'}</h2>
                <p>CPNJ: {order.customer ? order.customer.cpf_cnpj : '###'}</p>
                <p>{order.customer ? order.customer.address : '###'}</p>
              </ProductInfo>
              <ProductPrice
                disabled={
                  !canEdit ||
                  orderLoading ||
                  (order.status_code !== 1 && order.status_code !== 4)
                }
                onClick={() => handleChange('payment_method')}
              >
                <h2>Pagamento</h2>
                <div className="price-info">
                  <p>
                    {order.payment_method ? order.payment_method.name : '###'}
                  </p>
                  <Icon
                    color={
                      !canEdit ||
                      orderLoading ||
                      (order.status_code !== 1 && order.status_code !== 4)
                        ? 'inherit'
                        : 'primary'
                    }
                  >
                    {order.payment_method
                      ? order.payment_method.icon
                      : 'attach_money'}
                  </Icon>
                </div>
              </ProductPrice>
              <div className="product-ship-info">
                <div className="price">
                  <h2>Frete:</h2>
                  <p>
                    R$ <span>{order.freight_value || '0,00'}</span>
                  </p>
                </div>
                <div className="ship">
                  <ShipSelectForm
                    value={freight_type || ''}
                    onChange={handleChangeFreightType}
                  >
                    <FormControlLabel
                      disabled={
                        !canEdit ||
                        orderLoading ||
                        (order.status_code !== 1 && order.status_code !== 4)
                      }
                      value="CIF"
                      control={<Radio />}
                      label="CIF"
                    />
                    <FormControlLabel
                      disabled={
                        !canEdit ||
                        orderLoading ||
                        (order.status_code !== 1 && order.status_code !== 4)
                      }
                      value="FOB"
                      control={<Radio />}
                      label="FOB"
                    />
                    <FormControlLabel
                      disabled={
                        !canEdit ||
                        orderLoading ||
                        (order.status_code !== 1 && order.status_code !== 4)
                      }
                      value="SEM"
                      control={<Radio />}
                      label="Sem"
                    />
                  </ShipSelectForm>
                  <div className="price">
                    <h2>Valor total da Venda + Impostos:</h2>
                    <p>
                      R$ <span>{order.total_sale_value || '0,00'}</span>
                    </p>
                  </div>
                </div>
                <OrderDetails onClick={() => handleDetailDialog()}>
                  <Visibility />
                  <p>Detalhes do pedido</p>
                </OrderDetails>
                <DetailsContainer>
                  <SendEmail
                    disabled={emailSended || orderLoading}
                    onClick={handleSendMail}
                  >
                    <Email />
                    <p>Enviar Email</p>
                  </SendEmail>

                  <SendEmail
                    disabled={orderLoading}
                    onClick={event => handleOpenMenu(event.currentTarget)}
                  >
                    <PdfIcon />
                    <p>Gerar PDF</p>
                  </SendEmail>
                  <Menu
                    anchorEl={isMenuOpen}
                    open={!!isMenuOpen}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleGeneratePDF()}>
                      <DescriptionOutlinedIcon />
                      <Typography>Pedido</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleGenerateFreightPDF()}>
                      <DescriptionIcon />
                      <Typography>Ordem de Carregamento</Typography>
                    </MenuItem>
                  </Menu>
                </DetailsContainer>
              </div>
              <Form ref={formRef} onSubmit={handleChangeStatus}>
                <StatusContainer>
                  <Selector
                    name="status_uuid"
                    label="Status"
                    idColumn="uuid"
                    nameColumn="name"
                    options={orderStatus}
                    loading={orderStatusLoading}
                    loadData={loadOrderStatus}
                    disabled={!canEdit || orderLoading}
                  />
                  {order.status_code && order.status_code !== 1 && (
                    <div>
                      <SaveButtonStatus
                        type="submit"
                        disabled={!canEdit || orderLoading}
                      >
                        Alterar Status
                      </SaveButtonStatus>
                    </div>
                  )}
                </StatusContainer>
              </Form>
              <TextField
                label="Observação"
                multiline
                rows={2}
                inputRef={observationRef}
                variant="outlined"
                disabled={
                  !canEdit ||
                  orderLoading ||
                  (order.status_code !== 1 && order.status_code !== 4)
                }
              />
              <div className="order-buttons">
                {order.status_code === 2 && (
                  <div className="approve-refuse">
                    <ApproveButton
                      disabled={!canEdit || orderLoading}
                      onClick={handleApprove}
                    >
                      Aprovar
                    </ApproveButton>
                    <RefuseButton
                      disabled={!canEdit || orderLoading}
                      onClick={handleDecline}
                    >
                      Recusar
                    </RefuseButton>
                  </div>
                )}
                {order.status_code === 1 && (
                  <div className="save-confirm">
                    <SaveButton
                      disabled={!canEdit || orderLoading}
                      onClick={handleSave}
                    >
                      Salvar
                    </SaveButton>
                    <CartButton
                      disabled={!canEdit || orderLoading}
                      onClick={handleFinalize}
                    >
                      Finalizar
                    </CartButton>
                  </div>
                )}
              </div>
            </div>
            <div className="wrapper">
              <h1>Detalhes da compra</h1>
              <div className="product-info-detail">
                <div>
                  <h2>Número do Pedido</h2>
                  <p>{order.id}</p>
                </div>
                <div>
                  <h2>Status</h2>
                  <p>{order.status_name || '###'}</p>
                </div>
              </div>
              <div className="product-info-detail">
                <div>
                  <h2>Peso Bruto (KG)</h2>
                  <p>{order.gross_weight || '0,00'}</p>
                </div>
                <div>
                  <h2>Peso Líquido (KG)</h2>
                  <p>{order.net_weight || '0,00'}</p>
                </div>
              </div>
              <div className="product-info-detail">
                <div>
                  <h2>Desconto (%)</h2>
                  <p>{order.discount_percentage || '0,00'}</p>
                </div>
                <div>
                  <h2>Desconto (R$) </h2>
                  <p>{order.discount_value || '0,00'}</p>
                </div>
              </div>
              <div className="product-info-detail">
                <div>
                  <h2>Valor Bruto (R$)</h2>
                  <p>{order.gross_value || '0,00'}</p>
                </div>
                <div>
                  <h2>Valor Líquido (R$)</h2>
                  <p>{order.net_value || '0,00'}</p>
                </div>
              </div>
              <div className="product-info-detail">
                <div>
                  <h2>Data da Venda</h2>
                  <p>
                    {order.created_at
                      ? format(
                          parseISO(order.created_at),
                          "dd'/'MM'/'yyyy - HH:mm"
                        )
                      : '###'}
                  </p>
                </div>
                <div>
                  <h2>Prazo de Entrega</h2>
                  <p>{order.deadline || '###'} dias</p>
                </div>
              </div>
              <div className="product-ship-info">
                <OrderDetails onClick={() => setPageOrderIndex(0)}>
                  <ArrowBack />
                  <p>Voltar</p>
                </OrderDetails>
              </div>
            </div>
          </SwipeableViews>
        </CartResumeInfo>
      </ShoppingCart>
    </Container>
  );
}

export default Content;

Content.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  setChanging: PropTypes.func.isRequired,
};
