import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCustomer } from '~/providers/customer';
import { useAuth } from '~/providers/auth';

import FormContent from './content';
import NotSales from '~/pages/NotSales';

import { Grid } from '~/components/Form';

import {
  Container,
  SwipeableViews,
  Tabs,
  Paper,
  TabText,
  NavigationContent,
  RegisterContainer,
  ContentContainer,
} from './styles';

function CustomerForm({ formulary }) {
  const location = useLocation();

  const { clearState } = useCustomer();
  const { current_accesses, user } = useAuth();
  const [Tabsvalue, setTabsValue] = useState(0);

  const [pageIndex, setPageIndex] = useState(0);

  const [canEdit, setCanEdit] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => () => clearState({}), [clearState]);

  useEffect(() => {
    if (
      !location.state ||
      location.state.newCustomer ||
      location.state.updateCustomer
    )
      setCanEdit(true);

    window.history.replaceState(null, '');
  }, [location]);

  return (
    <Container>
      {location.state &&
        (location.state.showCustomer || location.state.updateCustomer) &&
        (user.is_admin ||
          user.is_manager ||
          current_accesses['facin.not-sales.get']) && (
          <NavigationContent>
            <Grid container justify="center">
              <Paper
                formulary={formulary ? 'formulary' : ''}
                style={{ boxShadow: 'none' }}
              >
                <Tabs
                  style={{ background: '#061928' }}
                  value={Tabsvalue}
                  onChange={handleChangeTabs}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <TabText
                    formulary={formulary ? 'formulary' : ''}
                    onClick={() => setPageIndex(0)}
                    label="Formulário"
                  />
                  <TabText
                    formulary={formulary ? 'formulary' : ''}
                    onClick={() => setPageIndex(1)}
                    label="Não venda"
                  />
                </Tabs>
              </Paper>
            </Grid>
          </NavigationContent>
        )}
      <ContentContainer>
        <SwipeableViews index={pageIndex}>
          <RegisterContainer>
            <FormContent canEdit={canEdit} />
          </RegisterContainer>
          {location.state &&
          (location.state.showCustomer || location.state.updateCustomer) ? (
            <RegisterContainer>
              <NotSales setPageIndex={setPageIndex} canEdit={canEdit} />
            </RegisterContainer>
          ) : (
            <div>Não venda</div>
          )}
        </SwipeableViews>
      </ContentContainer>
    </Container>
  );
}

export default CustomerForm;

CustomerForm.propTypes = {
  formulary: PropTypes.bool,
};

CustomerForm.defaultProps = {
  formulary: true,
};
