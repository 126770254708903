import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/reports', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { reports: data };
  } catch (error) {
    const reportsErrors = errorHandler({
      error,
      title: 'Error ao listar Relatórios',
      local: 'ReportActionsIndex',
    });

    return { reportsErrors };
  }
}

export async function show({ reportUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/reports/${reportUuid}`)
      .then(res => res.data);

    const selectorReportQueries = data.report.report_queries.filter(
      reportQuery => reportQuery.input_type === 'selector'
    );

    const singleReportQueries = selectorReportQueries.reduce((acc, curr) => {
      const accData = acc || [];

      return accData.some(ac => ac.table_name === curr.table_name)
        ? acc
        : [...acc, curr];
    }, []);

    const parsedReportQueries = {};

    singleReportQueries.forEach(singleReportQuerie => {
      const { table_name, table_key } = singleReportQuerie;

      const registers = data[table_name].map(item => ({
        ...item,
        [table_key]: String(item[table_key]),
      }));

      parsedReportQueries[singleReportQuerie.table_name] = registers;
    });

    return {
      report: {
        ...data.report,
        ...parsedReportQueries,
      },
    };
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao visualizar Relatórios',
      local: 'ReportActionsShow',
    });

    return { reportErrors };
  }
}

export async function store({ report = {} }) {
  try {
    const { data } = await apiFacin
      .post('/reports', {
        data: [report],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...report, ...data[0].report };
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Relatório',
      local: 'ReportActionsStore',
    });

    return { reportErrors };
  }
}

export async function update({ report = {} }) {
  try {
    const { data } = await apiFacin
      .put('/reports', {
        data: [report],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...report, ...data[0].report };
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao atualizar Relatório',
      local: 'ReportActionsUpdate',
    });

    return { reportErrors };
  }
}

export async function destroy({ report = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/reports`,
      method: 'delete',
      data: { data: [report] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao remover Relatório',
      local: 'ReportActionsDestroy',
    });

    return { reportErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: reports } = await apiFacin
      .get(`/reports`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { reports };
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao listar Relatório',
      local: 'ReportActionsIndexSelector',
    });

    return { reportErrors };
  }
}

export async function generatePdf({ reportUuid = '', report_query = {} }) {
  try {
    const { data } = await apiFacin
      .get(`/reports/generate/${reportUuid}`, {
        params: report_query,
      })
      .then(res => res.data);

    return data;
  } catch (error) {
    const reportErrors = errorHandler({
      error,
      title: 'Error ao gerar PDF do Relatório',
      local: 'ReportActionsGeneratePdf',
    });

    return { reportErrors };
  }
}
