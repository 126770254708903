import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { useReport } from '~/providers/reports';

import { validate } from '~/validators/report';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/report';

import { Form, Button, Loading, TextInput, Selector } from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

const tableNames = [{ value: 'orders', label: 'Pedidos' }];

function ReportForm({ tablecolor }) {
  const location = useLocation();

  const {
    report,
    reportLoading,
    reportErrors,
    store,
    update,
    clearState,
  } = useReport();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedReport = {
        ...parsedData,
        req_id: '1',
      };

      if (report.uuid)
        await update({
          report: { ...report, ...parsedReport },
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedReport.company_key = company_key;
        parsedReport.branch_key = branch_key;

        await store({ report: parsedReport });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(reportErrors).length &&
      reportErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(reportErrors.validations);
  }, [reportErrors]);

  useEffect(() => {
    if (report.uuid) {
      const auxReport = { ...report };

      const formData = parseFormData(auxReport);

      setFormData({ formRef, formData });
      const save = tableNames.find(
        table => table.value === auxReport.table_name
      );

      if (formRef.current) {
        const tableNameInputRef = formRef.current.getFieldRef('table_name');
        tableNameInputRef.handleChange(save);
      }

      if (location.state && !location.state.showReport) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newReport) {
      setCanEdit(true);
    }
  }, [report, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
                <TextInput
                  name="description"
                  label="Descrição"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>

              <FormSingleContent>
                <Selector
                  name="table_name"
                  label="Nome da Tabela"
                  idColumn="value"
                  nameColumn="label"
                  options={tableNames}
                  loading={false}
                  required
                  disabled={!canEdit}
                />
                <TextInput
                  name="template"
                  label="Modelo"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <Button
            disabled={reportLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {reportLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default ReportForm;

ReportForm.propTypes = {
  tablecolor: PropTypes.bool,
};

ReportForm.defaultProps = {
  tablecolor: true,
};
