import styled from 'styled-components';
import Dlg from '@material-ui/core/Dialog';
import DlgActions from '@material-ui/core/DialogActions';
import DlgContent from '@material-ui/core/DialogContent';
import DlgContentText from '@material-ui/core/DialogContentText';
import DlgTitle from '@material-ui/core/DialogTitle';
import Btn from '@material-ui/core/Button';

export { default as IconButton } from '@material-ui/core/IconButton';
export { default as CloseIcon } from '@material-ui/icons/Close';

export const Dialog = styled(Dlg)``;

export const DialogActions = styled(DlgActions)``;

export const DialogContent = styled(DlgContent)``;

export const DialogTitle = styled(DlgTitle)`
  h2 {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

export const DialogContentText = styled(DlgContentText)``;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;
