import React, { useState, useRef, useEffect } from 'react';
import Visibility from '@material-ui/icons/Visibility';

import { useAuth } from '~/providers/auth';

import noImage from '~/assets/images/noImage.png';

import {
  Container,
  TextField,
  Button,
  ProfileContainer,
  TextFieldContainer,
} from './styles';

function Profile() {
  const { user, authLoading, authErrors, changePassword } = useAuth();

  const passwordRef = useRef();
  const passwordConfRef = useRef();

  const [error, setError] = useState('');
  const [seePassword, setSeePassword] = useState(false);

  function onErrorImage(event) {
    event.target.src = noImage; // eslint-disable-line
  }

  async function handleSend() {
    if (
      !passwordRef.current.value ||
      !passwordConfRef.current.value ||
      passwordRef.current.value !== passwordConfRef.current.value
    )
      setError('Senhas devem ser iguais');
    else if (
      passwordRef.current.value.length < 6 ||
      passwordConfRef.current.value.length < 6
    )
      setError('Minímo de 6 digitos');
    else {
      setError('');

      await changePassword({ password: passwordRef.current.value });
    }
  }

  useEffect(() => {
    if (Object.keys(authErrors).length && authErrors.validations)
      setError(
        authErrors.validations.password ||
          authErrors.validations.password_confirmation
      );
  }, [authErrors]);

  return (
    <Container>
      <ProfileContainer>
        <img
          onError={onErrorImage}
          src={user.image || noImage}
          alt={user.name}
        />
        <TextFieldContainer>
          <h2>Olá, {user.name.split(' ')[0]} seja bem-vindo ao seu perfil.</h2>
          <TextField
            type={seePassword ? 'text' : 'password'}
            label="Senha"
            variant="outlined"
            inputRef={passwordRef}
            size="small"
            error={!!error}
            helperText={error || ' '}
          />
          <TextField
            type={seePassword ? 'text' : 'password'}
            label="Confirmar senha"
            variant="outlined"
            inputRef={passwordConfRef}
            size="small"
            error={!!error}
            helperText={error || ' '}
          />
          <Button
            variant="outlined"
            style={{ marginBottom: 6 }}
            disabled={authLoading}
            onClick={() => setSeePassword(!seePassword)}
          >
            <Visibility /> Visualizar senha
          </Button>
          <Button
            variant="contained"
            disabled={authLoading}
            onClick={handleSend}
          >
            Alterar senha
          </Button>
        </TextFieldContainer>
      </ProfileContainer>
    </Container>
  );
}

export default Profile;
