import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  productGroups: [],
  productGroup: {},
  productGroupErrors: {},
  productGroupLoading: false,
  productGroupListLoading: false,
};

const ProductGroupContext = createContext(INITIAL_STATE);

export function ProductGroupProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setProductGroupData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setProductGroupData({ productGroupListLoading: true });

      const productGroupData = await actions.index({
        search,
        order_by,
        order,
      });

      if (productGroupData.productGroupErrors)
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setProductGroupData({
        ...productGroupData,
        productGroupListLoading: false,
      });
    },
    [setProductGroupData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ productGroupUuid = '' }) => {
      setProductGroupData({ productGroupLoading: true });

      const productGroupData = await actions.show({ productGroupUuid });

      if (productGroupData.productGroupErrors) {
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => show({ productGroupUuid }),
          },
        });

        history.goBack();
      }

      setProductGroupData({
        ...productGroupData,
        productGroupLoading: false,
      });
    },
    [setProductGroupData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ productGroup = {} }) => {
      setProductGroupData({ productGroupLoading: true });

      const productGroupData = await actions.store({ productGroup });

      if (productGroupData.productGroupErrors) {
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => store({ productGroup }),
          },
        });

        setProductGroupData({
          ...productGroupData,
          productGroupLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          productGroups: [productGroupData, ...oldData.productGroups],
          productGroupLoading: false,
        }));

        history.goBack();

        toast.success('Grupo de Produto cadastrado com sucesso!');
      }
    },
    [setProductGroupData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ productGroup = {} }) => {
      setProductGroupData({ productGroupLoading: true });

      const productGroupData = await actions.update({ productGroup });

      if (productGroupData.productGroupErrors) {
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => update({ productGroup }),
          },
        });

        setProductGroupData({
          ...productGroupData,
          productGroupLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          productGroups: [
            productGroupData,
            ...oldData.productGroups.filter(
              auxProductGroup => auxProductGroup.uuid !== productGroup.uuid
            ),
          ],
          productGroupLoading: false,
        }));

        history.goBack();

        toast.success('Grupo de Produto atualizado com sucesso!');
      }
    },
    [setProductGroupData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ productGroup = {} }) => {
      setProductGroupData({ productGroupListLoading: true });

      const productGroupData = await actions.destroy({ productGroup });

      if (productGroupData.productGroupErrors) {
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => destroy({ productGroup }),
          },
        });

        setProductGroupData({
          ...productGroupData,
          productGroupListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          productGroups: [
            ...oldData.productGroups.filter(
              auxProductGroup => auxProductGroup.uuid !== productGroup.uuid
            ),
          ],
          productGroupListLoading: false,
        }));

        toast.success('Grupo de Produto removido com sucesso!');
      }
    },
    [setProductGroupData, setErrorHandlerData]
  );

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setProductGroupData({ productGroupLoading: true });

      const productGroupData = await actions.indexSelector({ search });

      if (productGroupData.productGroupErrors) {
        setErrorHandlerData({
          error: {
            ...productGroupData.productGroupErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setProductGroupData({ productGroupLoading: false });

      return productGroupData.productGroups;
    },
    [setProductGroupData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        productGroup: {},
        productGroupErrors: {},
        productGroupLoading: false,
        productGroupListLoading: false,
      };
    });
  }, []);

  return (
    <ProductGroupContext.Provider
      value={{
        ...data,
        setProductGroupData,
        index,
        show,
        store,
        update,
        destroy,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </ProductGroupContext.Provider>
  );
}

export function useProductGroup() {
  const context = useContext(ProductGroupContext);

  if (!context)
    throw new Error(
      'useProductGroup must be used within an ProductGroupProvider'
    );

  return context;
}

ProductGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
