import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useProductGroup } from '~/providers/productGroup';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
];

function ProductGroups() {
  const history = useHistory();

  const {
    productGroups,
    productGroupListLoading,
    index,
    show,
    destroy,
  } = useProductGroup();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadProductGroups = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.product-groups.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/product-groups/form', { newProductGroup: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async productGroup => {
          history.push('/product-groups/form', { showProductGroup: true });

          await show({ productGroupUuid: productGroup.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.product-groups.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async productGroup => {
          if (!user.is_admin && !user.is_manager && productGroup.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/product-groups/form', { updateProductGroup: true });

            await show({ productGroupUuid: productGroup.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.product-groups.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: productGroup => {
          if (!user.is_admin && !user.is_manager && productGroup.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ productGroup });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && productGroups.length <= 1) loadProductGroups();

    setLoaded(true);
  }, [productGroups, loadProductGroups, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Grupo de Produtos"
        newItem={newItem}
        headCells={headCells}
        data={productGroups}
        loadData={loadProductGroups}
        loading={productGroupListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default ProductGroups;
