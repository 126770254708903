import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  AccountCircle,
  Dashboard,
  Share,
  Menu,
  Tune,
  TableChart,
  ChevronLeft,
  SubdirectoryArrowLeft,
  AttachMoney,
  Group,
  Person,
  PersonAdd,
  ShopTwo,
  Shop,
  Straighten,
  LocalShipping,
  ReceiptOutlined,
  SwapHoriz,
  Assessment,
} from '@material-ui/icons';
import { useNavigation } from '~/providers/navigation';
import { useOrder } from '~/providers/order';
import Logo from '~/assets/images/logo.png';

import { useAuth } from '~/providers/auth';

import {
  Container,
  HeaderContent,
  LogoContent,
  MenuButton,
  DrawerContent,
  Main,
  RouteContent,
  RouteTitle,
  Content,
  ListItemIconContent,
  ListItemTitle,
  FacileLogoContent,
} from './styles';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Navigation({ children }) {
  const history = useHistory();

  const location = useLocation();
  const { setPageIndex } = useNavigation();
  const { order } = useOrder();

  const { signOut, user, current_accesses } = useAuth();

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function handleNavigate(path) {
    if (path !== location.pathname) {
      history.push(path);
    }
  }

  function handleChangeCompany() {
    history.push('/company-branches');
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleGoBack() {
    if (location.pathname !== '/orders/form') {
      history.goBack();
    } else {
      setPageIndex({});
    }
  }

  const locationPage = useMemo(() => {
    const locate = location?.pathname.split('/');

    const pagesDict = {
      profile: 'Perfil',
      shares: 'Compartilhamentos',
      permissions: 'Permissões',
      users: 'Usuários',
      'generic-tables': 'Tabelas Genéricas',
      'customer-groups': 'Grupo de Clientes',
      customers: 'Clientes',
      'product-groups': 'Grupo de Produtos',
      reports: 'Relatórios',
      'unit-of-measures': 'Unidades de Medidas',
      products: 'Produtos',
      'payment-methods': 'Formas de Pagamento',
      'freight-tables': 'Tabelas de Frete',
      orders: `${order.uuid ? 'Carrinho' : 'Pedidos de Venda'}`,
      parameters: 'Parâmetros',
    };

    return {
      title: pagesDict[locate[1]] || 'Dashboard',
      subTitle: locate.length >= 3 ? pagesDict[locate[2]] : null,
    };
  }, [location, order]);

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <HeaderContent
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <LogoContent>
          <MenuButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu color="primary" />
          </MenuButton>
          <ButtonBase onClick={() => history.push('/')}>
            <h2>Portal de Vendas FacIN Sales</h2>
          </ButtonBase>
        </LogoContent>
        <FacileLogoContent>
          <img src={Logo} alt="Facile Sistemas" />
        </FacileLogoContent>
      </HeaderContent>
      <DrawerContent
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft color="primary" style={{ fontSize: '1.9rem' }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip arrow placement="right-start" title="Dashboard">
            <ListItem button onClick={() => handleNavigate('/')}>
              <ListItemIconContent
                navigate={location.pathname === '/' ? 'navigate' : ''}
              >
                <Dashboard />
              </ListItemIconContent>
              <ListItemTitle primary="Dashboard" />
            </ListItem>
          </Tooltip>
          {user.is_admin && (
            <>
              <Tooltip arrow placement="right-start" title="Compartilhamentos">
                <ListItem button onClick={() => handleNavigate('/shares')}>
                  <ListItemIconContent
                    navigate={
                      location.pathname.includes('/shares') ? 'navigate' : ''
                    }
                  >
                    <Share />
                  </ListItemIconContent>
                  <ListItemTitle primary="Compartilhamentos" />
                </ListItem>
              </Tooltip>
            </>
          )}
          {(user.is_admin || user.is_manager) && (
            <>
              <Tooltip arrow placement="right-start" title="Parâmetros">
                <ListItem button onClick={() => handleNavigate('/parameters')}>
                  <ListItemIconContent
                    navigate={
                      location.pathname.includes('/parameters')
                        ? 'navigate'
                        : ''
                    }
                  >
                    <Tune />
                  </ListItemIconContent>
                  <ListItemTitle primary="Parâmetros" />
                </ListItem>
              </Tooltip>
            </>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.reports.get`]) && (
            <Tooltip arrow placement="right-start" title="Relatórios">
              <ListItem button onClick={() => handleNavigate('/reports')}>
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/reports') ? 'navigate' : ''
                  }
                >
                  <Assessment />
                </ListItemIconContent>
                <ListItemTitle primary="Relatórios" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.generic-tables.get`]) && (
            <Tooltip arrow placement="right-start" title="Tabelas Genéricas">
              <ListItem
                button
                onClick={() => handleNavigate('/generic-tables')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/generic-tables')
                      ? 'navigate'
                      : ''
                  }
                >
                  <TableChart />
                </ListItemIconContent>
                <ListItemTitle primary="Tabelas Genéricas" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.users.get`]) && (
            <Tooltip arrow placement="right-start" title="Usuários">
              <ListItem button onClick={() => handleNavigate('/users')}>
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/users') ? 'navigate' : ''
                  }
                >
                  <PersonAdd />
                </ListItemIconContent>
                <ListItemTitle primary="Usuários" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.customer-groups.get`]) && (
            <Tooltip arrow placement="right-start" title="Grupo de Clientes">
              <ListItem
                button
                onClick={() => handleNavigate('/customer-groups')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/customer-groups')
                      ? 'navigate'
                      : ''
                  }
                >
                  <Group />
                </ListItemIconContent>
                <ListItemTitle primary="Grupo de Clientes" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.customers/all.get`] ||
            current_accesses[`facin.customers/own-seller.get`] ||
            current_accesses[`facin.customers/without-seller.get`]) && (
            <Tooltip arrow placement="right-start" title="Clientes">
              <ListItem button onClick={() => handleNavigate('/customers')}>
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/customers') ? 'navigate' : ''
                  }
                >
                  <Person />
                </ListItemIconContent>
                <ListItemTitle primary="Clientes" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.product-groups.get`]) && (
            <Tooltip arrow placement="right-start" title="Grupo de Produtos">
              <ListItem
                button
                onClick={() => handleNavigate('/product-groups')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/product-groups')
                      ? 'navigate'
                      : ''
                  }
                >
                  <ShopTwo />
                </ListItemIconContent>
                <ListItemTitle primary="Grupo de Produtos" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.unit-of-measures.get`]) && (
            <Tooltip arrow placement="right-start" title="Unidades de Medida">
              <ListItem
                button
                onClick={() => handleNavigate('/unit-of-measures')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/unit-of-measures')
                      ? 'navigate'
                      : ''
                  }
                >
                  <Straighten />
                </ListItemIconContent>
                <ListItemTitle primary="Unidades de Medida" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.products.get`]) && (
            <Tooltip arrow placement="right-start" title="Produtos">
              <ListItem button onClick={() => handleNavigate('/products')}>
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/products') ? 'navigate' : ''
                  }
                >
                  <Shop />
                </ListItemIconContent>
                <ListItemTitle primary="Produtos" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.payment-methods.get`]) && (
            <Tooltip arrow placement="right-start" title="Formas de Pagamento">
              <ListItem
                button
                onClick={() => handleNavigate('/payment-methods')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/payment-methods')
                      ? 'navigate'
                      : ''
                  }
                >
                  <AttachMoney />
                </ListItemIconContent>
                <ListItemTitle primary="Formas de Pagamento" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.freight-tables.get`]) && (
            <Tooltip arrow placement="right-start" title="Tabelas de Frete">
              <ListItem
                button
                onClick={() => handleNavigate('/freight-tables')}
              >
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/freight-tables')
                      ? 'navigate'
                      : ''
                  }
                >
                  <LocalShipping />
                </ListItemIconContent>
                <ListItemTitle primary="Tabelas de Frete" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_admin ||
            user.is_manager ||
            current_accesses[`facin.orders/all.get`] ||
            current_accesses[`facin.orders/own-seller.get`]) && (
            <Tooltip arrow placement="right-start" title="Pedidos de Venda">
              <ListItem button onClick={() => handleNavigate('/orders')}>
                <ListItemIconContent
                  navigate={
                    location.pathname.includes('/orders') ? 'navigate' : ''
                  }
                >
                  <ReceiptOutlined />
                </ListItemIconContent>
                <ListItemTitle primary="Pedidos de Venda" />
              </ListItem>
            </Tooltip>
          )}
        </List>
        <Divider />
        <List>
          <Tooltip arrow placement="right-start" title="Perifl">
            <ListItem button onClick={() => handleNavigate('/profile')}>
              <ListItemIconContent
                navigate={
                  location.pathname.includes('/profile') ? 'navigate' : ''
                }
              >
                <AccountCircle />
              </ListItemIconContent>
              <ListItemTitle primary="Perfil" />
            </ListItem>
          </Tooltip>
          <Tooltip arrow placement="right-start" title="Mudar Empresa">
            <ListItem button onClick={handleChangeCompany}>
              <ListItemIconContent>
                <SwapHoriz />
              </ListItemIconContent>
              <ListItemTitle primary="Mudar empresa" />
            </ListItem>
          </Tooltip>
          <Tooltip arrow placement="right-start" title="Deslogar">
            <ListItem button onClick={signOut}>
              <ListItemIconContent>
                <SubdirectoryArrowLeft />
              </ListItemIconContent>
              <ListItemTitle primary="Deslogar" />
            </ListItem>
          </Tooltip>
        </List>
      </DrawerContent>
      <Main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <RouteContent style={{ position: 'relative', zIndex: 1 }}>
          {location.pathname !== '/' && (
            <IconButton onClick={() => handleGoBack()}>
              <ChevronLeft style={{ fontSize: '2rem', color: '#fff' }} />
            </IconButton>
          )}
          <RouteTitle>{locationPage.title}</RouteTitle>
        </RouteContent>
        <Content>{children}</Content>
      </Main>
    </Container>
  );
}

Navigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
