import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';
import { useOrderItem } from '../orderItem';

import { base64toPDF } from '~/utils/order';

import * as actions from './actions';

const INITIAL_STATE = {
  orders: [],
  order: {},
  header_discount: 0,
  orderErrors: {},
  orderLoading: false,
  orderListLoading: false,
};

const OrderContext = createContext(INITIAL_STATE);

export function OrderProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const { clearState: orderItemClearState, setOrderItemData } = useOrderItem();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setOrderData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setOrderData({ orderListLoading: true });

      const orderData = await actions.index({
        search,
        order_by,
        order,
      });

      if (orderData.orderErrors)
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setOrderData({
        ...orderData,
        orderListLoading: false,
      });
    },
    [setOrderData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ orderUuid = '', goBack = false }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.show({ orderUuid, goBack });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => show({ orderUuid, goBack }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      }

      const orderItems = [...orderData?.order?.order_items];

      delete orderData?.order?.order_items;

      setOrderItemData({
        orderItems: goBack ? [] : orderItems,
      });

      setData(oldData => {
        const auxOrders = [...oldData.orders];

        const orderIndex = auxOrders.findIndex(
          auxOrder => auxOrder.uuid === orderUuid
        );

        auxOrders.splice(
          orderIndex === -1 ? 0 : orderIndex,
          1,
          orderData.order
        );

        return {
          ...oldData,
          order: goBack ? {} : orderData.order,
          orders: auxOrders,
          orderLoading: false,
        };
      });
    },
    [setOrderData, setErrorHandlerData, setOrderItemData]
  );

  const store = useCallback(
    async ({ order = {}, orderItem = {} }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.store({ order, orderItem });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => store({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });

        setOrderItemData({
          orderItemErrors: orderData.orderErrors,
        });

        return false;
      }

      const orderItems = [...orderData.order_items];

      delete orderData.order_items;

      setData(oldData => ({
        ...oldData,
        order: orderData,
        orders: [orderData, ...oldData.orders],
        orderLoading: false,
      }));
      toast.success('O pedido foi cadastrado com sucesso!', {
        position: toast.POSITION.TOP_CENTER,
      });

      return orderItems[0];
    },
    [setOrderData, setErrorHandlerData, setOrderItemData]
  );

  const update = useCallback(
    async ({ order = {}, goBack = false }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.update({ order, goBack });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => update({ order, goBack }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        if (goBack) history.goBack();

        toast.success('Pedido atualizado com sucesso!');

        await show({ orderUuid: order.uuid, goBack });
      }

      setOrderData({ orderLoading: false });
    },
    [setOrderData, setErrorHandlerData, show, history]
  );

  const updateStatus = useCallback(
    async ({ order = {}, goBack = false }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.updateStatus({ order, goBack });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => updateStatus({ order, goBack }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        if (goBack) history.goBack();

        toast.success('Status do pedido atualizado com sucesso!');

        await show({ orderUuid: order.uuid, goBack });
      }

      setOrderData({ orderLoading: false });
    },
    [setOrderData, setErrorHandlerData, show, history]
  );

  const destroy = useCallback(
    async ({ order = {} }) => {
      setOrderData({ orderListLoading: true });

      const orderData = await actions.destroy({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => destroy({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          orders: [
            ...oldData.orders.filter(auxOrder => auxOrder.uuid !== order.uuid),
          ],

          orderListLoading: false,
        }));

        toast.success('Pedido removido com sucesso!');
      }
    },
    [setOrderData, setErrorHandlerData]
  );

  const sendEmail = useCallback(
    async ({ order = {} }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.sendEmail({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => sendEmail({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        setOrderData({
          orderLoading: false,
        });

        toast.success('Email enviado com sucesso!');
      }
    },
    [setOrderData, setErrorHandlerData]
  );

  const generatePDF = useCallback(
    async ({ order = {} }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.generatePDF({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => generatePDF({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        base64toPDF(orderData.pdf);

        setTimeout(
          () =>
            setOrderData({
              orderLoading: false,
            }),
          400
        );
      }
    },
    [setOrderData, setErrorHandlerData]
  );

  const generateFreightPDF = useCallback(
    async ({ order = {} }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.generateFreightPDF({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => generateFreightPDF({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        base64toPDF(orderData.pdf);

        setTimeout(
          () =>
            setOrderData({
              orderLoading: false,
            }),
          400
        );
      }
    },
    [setOrderData, setErrorHandlerData]
  );

  const setDiscount = useCallback(
    async ({ order = {} }) => {
      setOrderData({
        orderLoading: true,
        header_discount: order.header_discount,
      });

      if (!order.uuid) {
        setOrderData({ order, orderLoading: false });

        return true;
      }

      const orderData = await actions.setDiscount({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => setDiscount({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });

        return false;
      }

      setOrderData({
        order: { ...order, ...orderData.order },
        orderLoading: false,
      });

      toast.success('Desconto aplicado com sucesso!');

      return true;
    },
    [setOrderData, setErrorHandlerData]
  );

  const statusIndexSelector = useCallback(
    async ({ search = '' }) => {
      const orderData = await actions.statusIndexSelector({ search });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => statusIndexSelector({ search }),
          },
        });

        return [];
      }
      return orderData.status;
    },
    [setErrorHandlerData]
  );

  const clearState = useCallback(
    ({ all = false }) => {
      orderItemClearState({ all: true });

      setData(oldData => {
        if (all) return INITIAL_STATE;
        return {
          ...oldData,
          order: {},
          header_discount: 0,
          orderErrors: {},
          orderLoading: false,
          orderListLoading: false,
        };
      });
    },
    [orderItemClearState]
  );

  return (
    <OrderContext.Provider
      value={{
        ...data,
        setOrderData,
        index,
        show,
        store,
        update,
        updateStatus,
        destroy,
        sendEmail,
        generatePDF,
        generateFreightPDF,
        setDiscount,
        statusIndexSelector,
        clearState,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export function useOrder() {
  const context = useContext(OrderContext);

  if (!context)
    throw new Error('useOrder must be used within an OrderProvider');

  return context;
}

OrderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
