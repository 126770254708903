import React from 'react';
import PropTypes from 'prop-types';

import { Container, InputWrapper, Input, Loading } from './styles';

export default function Selector({
  label,
  value,
  nameColumn,
  options,
  onChange,
  loading,
  error,
  setError,
  containerStyle,
  labelStyle,
  ...rest
}) {
  function handleChange(newValue) {
    if (setError) setError('');
    if (onChange) onChange(newValue);
  }

  return (
    <Container
      options={options}
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={option => option[nameColumn]}
      getOptionSelected={option => option}
      style={containerStyle}
      loading={loading}
      renderInput={params => (
        <InputWrapper error={!!error}>
          {loading && <Loading />}
          <Input
            {...params}
            label={label}
            variant="outlined"
            error={!!error}
            helperText={error || ' '}
            style={labelStyle}
          />
        </InputWrapper>
      )}
      {...rest}
    />
  );
}

Selector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Object),
  nameColumn: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  setError: PropTypes.func,
  containerStyle: PropTypes.instanceOf(Object),
  labelStyle: PropTypes.instanceOf(Object),
};

Selector.defaultProps = {
  value: null,
  loading: false,
  error: '',
  setError: null,
  containerStyle: {},
  labelStyle: {},
};
