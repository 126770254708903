import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import { base64toPDF } from '~/utils/base64ToPdf';
import * as actions from './actions';

const INITIAL_STATE = {
  reports: [],
  report: {},
  reportErrors: {},
  reportLoading: false,
  reportListLoading: false,
};

const ReportContext = createContext(INITIAL_STATE);

export function ReportProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setReportData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setReportData({ reportListLoading: true });

      const reportData = await actions.index({
        search,
        order_by,
        order,
      });

      if (reportData.reportErrors)
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setReportData({
        ...reportData,
        reportListLoading: false,
      });
    },
    [setReportData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ reportUuid = '' }) => {
      setReportData({ reportLoading: true });

      const reportData = await actions.show({ reportUuid });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => show({ reportUuid }),
          },
        });

        history.goBack();
      }

      setReportData({
        ...reportData,
        reportLoading: false,
      });
    },
    [setReportData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ report = {} }) => {
      setReportData({ reportLoading: true });

      const reportData = await actions.store({ report });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => store({ report }),
          },
        });

        setReportData({
          ...reportData,
          reportLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          reports: [reportData, ...oldData.reports],
          reportLoading: false,
        }));

        history.goBack();

        toast.success('Relatório cadastrado com sucesso!');
      }
    },
    [setReportData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ report = {} }) => {
      setReportData({ reportLoading: true });

      const reportData = await actions.update({ report });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => update({ report }),
          },
        });

        setReportData({
          ...reportData,
          reportLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          reports: [
            reportData,
            ...oldData.reports.filter(
              auxreport => auxreport.uuid !== report.uuid
            ),
          ],
          reportLoading: false,
        }));

        history.goBack();

        toast.success('Relatório atualizado com sucesso!');
      }
    },
    [setReportData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ report = {} }) => {
      setReportData({ reportListLoading: true });

      const reportData = await actions.destroy({ report });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => destroy({ report }),
          },
        });

        setReportData({
          ...reportData,
          reportListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          reports: [
            ...oldData.reports.filter(
              auxreport => auxreport.uuid !== report.uuid
            ),
          ],
          reportListLoading: false,
        }));

        toast.success('Relatório removido com sucesso!');
      }
    },
    [setReportData, setErrorHandlerData]
  );

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setReportData({ reportLoading: true });

      const reportData = await actions.indexSelector({ search });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setReportData({ reportLoading: false });

      return reportData.reports;
    },
    [setReportData, setErrorHandlerData]
  );

  const generatePdf = useCallback(
    async ({ reportUuid = '', report_query = {} }) => {
      setReportData({ reportLoading: true });

      const reportData = await actions.generatePdf({
        reportUuid,
        report_query,
      });

      if (reportData.reportErrors) {
        setErrorHandlerData({
          error: {
            ...reportData.reportErrors,
            resolveFunction: () => generatePdf({ reportUuid, report_query }),
          },
        });

        setReportData({
          ...reportData,
          reportLoading: false,
        });
      } else {
        await base64toPDF(reportData.base64PDF);
        setReportData({ reportLoading: false });
      }
    },
    [setReportData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        report: {},
        reportErrors: {},
        reportLoading: false,
        reportListLoading: false,
      };
    });
  }, []);

  return (
    <ReportContext.Provider
      value={{
        ...data,
        setReportData,
        index,
        show,
        store,
        update,
        destroy,
        generatePdf,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

export function useReport() {
  const context = useContext(ReportContext);

  if (!context)
    throw new Error('useReport must be used within an ReportProvider');

  return context;
}

ReportProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
