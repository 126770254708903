import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotSale } from '../notSale';
import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  customers: [],
  customer: {},
  customerErrors: {},
  customerLoading: false,
  customerListLoading: false,
};

const CustomerContext = createContext(INITIAL_STATE);

export function CustomerProvider({ children }) {
  const history = useHistory();

  const { setNotSaleData, clearState: notSaleClearState } = useNotSale();
  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setCustomerData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setCustomerData({ customerListLoading: true });

      const customerData = await actions.index({
        search,
        order_by,
        order,
      });

      if (customerData.customerErrors)
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setCustomerData({ ...customerData, customerListLoading: false });
    },
    [setCustomerData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ customerUuid = '' }) => {
      setCustomerData({ customerLoading: true });

      const customerData = await actions.show({ customerUuid });

      if (customerData.customerErrors) {
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => show({ customerUuid }),
          },
        });

        history.goBack();
      } else {
        setNotSaleData({
          notSales: [...customerData.customer.not_sales],
        });

        delete customerData.customer.not_sales;
      }

      setCustomerData({ ...customerData, customerLoading: false });
    },
    [setCustomerData, setErrorHandlerData, history, setNotSaleData]
  );

  const store = useCallback(
    async ({ customer = {}, setShowDialog = null }) => {
      setCustomerData({ customerLoading: true });

      const customerData = await actions.store({ customer });

      if (customerData.customerErrors) {
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => store({ customer }),
          },
        });

        setCustomerData({ ...customerData, customerLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          customers: [customerData, ...oldData.customers],
          customerLoading: false,
        }));

        if (setShowDialog) setShowDialog(false);
        else history.goBack();

        toast.success('Cliente cadastrado com sucesso!');
      }
    },
    [setCustomerData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ customer = {} }) => {
      setCustomerData({ customerLoading: true });

      const customerData = await actions.update({ customer });

      if (customerData.customerErrors) {
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => update({ customer }),
          },
        });

        setCustomerData({ ...customerData, customerLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          customers: [
            customerData,
            ...oldData.customers.filter(
              auxCustomer => auxCustomer.uuid !== customer.uuid
            ),
          ],
          customerLoading: false,
        }));

        history.goBack();

        toast.success('Cliente atualizado com sucesso!');
      }
    },
    [setCustomerData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ customer = {} }) => {
      setCustomerData({ customerListLoading: true });

      const customerData = await actions.destroy({ customer });

      if (customerData.customerErrors) {
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => destroy({ customer }),
          },
        });

        setCustomerData({ ...customerData, customerListLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          customers: [
            ...oldData.customers.filter(
              auxCustomer => auxCustomer.uuid !== customer.uuid
            ),
          ],
          customerListLoading: false,
        }));

        toast.success('Cliente removido com sucesso!');
      }
    },
    [setCustomerData, setErrorHandlerData]
  );

  const newCustomer = useCallback(async () => {
    setCustomerData({ customerLoading: true });

    const customerData = await actions.newCustomer();

    if (customerData.customerErrors)
      setErrorHandlerData({
        error: {
          ...customerData.customerErrors,
          resolveFunction: () => newCustomer(),
        },
      });

    setCustomerData({ customerLoading: false });

    return { customer_groups: [], states: [], users: [], ...customerData };
  }, [setCustomerData, setErrorHandlerData]);

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setCustomerData({ customerLoading: true });

      const customerData = await actions.indexSelector({ search });

      if (customerData.customerErrors) {
        setErrorHandlerData({
          error: {
            ...customerData.customerErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setCustomerData({ customerLoading: false });

      return customerData.customers;
    },
    [setCustomerData, setErrorHandlerData]
  );

  const clearState = useCallback(
    ({ all = false }) => {
      notSaleClearState({ all: true });

      setData(oldData => {
        if (all) return INITIAL_STATE;
        return {
          ...oldData,
          customer: {},
          customerErrors: {},
          customerLoading: false,
          customerListLoading: false,
        };
      });
    },
    [notSaleClearState]
  );

  return (
    <CustomerContext.Provider
      value={{
        ...data,
        setCustomerData,
        index,
        show,
        store,
        update,
        destroy,
        newCustomer,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context)
    throw new Error('useCustomer must be used within an CustomerProvider');

  return context;
}

CustomerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
