import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useFreightTable } from '~/providers/freightTable';
import { useAuth } from '~/providers/auth';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'description', label: 'Descrição' },
  { id: 'start_at_view', label: 'Início Vigência' },
  { id: 'end_at_view', label: 'Fim Vigência' },
];

const otherCells = [
  { id: 'min_weight', label: 'Peso Mínimo' },
  { id: 'max_weight', label: 'Peso Máximo' },
  { id: 'customer_group_name', label: 'Grupo de Cliente' },
  { id: 'customer_name', label: 'Cliente' },
  { id: 'uf_city', label: 'UF / Município' },
];

function FreightTables() {
  const history = useHistory();

  const {
    freightTables,
    freightTableListLoading,
    index,
    show,
    destroy,
  } = useFreightTable();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadFreightTables = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.freight-tables.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/freight-tables/form', { newFreightTable: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async freightTable => {
          history.push('/freight-tables/form', { showFreightTable: true });

          await show({ freightTableUuid: freightTable.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.freight-tables.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async freightTable => {
          if (!user.is_admin && !user.is_manager && freightTable.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/freight-tables/form', { updateFreightTable: true });

            await show({ freightTableUuid: freightTable.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.freight-tables.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: freightTable => {
          if (!user.is_admin && !user.is_manager && freightTable.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ freightTable });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && freightTables.length <= 1) loadFreightTables();

    setLoaded(true);
  }, [freightTables, loadFreightTables, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Tabelas de Frete"
        newItem={newItem}
        headCells={headCells}
        otherCells={otherCells}
        data={freightTables}
        loadData={loadFreightTables}
        loading={freightTableListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default FreightTables;
