import styled from 'styled-components';
import Dlg from '@material-ui/core/Dialog';
import Btn from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Close } from '@material-ui/icons/Close';
export { default as Add } from '@material-ui/icons/Add';
export { default as Remove } from '@material-ui/icons/Remove';

export const SingleProductDialog = styled(Dlg).attrs({
  scroll: 'paper',
  maxWidth: 'lg',
})``;

export const SingleProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em 2em;
`;

export const SingleProductNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h2 {
    font-size: 0.9rem;
    margin-right: 1em;

    span {
      font-size: 1rem;
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const ImageTableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 2;
`;

export const ImageSelector = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const ImageCarousel = styled.div`
  width: 10%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  img {
    width: 100%;
  }
`;

export const FeaturedImage = styled.div`
  width: 100%;
  min-width: 450px;

  img {
    width: 100%;
    height: 320px;
    object-fit: contain;
  }
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 1rem;
  }
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  max-height: 140px;
  overflow-y: auto;
  box-shadow: 0px 3px 16px 0px rgba(30, 30, 50, 0.19);
`;

export const TableHead = styled.thead`
  th {
    padding: 0.5em;
    text-align: left;
    font-weight: 500;
    flex: 1;
  }
  td {
    padding: 0.5em;
    text-align: left;
    flex: 3;
  }
`;

export const TableRow = styled.tr`
  th {
    background: ${props =>
      props.change
        ? props.theme.colors.tableTitle
        : props.theme.colors.tableContent};
  }

  td {
    width: 100%;
    background: ${props =>
      props.change
        ? props.theme.colors.tableContent
        : props.theme.colors.tableTitle};
  }
`;

export const SingleProductContent = styled.div`
  display: flex;
  gap: 20px;
  padding: 1rem;
  justify-content: space-between;
  min-width: 800px;
`;

export const ImageButton = styled(ButtonBase)``;

export const SingleProductPriceInfo = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2rem;
    font-weight: lighter;
    line-height: 1;
    text-align: left;
    margin-bottom: 0.2em;
  }

  .product-info {
    span {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${props => props.theme.colors.backSecond};
      padding-bottom: 0.4em;
      padding-top: 0.1em;
    }
  }

  .product-ref {
    font-size: 0.9rem;
    font-weight: lighter;
    margin-bottom: 1em;
  }

  p {
    line-height: 1.6;
  }

  .product-price {
    margin-top: 1em;

    h6 {
      margin-bottom: 0;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.primary};
    }

    .price {
      font-size: 1.2rem;
      margin: 0;
      padding-bottom: 0.6em;
      border-bottom: 1px solid ${props => props.theme.colors.backSecond};

      span {
        font-size: 2rem;
      }
    }
  }

  .desc-price {
    display: flex;
    gap: 10px;
  }
`;

export const CartButton = styled(Btn).attrs({
  variant: 'contained',
  color: 'primary',
})`
  &.MuiButton-contained {
    margin-top: auto;
    background-color: ${props => props.theme.colors.primary};

    &:hover {
      background-color: ${props => props.theme.colors.primaryDarken};
    }
  }
`;

export const CartPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;

  .cart-update {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  input[type='number'] {
    text-align: center;
  }

  input[type='number']::-webkit-inner-spin-button {
    all: inherit;
    cursor: pointer;
  }
`;
