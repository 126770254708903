import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useUnitOfMeasure } from '~/providers/unitOfMeasure';

import { validate } from '~/validators/unitOfMeasure';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/unitOfMeasure';

import { Form, Button, Loading, TextInput } from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

function UnitOfMeasureForm({ tablecolor }) {
  const location = useLocation();

  const {
    unitOfMeasure,
    unitOfMeasureLoading,
    unitOfMeasureErrors,
    store,
    update,
    clearState,
  } = useUnitOfMeasure();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedUnitOfMeasure = {
        ...parsedData,
        req_id: '1',
      };

      if (unitOfMeasure.uuid)
        await update({
          unitOfMeasure: { ...unitOfMeasure, ...parsedUnitOfMeasure },
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedUnitOfMeasure.company_key = company_key;
        parsedUnitOfMeasure.branch_key = branch_key;

        await store({ unitOfMeasure: parsedUnitOfMeasure });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(unitOfMeasureErrors).length &&
      unitOfMeasureErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(unitOfMeasureErrors.validations);
  }, [unitOfMeasureErrors]);

  useEffect(() => {
    if (unitOfMeasure.uuid) {
      const auxUnitOfMeasure = { ...unitOfMeasure };

      const formData = parseFormData(auxUnitOfMeasure);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showUnitOfMeasure) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newUnitOfMeasure) {
      setCanEdit(true);
    }
  }, [unitOfMeasure, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
              </FormSingleContent>
              <FormSingleContent>
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="initials"
                  label="Iniciais"
                  required
                  maxLength={20}
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>

          <Button
            disabled={unitOfMeasureLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {unitOfMeasureLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default UnitOfMeasureForm;

UnitOfMeasureForm.propTypes = {
  tablecolor: PropTypes.bool,
};

UnitOfMeasureForm.defaultProps = {
  tablecolor: true,
};
