import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  notSales: [],
  notSale: {},
  notSaleErrors: {},
  notSaleLoading: false,
  notSaleListLoading: false,
};

const NotSaleContext = createContext(INITIAL_STATE);

export function NotSaleProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setNotSaleData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ customer_uuid = '', search = '', order_by = '', order = '' }) => {
      setNotSaleData({ notSaleListLoading: true });

      const notSaleData = await actions.index({
        customer_uuid,
        search,
        order_by,
        order,
      });

      if (notSaleData.notSaleErrors)
        setErrorHandlerData({
          error: {
            ...notSaleData.notSaleErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setNotSaleData({
        ...notSaleData,
        notSaleListLoading: false,
      });
    },
    [setNotSaleData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ notSaleUuid = '' }) => {
      setNotSaleData({ notSaleLoading: true });

      const notSaleData = await actions.show({ notSaleUuid });

      if (notSaleData.notSaleErrors) {
        setErrorHandlerData({
          error: {
            ...notSaleData.notSaleErrors,
            resolveFunction: () => show({ notSaleUuid }),
          },
        });
      }

      setNotSaleData({
        ...notSaleData,
        notSaleLoading: false,
      });
    },
    [setNotSaleData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ notSale = {} }) => {
      setNotSaleData({ notSaleLoading: true });

      const notSaleData = await actions.store({ notSale });

      if (notSaleData.notSaleErrors) {
        setErrorHandlerData({
          error: {
            ...notSaleData.notSaleErrors,
            resolveFunction: () => store({ notSale }),
          },
        });

        setNotSaleData({
          ...notSaleData,
          notSaleLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        notSales: [notSaleData, ...oldData.notSales],
        notSaleLoading: false,
      }));

      toast.success('Não venda cadastrada com sucesso!');

      return notSaleData;
    },
    [setNotSaleData, setErrorHandlerData]
  );

  const update = useCallback(
    async ({ notSale = {} }) => {
      setNotSaleData({ notSaleLoading: true });

      const notSaleData = await actions.update({ notSale });

      if (notSaleData.notSaleErrors) {
        setErrorHandlerData({
          error: {
            ...notSaleData.notSaleErrors,
            resolveFunction: () => update({ notSale }),
          },
        });

        setNotSaleData({
          ...notSaleData,
          notSaleLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        notSales: [
          notSaleData,
          ...oldData.notSales.filter(
            auxNotSale => auxNotSale.uuid !== notSale.uuid
          ),
        ],
        notSaleLoading: false,
      }));

      toast.success('Não venda atualizada com sucesso!');

      return notSaleData;
    },
    [setNotSaleData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ notSale = {} }) => {
      setNotSaleData({ notSaleListLoading: true });

      const notSaleData = await actions.destroy({ notSale });

      if (notSaleData.notSaleErrors) {
        setErrorHandlerData({
          error: {
            ...notSaleData.notSaleErrors,
            resolveFunction: () => destroy({ notSale }),
          },
        });

        setNotSaleData({
          ...notSaleData,
          notSaleListLoading: false,
        });

        return true;
      }

      setData(oldData => ({
        ...oldData,
        notSales: [
          ...oldData.notSales.filter(
            auxNotSale => auxNotSale.uuid !== notSale.uuid
          ),
        ],
        notSaleListLoading: false,
      }));

      toast.success('Não venda removida com sucesso!');

      return true;
    },
    [setNotSaleData, setErrorHandlerData]
  );

  const newNotSale = useCallback(async () => {
    setNotSaleData({ notSaleLoading: true });

    const notSaleData = await actions.newNotSale();

    if (notSaleData.notSaleErrors)
      setErrorHandlerData({
        error: {
          ...notSaleData.notSaleErrors,
          resolveFunction: () => newNotSale(),
        },
      });

    setNotSaleData({ notSaleLoading: false });

    return { customer_groups: [], customers: [], states: [], ...notSaleData };
  }, [setNotSaleData, setErrorHandlerData]);

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        notSale: {},
        notSaleErrors: {},
        notSaleLoading: false,
        notSaleListLoading: false,
      };
    });
  }, []);

  return (
    <NotSaleContext.Provider
      value={{
        ...data,
        setNotSaleData,
        index,
        show,
        store,
        update,
        destroy,
        newNotSale,
        clearState,
      }}
    >
      {children}
    </NotSaleContext.Provider>
  );
}

export function useNotSale() {
  const context = useContext(NotSaleContext);

  if (!context)
    throw new Error('useNotSale must be used within an NotSaleProvider');

  return context;
}

NotSaleProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
