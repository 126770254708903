import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { useProduct } from '~/providers/product';
import { useUnitOfMeasure } from '~/providers/unitOfMeasure';
import { useProductGroup } from '~/providers/productGroup';

import { validate } from '~/validators/product';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/product';

import {
  Form,
  Button,
  Loading,
  TextInput,
  ValueInput,
  RadioGroup,
  Selector,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

const productStatus = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

function ProductFormContent({ canEdit, tablecolor }) {
  const location = useLocation();

  const {
    product,
    productLoading,
    productErrors,
    store,
    update,
    newProduct,
  } = useProduct();
  const { indexSelector: unitOfMeasureIndexSelector } = useUnitOfMeasure();
  const { indexSelector: productGroupIndexSelector } = useProductGroup();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);
  const [product_groups, setProductGroups] = useState([]);
  const [unit_of_measures, setUnitOfMeasures] = useState([]);
  const [loadingUnitOfMeasures, setLoadingUnitOfMeasures] = useState(false);
  const [loadingProductGroups, setLoadingProductGroups] = useState(false);

  const loadUnitOfMeasures = useCallback(
    async (search = '') => {
      setLoadingUnitOfMeasures(true);
      const auxUnitOfMeasures = await unitOfMeasureIndexSelector({ search });
      setLoadingUnitOfMeasures(false);

      setUnitOfMeasures(auxUnitOfMeasures);
    },
    [unitOfMeasureIndexSelector]
  );

  const loadProductGroups = useCallback(
    async (search = '') => {
      setLoadingProductGroups(true);
      const auxProductGroups = await productGroupIndexSelector({ search });
      setLoadingProductGroups(false);

      setProductGroups(auxProductGroups);
    },
    [productGroupIndexSelector]
  );

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate({
      ...product,
      ...data,
    });

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const findedProductGroup = product_groups.find(
        product_group => product_group.uuid === data.product_group_uuid
      );
      const findedUnitOfMeasure = unit_of_measures.find(
        unit_of_measure => unit_of_measure.uuid === data.unit_of_measure_uuid
      );

      const parsedProduct = {
        ...parsedData,
        req_id: '1',
        product_group: findedProductGroup,
        unit_of_measure: findedUnitOfMeasure,
      };

      if (product.uuid) await update({ product: parsedProduct });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedProduct.company_key = company_key;
        parsedProduct.branch_key = branch_key;

        await store({ product: parsedProduct });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(productErrors).length &&
      productErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(productErrors.validations);
  }, [productErrors]);

  useEffect(() => {
    async function loadData() {
      const {
        product_groups: auxProductGroups,
        unit_of_measures: auxUnitOfMeasures,
      } = await newProduct();

      setProductGroups(auxProductGroups);
      setUnitOfMeasures(auxUnitOfMeasures);
    }

    if (product.uuid) {
      const auxProduct = { ...product };

      const {
        product_groups: auxProductGroups,
        unit_of_measures: auxUnitOfMeasures,
        product_images: auxProductImages,
        status_code,
        product_group,
        unit_of_measure,
        ...productData
      } = auxProduct;

      setProductGroups(auxProductGroups);
      setUnitOfMeasures(auxUnitOfMeasures);

      if (formRef.current) {
        const statusCodeInputRef = formRef.current.getFieldRef('status_code');
        statusCodeInputRef.handleChange({ target: { value: String(status_code) } }); // prettier-ignore

        const productGroupInputRef = formRef.current.getFieldRef('product_group_uuid'); // prettier-ignore
        productGroupInputRef.handleChange(product_group);

        const unitOfMeasureInputRef = formRef.current.getFieldRef('unit_of_measure_uuid'); // prettier-ignore
        unitOfMeasureInputRef.handleChange(unit_of_measure);
      }

      const formData = parseFormData(productData);

      setFormData({ formRef, formData });
    } else if (!location.state || location.state.newProduct) loadData();
  }, [product, newProduct, location]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer>
            <h2>Especificações</h2>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
                <TextInput
                  name="reference_code"
                  label="Código de Referência"
                  disabled={!canEdit}
                />
                <RadioGroup
                  name="status_code"
                  label="Status"
                  options={productStatus}
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <h2>Dados do Produto</h2>
            <FormContent>
              <FormSingleContent>
                <TextInput
                  name="name"
                  label="Nome"
                  required
                  disabled={!canEdit}
                />
                <TextInput
                  name="description"
                  label="Descrição"
                  disabled={!canEdit}
                />
                <TextInput
                  name="manufacturer"
                  label="Fabricante"
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <ValueInput
                  name="deadline"
                  label="Prazo de Entrega"
                  disabled={!canEdit}
                  inputProps={{ decimalScale: 0, suffix: ' DIAS' }}
                />
                <ValueInput
                  name="price"
                  label="Preço"
                  disabled={!canEdit}
                  required
                  inputProps={{
                    prefix: 'R$ ',
                    decimalScale: 6,
                  }}
                />
                <ValueInput
                  name="gross_weight"
                  label="Peso Bruto"
                  disabled={!canEdit}
                  required
                  prefix="KG "
                />
              </FormSingleContent>
              <FormSingleContent>
                <ValueInput
                  name="net_weight"
                  label="Peso Líquido"
                  disabled={!canEdit}
                  required
                  prefix="KG "
                />
                <ValueInput
                  name="stock_balance"
                  label="Saldo em Estoque"
                  disabled={!canEdit}
                  prefix="QTD "
                />
                <ValueInput
                  name="ipi_percentage"
                  label="% IPI"
                  disabled={!canEdit}
                  prefix="% "
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Selector
                  name="unit_of_measure_uuid"
                  label="Unidade de Medida"
                  idColumn="uuid"
                  nameColumn="name"
                  options={unit_of_measures}
                  loadData={loadUnitOfMeasures}
                  loading={loadingUnitOfMeasures || productLoading}
                  required
                  disabled={!canEdit}
                />
                <Selector
                  name="product_group_uuid"
                  label="Grupo de Produto"
                  idColumn="uuid"
                  nameColumn="name"
                  options={product_groups}
                  loadData={loadProductGroups}
                  loading={loadingProductGroups || productLoading}
                  disabled={!canEdit}
                  required
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <Button
            disabled={productLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {productLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default ProductFormContent;

ProductFormContent.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  tablecolor: PropTypes.bool,
};

ProductFormContent.defaultProps = {
  tablecolor: true,
};
