import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconSearch from '@material-ui/icons/Search';
import IconError from '@material-ui/icons/Error';

import { useAuth } from '~/providers/auth';
import { useUser } from '~/providers/user';
import { useCustomerGroup } from '~/providers/customerGroup';
import { useCustomer } from '~/providers/customer';
import { useErrorHandler } from '~/providers/errorHandler';

import SearchCnpjService from '~/services/searchCnpj';
import SearchZipCodeService from '~/services/searchZipCode';
import indexCities from '~/services/indexCities';

import { validate } from '~/validators/customer';

import { setFormData } from '~/utils/form';
import {
  cnpjMask,
  cpfMask,
  zipCodeMask,
  phoneMask,
  cellMask,
  numberUnmask,
} from '~/utils/mask';
import { parseFormData } from '~/utils/customer';

import {
  Form,
  Button,
  Loading,
  TextInput,
  ValueInput,
  RadioGroup,
  DatePicker,
  Selector,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
  Tabs,
  TabText,
  SwipeableViews,
  Paper,
  TitleContent,
} from './styles';

const customerTypes = [
  { value: 'F', label: 'Física' },
  { value: 'J', label: 'Jurídica' },
];

const customerStatus = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

function CustomerForm({ setShowDialog, tablecolor }) {
  const location = useLocation();

  const {
    customer,
    customerLoading,
    customerErrors,
    store,
    update,
    newCustomer,
    clearState,
  } = useCustomer();
  const { indexSelector: userIndexSelector } = useUser();
  const { indexSelector: customerGroupIndexSelector } = useCustomerGroup();
  const { logged_branch, user } = useAuth();
  const { setErrorHandlerData } = useErrorHandler();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);
  const [exempted, setExempted] = useState(false);
  const [type, setType] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [users, setUsers] = useState([]);
  const [customer_groups, setCustomerGroups] = useState([]);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingCustomerGroups, setLoadingCustomerGroups] = useState(false);
  const [Tabsvalue, setTabsValue] = useState(0);
  const [pageOrderIndex, setPageOrderIndex] = useState(0);
  const [contactErrors, setContactErrors] = useState(false);
  const [addressErrors, setAddressErrors] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  function handleChangeType(newType) {
    setType(newType);

    setFormData({ formRef, formData: { cpf_cnpj: '' } });
  }

  function handleChangeExempted(event) {
    setExempted(event.target.checked);

    setFormData({ formRef, formData: { state_inscription: '' } });
  }

  async function handleSearchZipCode() {
    await SearchZipCodeService({
      setLoadingZipCode,
      formRef,
      setErrorHandlerData,
      states,
    });
  }

  async function handleSearchCnpj() {
    await SearchCnpjService({
      setLoadingCnpj,
      formRef,
      setErrorHandlerData,
      handleSearchZipCode,
    });
  }

  const loadCustomerGroups = useCallback(
    async (search = '') => {
      setLoadingCustomerGroups(true);
      const auxCustomerGroups = await customerGroupIndexSelector({ search });
      setLoadingCustomerGroups(false);

      setCustomerGroups(auxCustomerGroups);
    },
    [customerGroupIndexSelector]
  );

  const loadUsers = useCallback(
    async (search = '') => {
      setLoadingUsers(true);
      const auxUsers = await userIndexSelector({ search });
      setLoadingUsers(false);

      setUsers(auxUsers);
    },
    [userIndexSelector]
  );

  async function handleChangeState(state) {
    if (state) {
      setLoadingCities(true);
      const { cities: auxCities } = await indexCities({ state_id: state.id });
      setLoadingCities(false);

      setCities(auxCities);

      const city_id =
        formRef.current && formRef.current.getFieldValue('city_id');

      if (city_id) {
        const findedCity = auxCities.find(city => city.id === city_id);

        if (formRef.current) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(findedCity);
        }
      }
    } else {
      setCities([]);

      if (formRef.current) {
        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(null);
      }
    }
  }

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages?.ddd1 || errorMessages?.telephone1) {
      setContactErrors(true);
    } else setContactErrors(false);

    if (
      errorMessages?.zip_code ||
      errorMessages?.state_id ||
      errorMessages?.neighborhood ||
      errorMessages?.city_id
    ) {
      setAddressErrors(true);
    } else setAddressErrors(false);

    if (errorMessages && formRef.current) {
      formRef.current.setErrors(errorMessages);
    } else {
      const findedState = states.find(state => state.id === data.state_id);
      const findedCity = cities.find(city => city.id === data.city_id);

      const parsedCustomer = {
        ...parsedData,
        req_id: '1',
        state: findedState,
        city: findedCity,
      };

      if (customer.uuid)
        await update({
          customer: { ...customer, ...parsedCustomer },
          setShowDialog,
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedCustomer.company_key = company_key;
        parsedCustomer.branch_key = branch_key;

        await store({ customer: parsedCustomer, setShowDialog });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(customerErrors).length &&
      customerErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(customerErrors.validations);
  }, [customerErrors]);

  useEffect(() => {
    async function loadData() {
      const {
        customer_groups: auxCustomerGroups,
        states: auxStates,
        users: auxUsers,
      } = await newCustomer();

      setCustomerGroups(auxCustomerGroups);
      setStates(auxStates);
      setUsers(auxUsers);
    }

    if (customer.uuid) {
      const auxCustomer = { ...customer };

      const {
        customer_groups: auxCustomerGroups,
        states: auxStates,
        cities: auxCities,
        users: auxUsers,
        type: auxType,
        status_code,
        credit_maturity_at,
        state,
        city,
        customer_group,
        seller1,
        seller2,
        ...customerData
      } = auxCustomer;

      setCustomerGroups(auxCustomerGroups);
      setStates(auxStates);
      setCities(auxCities);
      setUsers(auxUsers);

      if (formRef.current) {
        const typeInputRef = formRef.current.getFieldRef('type');
        typeInputRef.handleChange({ target: { value: auxType } });

        const statusCodeInputRef = formRef.current.getFieldRef('status_code');
        statusCodeInputRef.handleChange({ target: { value: String(status_code) } }); // prettier-ignore

        const stateInputRef = formRef.current.getFieldRef('state_id');
        stateInputRef.handleChange(state);

        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(city);

        if (credit_maturity_at) {
          const creditMaturityAtInputRef = formRef.current.getFieldRef('credit_maturity_at'); // prettier-ignore
          creditMaturityAtInputRef.handleChange(new Date(credit_maturity_at));
        }

        if (seller1) {
          const seller1InputRef = formRef.current.getFieldRef('seller1_uuid');
          seller1InputRef.handleChange(seller1);
        }

        if (seller2) {
          const seller2InputRef = formRef.current.getFieldRef('seller2_uuid');
          seller2InputRef.handleChange(seller2);
        }

        if (customer_group) {
          const customerGroupInputRef = formRef.current.getFieldRef('customer_group_uuid'); // prettier-ignore
          customerGroupInputRef.handleChange(customer_group);
        }

        if (!auxCustomer.state_inscription) setExempted(true);
      }

      const formData = parseFormData(customerData);

      setFormData({ formRef, formData });

      if (
        location.state &&
        !location.state.showCustomer &&
        !location.state.newOrder
      )
        setCanEdit(true);
      else setCanEdit(false);

      window.history.replaceState(null, '');
    } else if (
      !location.state ||
      location.state.newCustomer ||
      location.state.newOrder
    ) {
      loadData();

      setCanEdit(true);
    }
  }, [customer, newCustomer, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
                <RadioGroup
                  name="type"
                  label="Tipo"
                  options={customerTypes}
                  onChange={handleChangeType}
                  required
                  disabled={
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                />
                <TextInput
                  name="cpf_cnpj"
                  label={!type ? 'CPF / CNPJ' : type === 'F' ? 'CPF' : 'CNPJ'} // eslint-disable-line
                  helperText={!type ? 'Selecione um Tipo antes' : ''}
                  mask={type === 'F' ? cpfMask : cnpjMask}
                  disabled={
                    !type ||
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                  required
                  endAdornment={
                    type && type === 'J' ? (
                      <IconButton
                        size="small"
                        edge="end"
                        disabled={
                          loadingCnpj ||
                          !canEdit ||
                          (!!customer.legacy_code &&
                            !user.is_admin &&
                            !user.is_manager)
                        }
                        onClick={handleSearchCnpj}
                      >
                        {loadingCnpj ? <Loading /> : <IconSearch />}
                      </IconButton>
                    ) : null
                  }
                />
                <TextInput
                  name="state_inscription"
                  label="Inscrição Estadual"
                  mask={numberUnmask}
                  maxLength={20}
                  disabled={
                    exempted ||
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          !canEdit ||
                          (!!customer.legacy_code &&
                            !user.is_admin &&
                            !user.is_manager)
                        }
                        checked={exempted}
                        onChange={handleChangeExempted}
                        color="primary"
                      />
                    }
                    label="Isento"
                  />
                </FormGroup>
              </FormSingleContent>
              <FormSingleContent>
                <TextInput
                  name="first_name"
                  label="Razão Social"
                  required
                  disabled={
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                />
                <TextInput
                  name="last_name"
                  label="Nome Fantasia"
                  disabled={
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                />
                <TextInput name="email" label="E-mail" disabled={!canEdit} />
                <RadioGroup
                  name="status_code"
                  label="Status"
                  options={customerStatus}
                  required
                  disabled={
                    !canEdit ||
                    (!!customer.legacy_code &&
                      !user.is_admin &&
                      !user.is_manager)
                  }
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <Paper>
            <Tabs
              value={Tabsvalue}
              onChange={handleChangeTabs}
              indicatorColor="secondary"
              textColor="secondary"
              centered
              style={{ background: '#1f7ecc' }}
            >
              <TabText
                onClick={() => setPageOrderIndex(0)}
                label={
                  <>
                    Contatos
                    {contactErrors && (
                      <Tooltip
                        placement="right-start"
                        arrow
                        title="Campos não preenchidos"
                      >
                        <div style={{ display: 'flex', marginLeft: 4 }}>
                          <IconError />
                        </div>
                      </Tooltip>
                    )}
                  </>
                }
              />
              <TabText
                onClick={() => setPageOrderIndex(1)}
                label={
                  <>
                    Endereços
                    {addressErrors && (
                      <Tooltip
                        placement="right-start"
                        arrow
                        title="Campos não preenchidos"
                      >
                        <div style={{ display: 'flex', marginLeft: 4 }}>
                          <IconError />
                        </div>
                      </Tooltip>
                    )}
                  </>
                }
              />
              <TabText onClick={() => setPageOrderIndex(2)} label="Vendas" />
            </Tabs>
          </Paper>
          <TitleContent>
            <SwipeableViews index={pageOrderIndex}>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="ddd1"
                    label="DDD 1"
                    mask={numberUnmask}
                    maxLength={2}
                    required
                    disabled={!canEdit}
                  />
                  <TextInput
                    name="telephone1"
                    label="Telefone 1"
                    mask={value =>
                      numberUnmask(value).length <= 8
                        ? phoneMask(value)
                        : cellMask(value)
                    }
                    required
                    disabled={!canEdit}
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <TextInput
                    name="ddd2"
                    label="DDD 2"
                    mask={numberUnmask}
                    maxLength={2}
                    disabled={!canEdit}
                  />
                  <TextInput
                    name="telephone2"
                    label="Telefone 2"
                    mask={value =>
                      numberUnmask(value).length <= 8
                        ? phoneMask(value)
                        : cellMask(value)
                    }
                    disabled={!canEdit}
                  />
                </FormSingleContent>
              </FormContent>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="zip_code"
                    label="CEP"
                    mask={zipCodeMask}
                    required
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                    endAdornment={
                      <IconButton
                        size="small"
                        edge="end"
                        disabled={
                          loadingZipCode ||
                          !canEdit ||
                          (!!customer.legacy_code &&
                            !user.is_admin &&
                            !user.is_manager)
                        }
                        onClick={handleSearchZipCode}
                      >
                        {loadingZipCode ? <Loading /> : <IconSearch />}
                      </IconButton>
                    }
                  />
                  <Selector
                    name="state_id"
                    label="Estado"
                    idColumn="id"
                    nameColumn="name"
                    options={states}
                    onChange={handleChangeState}
                    loading={customerLoading}
                    required
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                  <Selector
                    name="city_id"
                    label="Cidade"
                    idColumn="id"
                    nameColumn="name"
                    options={cities}
                    loading={loadingCities || customerLoading}
                    required
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <TextInput
                    name="neighborhood"
                    label="Bairro"
                    required
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                  <TextInput
                    name="address"
                    label="Endereço"
                    required
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="number"
                    label="Número"
                    mask={numberUnmask}
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                  <TextInput
                    name="complement"
                    label="Complemento"
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
              </FormContent>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <ValueInput
                    name="credit_limit"
                    label="Crédito Limite"
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                    prefix="R$ "
                  />
                  <DatePicker
                    name="credit_maturity_at"
                    label="Vencimento Crédito"
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <Selector
                    name="customer_group_uuid"
                    label="Grupo de Cliente"
                    idColumn="uuid"
                    nameColumn="name"
                    options={customer_groups}
                    loadData={loadCustomerGroups}
                    loading={loadingCustomerGroups || customerLoading}
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                  <Selector
                    name="seller1_uuid"
                    label="Vendedor 1"
                    idColumn="uuid"
                    nameColumn="name"
                    options={users}
                    loadData={loadUsers}
                    loading={loadingUsers || customerLoading}
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <Selector
                    name="seller2_uuid"
                    label="Vendedor 2"
                    idColumn="uuid"
                    nameColumn="name"
                    options={users}
                    loadData={loadUsers}
                    loading={loadingUsers || customerLoading}
                    disabled={
                      !canEdit ||
                      (!!customer.legacy_code &&
                        !user.is_admin &&
                        !user.is_manager)
                    }
                  />
                </FormSingleContent>
              </FormContent>
            </SwipeableViews>
          </TitleContent>
          <Button
            disabled={customerLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {customerLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default CustomerForm;

CustomerForm.propTypes = {
  setShowDialog: PropTypes.func,
  tablecolor: PropTypes.bool,
};

CustomerForm.defaultProps = {
  setShowDialog: null,
  tablecolor: true,
};
