import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/orderItem';
import errorHandler from '~/utils/errorHandler';

export async function index({
  order_uuid = '',
  search = '',
  order_by = '',
  order = '',
}) {
  try {
    const { data } = await apiFacin
      .get('/order-items', { params: { order_uuid, search, order_by, order } })
      .then(res => res.data);

    return { orderItems: parseToView(data) };
  } catch (error) {
    const orderItemErrors = errorHandler({
      error,
      title: 'Error ao listar Itens do Pedido',
      local: 'OrderItemActionsIndex',
    });

    return { orderItemErrors };
  }
}

export async function show({ orderItemUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/order-items/${orderItemUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.order_item;

    return { orderItem: parseToView([{ ...data.order_item, ...auxData }])[0] };
  } catch (error) {
    const orderItemErrors = errorHandler({
      error,
      title: 'Error ao visualizar Item do Pedido',
      local: 'OrderItemActionsShow',
    });

    return { orderItemErrors };
  }
}

export async function store({ orderItem = {} }) {
  try {
    const { data } = await apiFacin
      .post('/order-items', {
        data: [orderItem],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...orderItem, ...data[0].order_item }])[0];
  } catch (error) {
    const orderItemErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Item do Pedido',
      local: 'OrderItemActionsStore',
    });

    return { orderItemErrors };
  }
}

export async function update({ orderItem = {} }) {
  try {
    const { data } = await apiFacin
      .put('/order-items', {
        data: [orderItem],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...orderItem, ...data[0].order_item }])[0];
  } catch (error) {
    const orderItemErrors = errorHandler({
      error,
      title: 'Error ao atualizar Item do Pedido',
      local: 'OrderItemActionsUpdate',
    });

    return { orderItemErrors };
  }
}

export async function destroy({ orderItem = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/order-items`,
      method: 'delete',
      data: { data: [orderItem] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const orderItemErrors = errorHandler({
      error,
      title: 'Error ao remover Item do Pedido',
      local: 'OrderItemActionsDestroy',
    });

    return { orderItemErrors };
  }
}
