import React, { useEffect, useState, useCallback } from 'react';
import { valueMask } from '~/utils/mask';
import { useAuth } from '~/providers/auth';
import TableList from '~/components/TableList';

import {
  Container,
  WelcomeContainer,
  WelcomeText,
  WelcomeCards,
  Card,
  CardIcon,
  CardInfo,
  SellersContainer,
  Equalizer,
  GraphicEq,
  Receipt,
  CircularProgress,
  ListContainer,
} from './styles';

const headCells = [
  {
    id: 'image',
    label: 'Foto',
    image: true,
    disablePadding: true,
  },
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'sales_goal', label: 'Meta do Mês (R$)' },
  { id: 'month_sales', label: 'Vendas do Mês (R$)' },
  { id: 'expected_commission', label: 'Comissão Prevista (R$)' },
];

function Dashboard() {
  const { user, dashboardInfo, authLoading } = useAuth();

  const [sales_goal, setSalesGoal] = useState('');
  const [month_sales, setMonthSales] = useState('');
  const [expected_commission, setExpectedCommission] = useState('');
  const [sellers, setSellers] = useState([]);

  const loadSellers = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      const auxDashboardInfo = await dashboardInfo({ search, order_by, order });
      setSalesGoal(valueMask(auxDashboardInfo.logged_seller?.sales_goal));
      setMonthSales(valueMask(auxDashboardInfo.logged_seller?.month_sales));
      setExpectedCommission(
        valueMask(auxDashboardInfo.logged_seller?.expected_commission)
      );
      setSellers(auxDashboardInfo.sellers);
    },
    [dashboardInfo]
  );

  useEffect(() => {
    loadSellers();
  }, [loadSellers]);

  return (
    <Container>
      <WelcomeContainer>
        <WelcomeText>
          <h1>Olá, {user.name}! Seja bem vindo!</h1>
        </WelcomeText>
        <WelcomeText>
          <p>Suas atualizações.</p>
        </WelcomeText>
        <WelcomeCards>
          <Card type="sales_goal">
            <CardIcon>
              <GraphicEq fontSize="large" />
            </CardIcon>
            <CardInfo>
              {authLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <h2>
                    <span>R$</span> {sales_goal || '0,00'}
                  </h2>
                  <p>Metas do Mês</p>
                </>
              )}
            </CardInfo>
          </Card>
          <Card type="month_sales">
            <CardIcon>
              <Receipt fontSize="large" />
            </CardIcon>
            <CardInfo>
              {authLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <h2>
                    <span>R$</span> {month_sales || '0,00'}
                  </h2>
                  <p>Vendas do Mês</p>
                </>
              )}
            </CardInfo>
          </Card>
          <Card type="expected_comission">
            <CardIcon>
              <Equalizer fontSize="large" />
            </CardIcon>
            <CardInfo>
              {authLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <h2>
                    <span>R$</span> {expected_commission || '0,00'}
                  </h2>
                  <p>Comissão Prevista</p>
                </>
              )}
            </CardInfo>
          </Card>
        </WelcomeCards>
      </WelcomeContainer>
      {(user.is_admin || user.is_manager) && (
        <SellersContainer>
          <ListContainer>
            <TableList
              title={<p>Atualizações dos vendedores</p>}
              headCells={headCells}
              data={sellers}
              loadData={loadSellers}
              loading={authLoading}
            />
          </ListContainer>
        </SellersContainer>
      )}
    </Container>
  );
}

export default Dashboard;
