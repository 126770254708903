import React, { useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useCustomer } from '~/providers/customer';
import { useNotSale } from '~/providers/notSale';
import { useAuth } from '~/providers/auth';

import NotSaleDialog from '~/components/Dialogs/NotSale';
import TableList, { WrapperContainer } from '~/components/TableList';

import { Container } from './styles';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'generic_table_name', label: 'Motivo' },
  { id: 'observation', label: 'Observação' },
];

function NotSales({ canEdit }) {
  const { customer } = useCustomer();
  const { notSales, notSaleListLoading, index, show, destroy } = useNotSale();
  const { user, current_accesses } = useAuth();

  const dialogRef = useRef(null);

  const [showDialog, setShowDialog] = useState(false);

  const loadNotSales = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ customer_uuid: customer.uuid, search, order_by, order });
    },
    [index, customer]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.not-sales.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () => {
              if (dialogRef.current) {
                dialogRef.current.handleLoadData();
                dialogRef.current.setCanEdit(true);

                setShowDialog(true);
              }
            },
          }
        : null,
    [current_accesses, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async notSale => {
          setShowDialog(true);

          await show({ notSaleUuid: notSale.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.not-sales.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        disabled: !canEdit,
        handleFunction: async notSale => {
          if (!user.is_admin && !user.is_manager && notSale.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            if (dialogRef.current) dialogRef.current.setCanEdit(true);

            setShowDialog(true);

            await show({ notSaleUuid: notSale.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.not-sales.delete']
    )
      auxActions.push({
        label: 'Remover',
        disabled: !canEdit,
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: notSale => {
          if (!user.is_admin && !user.is_manager && notSale.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ notSale });
        },
      });

    return auxActions;
  }, [user, current_accesses, canEdit, show, destroy]);

  return (
    <Container>
      <WrapperContainer>
        <NotSaleDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          ref={dialogRef}
        />
        <TableList
          title="Não Vendas"
          newItem={newItem}
          headCells={headCells}
          data={notSales}
          loadData={loadNotSales}
          loading={notSaleListLoading}
          actions={actions}
          canEdit={canEdit}
        />
      </WrapperContainer>
    </Container>
  );
}

export default NotSales;

NotSales.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};
