import {
  cnpjMask,
  cpfMask,
  valueUnmask,
  valueMask,
  numberUnmask,
} from './mask';

export function parseToView(users = []) {
  return users.map(user => ({
    ...user,
    cpf_cnpj:
      user.type === 'F' ? cpfMask(user.cpf_cnpj) : cnpjMask(user.cpf_cnpj),
    commission_percentage: valueMask(user.commission_percentage),
    commission_value: valueMask(user.commission_value),
    sales_goal: valueMask(user.sales_goal),
  }));
}

export function parseDataToApi(user = {}) {
  return {
    commission_percentage: valueUnmask(user.commission_percentage) || null,
    commission_value: valueUnmask(user.commission_value) || null,
    company_key: user.company_key,
    cpf_cnpj: numberUnmask(user.cpf_cnpj),
    email: user.email,
    image: user.image,
    legacy_code: user.legacy_code,
    licenses: numberUnmask(user.licenses),
    name: user.name,
    req_id: user.req_id,
    sales_goal: valueUnmask(user.sales_goal) || null,
    type: user.type,
    user: {},
    users: [],
    companies: [],
    uuid: user.uuid,
  };
}

export function parseFormData(user = {}) {
  return {
    legacy_code: user.legacy_code,
    cpf_cnpj: user.cpf_cnpj,
    name: user.name,
    licenses: user.licenses,
    commission_percentage: user.commission_percentage,
    commission_value: user.commission_value,
    sales_goal: user.sales_goal,
  };
}
