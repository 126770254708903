import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  genericTables: [],
  genericTable: {},
  genericTableErrors: {},
  genericTableLoading: false,
  genericTableListLoading: false,
};

const GenericTableContext = createContext(INITIAL_STATE);

export function GenericTableProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setGenericTableData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setGenericTableData({ genericTableListLoading: true });

      const genericTableData = await actions.index({
        search,
        order_by,
        order,
      });

      if (genericTableData.genericTableErrors)
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setGenericTableData({
        ...genericTableData,
        genericTableListLoading: false,
      });
    },
    [setGenericTableData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ genericTableUuid = '' }) => {
      setGenericTableData({ genericTableLoading: true });

      const genericTableData = await actions.show({ genericTableUuid });

      if (genericTableData.genericTableErrors) {
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => show({ genericTableUuid }),
          },
        });

        history.goBack();
      }

      setGenericTableData({
        ...genericTableData,
        genericTableLoading: false,
      });
    },
    [setGenericTableData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ genericTable = {} }) => {
      setGenericTableData({ genericTableLoading: true });

      const genericTableData = await actions.store({ genericTable });

      if (genericTableData.genericTableErrors) {
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => store({ genericTable }),
          },
        });

        setGenericTableData({
          ...genericTableData,
          genericTableLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          genericTables: [genericTableData, ...oldData.genericTables],
          genericTableLoading: false,
        }));

        history.goBack();

        toast.success('Tabela Genérica cadastrada com sucesso!');
      }
    },
    [setGenericTableData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ genericTable = {} }) => {
      setGenericTableData({ genericTableLoading: true });

      const genericTableData = await actions.update({ genericTable });

      if (genericTableData.genericTableErrors) {
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => update({ genericTable }),
          },
        });

        setGenericTableData({
          ...genericTableData,
          genericTableLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          genericTables: [
            genericTableData,
            ...oldData.genericTables.filter(
              auxGenericTable => auxGenericTable.uuid !== genericTable.uuid
            ),
          ],
          genericTableLoading: false,
        }));

        history.goBack();

        toast.success('Tabela Genérica atualizada com sucesso!');
      }
    },
    [setGenericTableData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ genericTable = {} }) => {
      setGenericTableData({ genericTableListLoading: true });

      const genericTableData = await actions.destroy({ genericTable });

      if (genericTableData.genericTableErrors) {
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => destroy({ genericTable }),
          },
        });

        setGenericTableData({
          ...genericTableData,
          genericTableListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          genericTables: [
            ...oldData.genericTables.filter(
              auxGenericTable => auxGenericTable.uuid !== genericTable.uuid
            ),
          ],
          genericTableListLoading: false,
        }));

        toast.success('Tabela Genérica removida com sucesso!');
      }
    },
    [setGenericTableData, setErrorHandlerData]
  );

  const newGenericTable = useCallback(async () => {
    setGenericTableData({ genericTableLoading: true });

    const genericTableData = await actions.newGenericTable();

    if (genericTableData.genericTableErrors)
      setErrorHandlerData({
        error: {
          ...genericTableData.genericTableErrors,
          resolveFunction: () => newGenericTable(),
        },
      });

    setGenericTableData({ genericTableLoading: false });

    return { generic_tables: [], ...genericTableData };
  }, [setGenericTableData, setErrorHandlerData]);

  const indexSelector = useCallback(
    async ({ search = '', table = '0000000000' }) => {
      setGenericTableData({ genericTableLoading: true });

      const genericTableData = await actions.indexSelector({ search, table });

      if (genericTableData.genericTableErrors) {
        setErrorHandlerData({
          error: {
            ...genericTableData.genericTableErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setGenericTableData({ genericTableLoading: false });
      return genericTableData.genericTables;
    },
    [setGenericTableData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        genericTable: {},
        genericTableErrors: {},
        genericTableLoading: false,
        genericTableListLoading: false,
      };
    });
  }, []);

  return (
    <GenericTableContext.Provider
      value={{
        ...data,
        setGenericTableData,
        index,
        show,
        store,
        update,
        destroy,
        newGenericTable,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </GenericTableContext.Provider>
  );
}

export function useGenericTable() {
  const context = useContext(GenericTableContext);

  if (!context)
    throw new Error(
      'useGenericTable must be used within an GenericTableProvider'
    );

  return context;
}

GenericTableProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
