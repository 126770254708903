import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

  &::-webkit-scrollbar-track{
    background: #CFD8DC;
  }

  &::-webkit-scrollbar{
    width: 11px;
  }

  &::-webkit-scrollbar-thumb{
    background: #90A4AE;
    border-radius: 6px;
    border: 3px solid #CFD8DC;
  }
  
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    display: flex;
    flex: 1;
    min-height: 100%;
    width: 100%;
  }

  body {
    background: ${props => props.theme.colors.backFirst};
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, textarea, button {
    color: ${props => props.theme.colors.textFirst};
    font-size: 14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }



  .Toastify__toast-body {
    overflow: hidden;
    font-size: 1rem;
  }
  .Toastify__toast--warning {
    color: black
  }


`;
