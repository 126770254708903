import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/product-groups', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { productGroups: data };
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao listar Grupos de Produtos',
      local: 'ProductGroupActionsIndex',
    });

    return { productGroupErrors };
  }
}

export async function show({ productGroupUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/product-groups/${productGroupUuid}`)
      .then(res => res.data);

    return { productGroup: data.product_group };
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao visualizar Grupo de Produto',
      local: 'ProductGroupActionsShow',
    });

    return { productGroupErrors };
  }
}

export async function store({ productGroup = {} }) {
  try {
    const { data } = await apiFacin
      .post('/product-groups', {
        data: [productGroup],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...productGroup, ...data[0].product_group };
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Grupo de Produto',
      local: 'ProductGroupActionsStore',
    });

    return { productGroupErrors };
  }
}

export async function update({ productGroup = {} }) {
  try {
    const { data } = await apiFacin
      .put('/product-groups', {
        data: [productGroup],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...productGroup, ...data[0].product_group };
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao atualizar Grupo de Produto',
      local: 'ProductGroupActionsUpdate',
    });

    return { productGroupErrors };
  }
}

export async function destroy({ productGroup = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/product-groups`,
      method: 'delete',
      data: { data: [productGroup] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao remover Grupo de Produto',
      local: 'ProductGroupActionsDestroy',
    });

    return { productGroupErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: productGroups } = await apiFacin
      .get(`/product-groups`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { productGroups };
  } catch (error) {
    const productGroupErrors = errorHandler({
      error,
      title: 'Error ao listar Grupos de Produtos',
      local: 'ProductGroupActionsIndexSelector',
    });

    return { productGroupErrors };
  }
}
