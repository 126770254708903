import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/shares', { params: { search, order_by, order } })
      .then(res => res.data);

    return { shares: data };
  } catch (error) {
    const shareErrors = errorHandler({
      error,
      title: 'Error ao listar Compartilhamentos',
      local: 'ShareActionsIndex',
    });

    return { shareErrors };
  }
}

export async function show({ shareUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/shares/${shareUuid}`)
      .then(res => res.data);

    return { share: data.share };
  } catch (error) {
    const shareErrors = errorHandler({
      error,
      title: 'Error ao visualizar Compartilhamento',
      local: 'ShareActionsShow',
    });

    return { shareErrors };
  }
}

export async function store({ share = {} }) {
  try {
    const { data } = await apiFacin
      .post('/shares', {
        data: [share],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...share, ...data[0].share };
  } catch (error) {
    const shareErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Compartilhamento',
      local: 'ShareActionsStore',
    });

    return { shareErrors };
  }
}

export async function update({ share = {} }) {
  try {
    const { data } = await apiFacin
      .put('/shares', {
        data: [share],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...share, ...data[0].share };
  } catch (error) {
    const shareErrors = errorHandler({
      error,
      title: 'Error ao atualizar Compartilhamento',
      local: 'ShareActionsUpdate',
    });

    return { shareErrors };
  }
}

export async function destroy({ share = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/shares`,
      method: 'delete',
      data: { data: [share] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const shareErrors = errorHandler({
      error,
      title: 'Error ao atualizar Compartilhamento',
      local: 'ShareActionsDestroy',
    });

    return { shareErrors };
  }
}
