import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  paymentMethods: [],
  paymentMethod: {},
  paymentMethodErrors: {},
  paymentMethodLoading: false,
  paymentMethodListLoading: false,
};

const PaymentMethodContext = createContext(INITIAL_STATE);

export function PaymentMethodProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setPaymentMethodData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setPaymentMethodData({ paymentMethodListLoading: true });

      const paymentMethodData = await actions.index({
        search,
        order_by,
        order,
      });

      if (paymentMethodData.paymentMethodErrors)
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setPaymentMethodData({
        ...paymentMethodData,
        paymentMethodListLoading: false,
      });
    },
    [setPaymentMethodData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ paymentMethodUuid = '' }) => {
      setPaymentMethodData({ paymentMethodLoading: true });

      const paymentMethodData = await actions.show({ paymentMethodUuid });

      if (paymentMethodData.paymentMethodErrors) {
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => show({ paymentMethodUuid }),
          },
        });

        history.goBack();
      }

      setPaymentMethodData({
        ...paymentMethodData,
        paymentMethodLoading: false,
      });
    },
    [setPaymentMethodData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ paymentMethod = {} }) => {
      setPaymentMethodData({ paymentMethodLoading: true });

      const paymentMethodData = await actions.store({ paymentMethod });

      if (paymentMethodData.paymentMethodErrors) {
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => store({ paymentMethod }),
          },
        });

        setPaymentMethodData({
          ...paymentMethodData,
          paymentMethodLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          paymentMethods: [paymentMethodData, ...oldData.paymentMethods],
          paymentMethodLoading: false,
        }));

        history.goBack();

        toast.success('Forma de pagamento cadastrada com sucesso!');
      }
    },
    [setPaymentMethodData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ paymentMethod = {} }) => {
      setPaymentMethodData({ paymentMethodLoading: true });

      const paymentMethodData = await actions.update({ paymentMethod });

      if (paymentMethodData.paymentMethodErrors) {
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => update({ paymentMethod }),
          },
        });

        setPaymentMethodData({
          ...paymentMethodData,
          paymentMethodLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          paymentMethods: [
            paymentMethodData,
            ...oldData.paymentMethods.filter(
              auxPaymentMethod => auxPaymentMethod.uuid !== paymentMethod.uuid
            ),
          ],
          paymentMethodLoading: false,
        }));

        history.goBack();

        toast.success('Forma de pagamento atualizada com sucesso!');
      }
    },
    [setPaymentMethodData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ paymentMethod = {} }) => {
      setPaymentMethodData({ paymentMethodListLoading: true });

      const paymentMethodData = await actions.destroy({ paymentMethod });

      if (paymentMethodData.paymentMethodErrors) {
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => destroy({ paymentMethod }),
          },
        });

        setPaymentMethodData({
          ...paymentMethodData,
          paymentMethodListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          paymentMethods: [
            ...oldData.paymentMethods.filter(
              auxPaymentMethod => auxPaymentMethod.uuid !== paymentMethod.uuid
            ),
          ],
          paymentMethodListLoading: false,
        }));

        toast.success('Forma de pagamento removida com sucesso!');
      }
    },
    [setPaymentMethodData, setErrorHandlerData]
  );

  const newPaymentMethod = useCallback(async () => {
    setPaymentMethodData({ paymentMethodLoading: true });

    const paymentMethodData = await actions.newPaymentMethod();

    if (paymentMethodData.paymentMethodErrors)
      setErrorHandlerData({
        error: {
          ...paymentMethodData.paymentMethodErrors,
          resolveFunction: () => newPaymentMethod(),
        },
      });

    setPaymentMethodData({ paymentMethodLoading: false });

    return paymentMethodData;
  }, [setPaymentMethodData, setErrorHandlerData]);

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setPaymentMethodData({ paymentMethodLoading: true });

      const paymentMethodData = await actions.indexSelector({ search });

      if (paymentMethodData.paymentMethodErrors) {
        setErrorHandlerData({
          error: {
            ...paymentMethodData.paymentMethodErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setPaymentMethodData({ paymentMethodLoading: false });

      return paymentMethodData.paymentMethods;
    },
    [setPaymentMethodData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        paymentMethod: {},
        paymentMethodErrors: {},
        paymentMethodLoading: false,
        paymentMethodListLoading: false,
      };
    });
  }, []);

  return (
    <PaymentMethodContext.Provider
      value={{
        ...data,
        setPaymentMethodData,
        index,
        show,
        store,
        update,
        destroy,
        clearState,
        newPaymentMethod,
        indexSelector,
      }}
    >
      {children}
    </PaymentMethodContext.Provider>
  );
}

export function usePaymentMethod() {
  const context = useContext(PaymentMethodContext);

  if (!context)
    throw new Error(
      'usePaymentMethod must be used within an PaymentMethodProvider'
    );

  return context;
}

PaymentMethodProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
