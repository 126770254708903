import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { useAuth } from '~/providers/auth';
import { useReport } from '~/providers/reports';

import TableList, { WrapperContainer } from '~/components/TableList';
import GeneratePdfDialog from '~/components/Dialogs/GeneratePdf';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'description', label: 'Descrição' },
];

function Reports() {
  const history = useHistory();

  const { reports, reportListLoading, index, show, destroy } = useReport();
  const { user, current_accesses } = useAuth();

  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const loadReports = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/reports/form', { newReport: true }),
          }
        : null,
    [history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    if (user.is_admin)
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async report => {
          history.push('/reports/form', { showReport: true });

          await show({ reportUuid: report.uuid });
        },
      });

    if (user.is_admin)
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async report => {
          if (!user.is_admin && !user.is_manager && report.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/reports/form', { updateReport: true });

            await show({ reportUuid: report.uuid });
          }
        },
      });

    if (user.is_admin)
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: report => {
          if (!user.is_admin && !user.is_manager && report.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ report });
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.reports/generate/:uuid.get']
    )
      auxActions.push({
        label: 'Gerar PDF',
        icon: <PictureAsPdfIcon fontSize="small" />,
        handleFunction: async report => {
          await show({ reportUuid: report.uuid });
          setShowGenerateDialog(true);
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && reports.length <= 1) loadReports();

    setLoaded(true);
  }, [reports, loadReports, loaded]);

  return (
    <WrapperContainer>
      <GeneratePdfDialog
        showDialog={showGenerateDialog}
        setShowDialog={setShowGenerateDialog}
      />
      <TableList
        title="Relatórios"
        newItem={newItem}
        headCells={headCells}
        data={reports}
        loadData={loadReports}
        loading={reportListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Reports;
