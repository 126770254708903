import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/genericTable';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    let parsedOrderBy = order_by;

    if (order_by === 'table_view')
      parsedOrderBy = parsedOrderBy.replace('_view', '');

    const { data } = await apiFacin
      .get('/generic-tables', {
        params: { search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { genericTables: parseToView(data) };
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao listar Tabelas Genéricas',
      local: 'GenericTableActionsIndex',
    });

    return { genericTableErrors };
  }
}

export async function show({ genericTableUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/generic-tables/${genericTableUuid}`)
      .then(res => res.data);

    const { generic_table, generic_tables } = data;

    return {
      genericTable: { ...generic_table, genericTablesTypes: generic_tables },
    };
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao visualizar Tabela Genérica',
      local: 'GenericTableActionsShow',
    });

    return { genericTableErrors };
  }
}

export async function store({ genericTable = {} }) {
  try {
    const { data } = await apiFacin
      .post('/generic-tables', {
        data: [genericTable],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...genericTable, ...data[0].generic_table }])[0];
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Tabela Genérica',
      local: 'GenericTableActionsStore',
    });

    return { genericTableErrors };
  }
}

export async function update({ genericTable = {} }) {
  try {
    const { data } = await apiFacin
      .put('/generic-tables', {
        data: [genericTable],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...genericTable, ...data[0].generic_table }])[0];
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao atualizar Tabela Genérica',
      local: 'GenericTableActionsUpdate',
    });

    return { genericTableErrors };
  }
}

export async function destroy({ genericTable = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/generic-tables`,
      method: 'delete',
      data: { data: [genericTable] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao remover Tabela Genérica',
      local: 'GenericTableActionsDestroy',
    });

    return { genericTableErrors };
  }
}

export async function newGenericTable() {
  try {
    const { data: genericTables } = await apiFacin
      .get('/new-generic-table')
      .then(res => res.data);

    return genericTables;
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para nova Tabela Genérica',
      local: 'GenericTableNewGenericTable',
    });

    return { genericTableErrors };
  }
}

export async function indexSelector({ search = '', table = '0000000000' }) {
  try {
    const { data: genericTables } = await apiFacin
      .get(`/generic-tables`, {
        params: { search, for_selector: true, table },
      })
      .then(res => res.data);

    return { genericTables };
  } catch (error) {
    const genericTableErrors = errorHandler({
      error,
      title: 'Error ao listar Tabelas',
      local: 'GenericTableIndexSelector',
    });

    return { genericTableErrors };
  }
}
