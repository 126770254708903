/* eslint-disable no-nested-ternary */
import { format, parseISO } from 'date-fns';
import { numberMask, valueUnmask, valueMask } from './mask';

import { parseToView as orderItemParseToView } from './orderItem';
import { parseToView as paymentMethodParseToView } from './paymentMethod';

export function parseToView(orders = []) {
  return orders.map(order => {
    let status_uuid_view = '';

    if (order.status_uuid) status_uuid_view = order?.status?.name;

    return {
      ...order,
      status_uuid_view,
      customer_name: order.customer?.first_name,
      payment_method_name: order.payment_method?.name,
      total_sale_value: valueMask(order.total_sale_value, 2),
      freight_value: valueMask(order.freight_value, 2),
      ipi_value: valueMask(order.ipi_value, 2),
      gross_value: valueMask(order.gross_value, 2),
      net_value: valueMask(order.net_value, 2),
      discount_value: valueMask(order.discount_value, 2),
      discount_percentage: valueMask(order.discount_percentage, 2),
      gross_weight: valueMask(order.gross_weight, 2),
      net_weight: valueMask(order.net_weight, 2),
      status_name:
        order.status_code === 1
          ? 'Pendente'
          : order.status_code === 2
          ? 'Liberado'
          : order.status_code === 3
          ? 'Aprovado'
          : order.status_code === 5
          ? 'Faturado'
          : order.status_code === 9
          ? 'Cancelado ERP'
          : 'Recusado',
      order_items: order.order_items?.length
        ? orderItemParseToView(order.order_items)
        : [],
      payment_method: order.payment_method
        ? paymentMethodParseToView([order.payment_method])[0]
        : {},
      created_at_view: format(parseISO(order.created_at), "dd'/'MM'/'yyyy"),
    };
  });
}

export function parseDataToApi(order = {}) {
  return {
    ...order,
    total_sale_value: valueUnmask(order.total_sale_value),
    freight_value: valueUnmask(order.freight_value),
    ipi_value: valueUnmask(order.ipi_value),
    gross_value: valueUnmask(order.gross_value),
    net_value: valueUnmask(order.net_value),
    discount_value: valueUnmask(order.discount_value),
    discount_percentage: valueUnmask(order.discount_percentage),
    gross_weight: valueUnmask(order.gross_weight),
    net_weight: valueUnmask(order.net_weight),
    deadline: numberMask(order.deadline),
    status_code: numberMask(order.status_code),
  };
}

export function parseFormData(order = {}) {
  return {
    status_uuid: order.status_uuid,
    legacy_code: order.legacy_code,
    total_sale_value: order.total_sale_value,
    freight_value: order.freight_value,
    ipi_value: order.ipi_value,
    gross_value: order.gross_value,
    net_value: order.net_value,
    discount_value: order.discount_value,
    discount_percentage: order.discount_percentage,
    gross_weight: order.gross_weight,
    net_weight: order.net_weight,
    deadline: order.deadline,
    observation: order.observation,
  };
}

export function base64toPDF(b64PDF) {
  function b64toBlob(b64Data, sliceSize = 512) {
    const byteChar = atob(b64Data);

    const byteArr = [];

    for (let offset = 0; offset < byteChar.length; offset += sliceSize) {
      const slice = byteChar.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArr.push(byteArray);
    }

    const blob = new Blob(byteArr, { type: 'application/pdf' });
    return blob;
  }

  const blob = b64toBlob(b64PDF);
  const blobUrl = URL.createObjectURL(blob);

  const iframeEle = document.getElementById('Iframe');

  if (iframeEle) {
    iframeEle.src = blobUrl;
    setTimeout(() => iframeEle.contentWindow.print(), 500);
  }
}
