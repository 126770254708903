import { parseToView as productParseToView } from '~/utils/product';

import { numberMask, valueUnmask, valueMask } from './mask';

export function parseToView(orderItems = []) {
  return orderItems.map(orderItem => ({
    ...orderItem,
    quantity: valueMask(orderItem.quantity, 6),
    unitary_net_value: valueMask(orderItem.unitary_net_value, 6),
    unitary_gross_value: valueMask(orderItem.unitary_gross_value, 6),
    unitary_discount_value: valueMask(orderItem.unitary_discount_value, 6),
    unitary_gross_weight: valueMask(orderItem.unitary_gross_weight, 6),
    unitary_net_weight: valueMask(orderItem.unitary_net_weight, 6),
    total_net_value: valueMask(orderItem.total_net_value, 2),
    total_gross_value: valueMask(orderItem.total_gross_value, 2),
    total_discount_value: valueMask(orderItem.total_discount_value, 2),
    total_gross_weight: valueMask(orderItem.total_gross_weight, 2),
    total_net_weight: valueMask(orderItem.total_net_weight, 2),
    discount_percentage: valueMask(orderItem.discount_percentage, 6),
    ipi_value: valueMask(orderItem.ipi_value, 6),
    ipi_percentage: valueMask(orderItem.ipi_percentage, 2),
    commission_percentage: valueMask(orderItem.commission_percentage, 2),
    commission_value: valueMask(orderItem.commission_value, 2),
    product: productParseToView([orderItem.product])[0],
    product_name: orderItem.product.name,
    product_image:
      orderItem.product.product_images &&
      orderItem.product.product_images.length
        ? orderItem.product.product_images[0].image
        : null,
  }));
}

export function parseDataToApi(orderItem = {}) {
  return {
    ...orderItem,
    quantity: valueUnmask(orderItem.quantity),
    unitary_net_value: valueUnmask(orderItem.unitary_net_value),
    unitary_gross_value: valueUnmask(orderItem.unitary_gross_value),
    unitary_discount_value: valueUnmask(orderItem.unitary_discount_value),
    unitary_gross_weight: valueUnmask(orderItem.unitary_gross_weight),
    unitary_net_weight: valueUnmask(orderItem.unitary_net_weight),
    total_net_value: valueUnmask(orderItem.total_net_value),
    total_gross_value: valueUnmask(orderItem.total_gross_value),
    total_discount_value: valueUnmask(orderItem.total_discount_value),
    total_gross_weight: valueUnmask(orderItem.total_gross_weight),
    total_net_weight: valueUnmask(orderItem.total_net_weight),
    discount_percentage: valueUnmask(orderItem.discount_percentage),
    ipi_value: valueUnmask(orderItem.ipi_value),
    ipi_percentage: valueUnmask(orderItem.ipi_percentage),
    commission_percentage: valueUnmask(orderItem.commission_percentage),
    commission_value: valueUnmask(orderItem.commission_value),
    deadline: numberMask(orderItem.deadline),
  };
}

export function parseFormData(orderItem = {}) {
  return {
    legacy_code: orderItem.legacy_code,
    discount_value: orderItem.discount_value,
    discount_percentage: orderItem.discount_percentage,
    quantity: orderItem.quantity,
  };
}
