export function parseToBase64(file) {
  if (!file) return new Promise(resolve => resolve());
  const reader = new FileReader();

  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onabort = () => resolve();
    reader.onerror = () => reject(reader.error);
  });
}
