import * as Yup from 'yup';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      company_key: Yup.string().required('Este campo é obrigatório'),
      customers: Yup.boolean().required('Este campo é obrigatório'),
      customer_groups: Yup.boolean().required('Este campo é obrigatório'),
      freight_tables: Yup.boolean().required('Este campo é obrigatório'),
      generic_tables: Yup.boolean().required('Este campo é obrigatório'),
      not_sales: Yup.boolean().required('Este campo é obrigatório'),
      orders: Yup.boolean().required('Este campo é obrigatório'),
      order_items: Yup.boolean().required('Este campo é obrigatório'),
      payment_methods: Yup.boolean().required('Este campo é obrigatório'),
      price_lists: Yup.boolean().required('Este campo é obrigatório'),
      products: Yup.boolean().required('Este campo é obrigatório'),
      product_groups: Yup.boolean().required('Este campo é obrigatório'),
      product_images: Yup.boolean().required('Este campo é obrigatório'),
      unit_of_measures: Yup.boolean().required('Este campo é obrigatório'),
    });

    await schema.validate(data, { abortEarly: false });

    return data;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
