import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddAPhotoOutlined from '@material-ui/icons/AddAPhotoOutlined';
import DeleteForever from '@material-ui/icons/DeleteForever';

import { useProductImage } from '~/providers/productImage';

import ImageContainer from '~/components/ImageContainer';
import ProductImageDialog from '~/components/Dialogs/ProductImage';

import { Grid, GridListTileBar, IconButton, Loading } from '~/components/Form';

function ProductImages({ canEdit }) {
  const { productImages, productImageLoading, destroy } = useProductImage();

  const [showDialog, setShowDialog] = useState(false);

  async function handleDestroyProductImage(productImage) {
    await destroy({ productImage });
  }

  return (
    <Grid container spacing={1}>
      <Grid container justify="space-between" spacing={1}>
        <Grid item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="primary"
              disabled={productImageLoading || !canEdit}
              onClick={() => setShowDialog(true)}
            >
              <AddAPhotoOutlined />
              {productImageLoading && <Loading />}
            </IconButton>
            <ProductImageDialog
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
            <h1>Imagens</h1>
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        />
      </Grid>
      <Grid container spacing={2}>
        {productImages.map(productImage => (
          <Grid
            key={productImage.uuid}
            item
            style={{ margin: 8, padding: 0, width: 150, position: 'relative' }}
          >
            <ImageContainer
              size={150}
              src={[productImage.image]}
              alt={productImage.description}
            />
            <GridListTileBar
              style={{ borderRadius: 4 }}
              title={productImage.description}
              subtitle={productImage.legacy_code}
              actionIcon={
                <IconButton
                  color="primary"
                  disabled={productImageLoading || !canEdit}
                  onClick={() => handleDestroyProductImage(productImage)}
                >
                  <DeleteForever />
                </IconButton>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ProductImages;

ProductImages.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};
