import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import SwipViews from 'react-swipeable-views';
import MUIGrid from '@material-ui/core/Grid';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const SwipeableViews = styled(SwipViews).attrs({
  disabled: true,
})`
  width: 100%;

  .react-swipeable-view-container {
    height: 100%;
  }

  .react-swipeable-view-container > * {
    display: flex;
    align-items: flex-start;
  }
`;

export const Grid = styled(MUIGrid)`
  width: 210px;

  padding: 0;

  &.MuiGrid-container {
    width: 100%;
    margin: 0;
  }
`;
