import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useGenericTable } from '~/providers/genericTable';

import { validate } from '~/validators/genericTable';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/genericTable';

import {
  Form,
  Button,
  Loading,
  TextInput,
  Selector,
  RadioGroup,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
  FormScroll,
  ButtonContent,
} from './styles';

const levels = [
  { value: '1', label: 'Admin' },
  { value: '2', label: 'Gerente' },
];

function GenericTableForm({ tablecolor }) {
  const location = useLocation();

  const {
    genericTable,
    genericTableLoading,
    genericTableErrors,
    newGenericTable,
    indexSelector,
    store,
    update,
    clearState,
  } = useGenericTable();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);
  const history = useHistory();

  const [canEdit, setCanEdit] = useState(false);
  const [genericTableTypes, setGenericTableTypes] = useState([]);
  const [helperText, setHelperText] = useState({});

  const loadGenericTableTypes = useCallback(
    async (search = '') => {
      const auxGenericTableTypes = await indexSelector({
        search,
        table: '0000000000',
      });

      setGenericTableTypes(auxGenericTableTypes);
    },
    [indexSelector]
  );

  const handleChangeTable = useCallback(auxTable => {
    setHelperText({
      value1: auxTable?.value1,
      value2: auxTable?.value2,
      value3: auxTable?.value3,
      value4: auxTable?.value4,
      value5: auxTable?.value5,
    });
  }, []);

  async function handleSubmit(data) {
    const dataToValidate = { ...data };

    if (!user.is_admin) {
      dataToValidate.level = genericTable.level || '2';
      dataToValidate.company_key =
        genericTable.company_key || logged_branch.company_key;
      dataToValidate.branch_key =
        genericTable.branch_key || logged_branch.branch_key;
    }

    const { errorMessages, ...parsedData } = await validate(dataToValidate);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedGenericTable = {
        ...parsedData,
        req_id: '1',
      };

      if (genericTable.uuid)
        await update({
          genericTable: { ...genericTable, ...parsedGenericTable },
        });
      else await store({ genericTable: parsedGenericTable });
    }
  }

  useEffect(() => {
    if (
      Object.keys(genericTableErrors).length &&
      genericTableErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(genericTableErrors.validations);
  }, [genericTableErrors]);

  useEffect(() => {
    async function loadData() {
      const { generic_tables: auxGenericTablesTypes } = await newGenericTable();
      setGenericTableTypes(auxGenericTablesTypes);
    }

    if (genericTable.uuid) {
      const auxGenericTable = { ...genericTable };

      const {
        genericTablesTypes: auxGenericTablesTypes,
        table: auxTable,
        level,
        ...genericTableData
      } = auxGenericTable;

      const findedTable = auxGenericTablesTypes?.find(
        genericTablesType => genericTablesType.table_key === auxTable
      );

      const levelInputRef = formRef.current.getFieldRef('level');
      if (levelInputRef)
        levelInputRef.handleChange({ target: { value: String(level) } });

      if (findedTable) {
        const tableInputRef = formRef.current.getFieldRef('table');
        tableInputRef.handleChange(findedTable);
      }

      const formData = parseFormData(genericTableData);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showGenericTable) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newGenericTable) {
      setCanEdit(true);
    }

    loadData();
  }, [genericTable, location, newGenericTable]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormScroll>
            <h2>Especificações</h2>
            <FormContainer>
              <FormContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="legacy_code"
                    label="Código Legado"
                    maxLength={10}
                    disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                  />
                  <Selector
                    name="table"
                    label="Tabela"
                    idColumn="table_key"
                    nameColumn="name"
                    onChange={handleChangeTable}
                    options={genericTableTypes}
                    loadData={loadGenericTableTypes}
                    loading={genericTableLoading}
                    required
                    disabled={!canEdit}
                  />
                  {user.is_admin && (
                    <RadioGroup
                      name="level"
                      label="Nível"
                      options={levels}
                      required
                      disabled={
                        !canEdit || (!user.is_admin && !user.is_manager)
                      }
                    />
                  )}
                </FormSingleContent>
                <FormSingleContent>
                  <TextInput
                    name="table_key"
                    label="Chave da Tabela"
                    maxLength={10}
                    required
                    disabled={!canEdit}
                  />
                  <TextInput
                    name="name"
                    label="Nome"
                    required
                    disabled={!canEdit}
                  />
                </FormSingleContent>
              </FormContent>
            </FormContainer>
            <FormContainer>
              <FormContent>
                <h2>Valores</h2>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                  <TextInput
                    name="value1"
                    label="Valor 1"
                    helper={helperText?.value1}
                    disabled={!canEdit}
                  />
                  <TextInput
                    name="value2"
                    label="Valor 2"
                    helper={helperText?.value2}
                    disabled={!canEdit}
                  />
                </FormSingleContent>
                <FormSingleContent>
                  <TextInput
                    name="value3"
                    label="Valor 3"
                    helper={helperText?.value3}
                    disabled={!canEdit}
                  />
                  <TextInput
                    name="value4"
                    label="Valor 4"
                    helper={helperText?.value4}
                    disabled={!canEdit}
                  />
                </FormSingleContent>
                <FormSingleContent table={tablecolor ? 'tablecolor' : ' '}>
                  <TextInput
                    name="value5"
                    label="Valor 5"
                    helper={helperText?.value5}
                    disabled={!canEdit}
                  />
                </FormSingleContent>
              </FormContent>
            </FormContainer>
          </FormScroll>
          <ButtonContent>
            <Button
              disabled={genericTableLoading || !canEdit}
              type="button"
              variant="outlined"
              color="default"
              onClick={() => history.goBack()}
            >
              Cancelar
              {genericTableLoading && <Loading />}
            </Button>
            <Button
              disabled={genericTableLoading || !canEdit}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Confirmar
              {genericTableLoading && <Loading />}
            </Button>
          </ButtonContent>
        </Form>
      </DataContent>
    </Container>
  );
}

export default GenericTableForm;

GenericTableForm.propTypes = {
  tablecolor: PropTypes.bool,
};

GenericTableForm.defaultProps = {
  tablecolor: true,
};
