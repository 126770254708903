import React from 'react';
import { useAuth } from '~/providers/auth';
import Illustration from '~/assets/images/illustration.png';
import SignContent from './content';
import CompanyBranchesContent from '~/pages/CompanyBranches/content';
import {
  Container,
  SwipeableViews,
  IconButton,
  IconArrowBack,
  WelcomeContainer,
  FormContainer,
  Facile,
  Copyright,
  FacinSales,
} from './styles';

function Sign() {
  const { authPageIndex, setAuthData } = useAuth();

  return (
    <Container>
      <WelcomeContainer background={Illustration}>
        <Facile>
          <p>Portal desenvolvido por Facile Sistemas</p>
        </Facile>
        <FacinSales>
          <h1>Bem vindo ao FacIN Sales</h1>
          <p>Sistema de gestão de pedido de vendas.</p>
        </FacinSales>
        <Copyright login>Todos os direitos reservados</Copyright>
      </WelcomeContainer>
      <FormContainer>
        {authPageIndex !== 0 && (
          <IconButton
            onClick={() => setAuthData({ authPageIndex: authPageIndex - 1 })}
          >
            <IconArrowBack />
          </IconButton>
        )}
        <SwipeableViews index={authPageIndex}>
          <SignContent />
          <CompanyBranchesContent />
        </SwipeableViews>
      </FormContainer>
    </Container>
  );
}

export default Sign;
