import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/paymentMethod';

function validateDateTermInstallment(paymentMethod) {
  const regexComma = /\s*,\s*/;
  const auxInstallments = paymentMethod?.term_installment.split(regexComma);

  let validationResult = true;

  auxInstallments.forEach((auxInstallment, index) => {
    const findIndex = index + 1;
    const secondValue = Number(auxInstallments[findIndex]);

    if (secondValue) {
      if (Number(auxInstallment) > secondValue) {
        validationResult = false;
      }
    }
  });
  return validationResult;
}

function validateQuantityTermInstallment(paymentMethod) {
  const regexComma = /\s*,\s*/;
  const auxInstallment = paymentMethod?.term_installment.split(regexComma);

  if (paymentMethod?.quantity_installment !== auxInstallment.length)
    return false;

  return true;
}

export async function validate(data) {
  try {
    const parsedData = parseDataToApi(data);

    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      name: Yup.string().required('Este campo é obrigatório'),
      type_uuid: Yup.string().required('Este campo é obrigatório'),
      start_discount_percentage: Yup.string().nullable(),
      max_discount_percentage: Yup.string().nullable(),
      quantity_installment: Yup.string().nullable(),
      term_installment: Yup.string()
        .matches(
          /^[-+]?([0-9]{1,3})(?:,[0-9]{1,3})*(?:,[0-9]{1,3})?$/gm,
          'Não está no formato correto. Ex: 20,40,50'
        )
        .test(
          'validateQuantityTermInstallment',
          'Os prazos das parcelas devem respeitar a quantidade de parcelas.',
          () => validateQuantityTermInstallment(parsedData)
        )
        .test(
          'validateDateTermInstallment',
          'Os prazos devem ser crescentes.',
          () => validateDateTermInstallment(parsedData)
        ),
      min_per_installment: Yup.string().nullable(),
    });

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
