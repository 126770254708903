export default {
  title: 'light',

  colors: {
    primary: '#1f7ecc',
    primaryDarkened: '#0f3e65',
    secondary: '#061928',

    cartButton: '#5B8D5B',
    cartButtonHover: '#7BBA7B',
    cartButtonIcon: '#70B270',

    refuseButton: '#CC1C1C',
    refuseButtonHover: '#F74A4A',

    acceptButton: '#3C833C',
    acceptButtonHover: '#5EBF5E',

    backFirst: '#f6f8f9',
    backSecond: '#f0f0f0',

    textPrimary: '#f0f0f0',

    textFirst: '#000',
    textSecond: '#9ea9ac',

    backHeader: '#ededed',
    backAside: '#ffffff',
    backAsideHover: '#eeeeee',
    backScrollBar: '#aaaaaa',
    backFromSystemMessage: '#e1f3fb',
    backFromCustomerMessage: '#ffffff',
    backFromMeMessage: '#dcf8c6',
    backMessageShadow: 'rgba(0, 0, 0, 0.2)',
    backSendMessage: '#f0f0f0',
    backInputMessage: '#ffffff',
    backModalButtonHover: '#eeeeee',

    borderDivider: '#dcdcdc',

    textThird: '#595959',
    textFourth: '#616161',
    textFifth: '#777777',

    tableTitle: '#F2F2F2',
    tableContent: '#F8F8F8',
  },
};
