import styled from 'styled-components';
import Btn from '@material-ui/core/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: ${props => props.theme.colors.backMain};
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
  variant: 'contained',
})`
  &.MuiButtonBase-root {
    margin-top: 10px;
  }
`;
