import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useProduct } from '~/providers/product';
import { useProductImage } from '~/providers/productImage';

import { validate } from '~/validators/productImage';

import { parseToBase64 } from '~/utils/file';
import { parseFormData } from '~/utils/productImage';

import { Form, Grid, Loading, TextInput } from '../Form';
import { Dialog, DialogActions, DialogTitle, Button } from './styles';
import ImageContainer from '../ImageContainer';
import { setFormData } from '~/utils/form';

function ProductImageDialog({ showDialog, setShowDialog }) {
  const { logged_branch } = useAuth();
  const { product } = useProduct();
  const {
    productImage,
    productImageLoading,
    store,
    update,
    productImageErrors,
    clearState,
  } = useProductImage();

  const formRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState('');

  async function handleChange(event) {
    if (
      event.target.files.length &&
      event.target.files[0].type.startsWith('image/')
    ) {
      const base64Data = await parseToBase64(event.target.files[0]).catch(err =>
        alert(JSON.stringify(err && err.message))
      );

      setSelectedImage(base64Data);

      setImageError('');
    }
  }

  function handleClose() {
    setShowDialog(false);
    setSelectedImage(null);
    clearState({});
  }

  async function handleSubmit() {
    if (selectedImage && formRef.current) {
      const { errorMessages, ...parsedData } = await validate({
        product_uuid: product.uuid,
        image: selectedImage,
        ...formRef.current.getData(),
      });

      const parsedProductImage = {
        ...parsedData,
        req_id: '1',
      };

      if (errorMessages && formRef.current)
        formRef.current.setErrors(errorMessages);
      else {
        let response = null;

        if (productImage.uuid)
          response = await update({
            productImage: { ...productImage, ...parsedProductImage },
          });
        else {
          const { company_key, branch_key } = logged_branch;

          parsedProductImage.company_key = company_key;
          parsedProductImage.branch_key = branch_key;

          response = await store({ productImage: parsedProductImage });
        }

        if (response && response.uuid) handleClose();
      }
    } else setImageError('Este campo é obrigatório');
  }

  useEffect(() => {
    if (
      Object.keys(productImageErrors).length &&
      productImageErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(productImageErrors.validations);
  }, [productImageErrors]);

  useEffect(() => {
    if (productImage.uuid) {
      const auxProductImage = { ...productImage };

      const { image, ...productImageData } = auxProductImage;

      setSelectedImage(image);

      const formData = parseFormData(productImageData);

      setFormData({ formRef, formData });
    }
  }, [productImage]);

  return (
    <Dialog
      open={showDialog}
      onClose={!productImageLoading ? handleClose : () => {}}
    >
      <DialogTitle>Imagem</DialogTitle>
      <Form style={{ maxWidth: 300 }} ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1} justify="center">
          <Grid item style={{ width: 'unset' }}>
            <Button>
              <label htmlFor="image">
                Selecione um imagem
                {imageError && (
                  <>
                    <br />
                    <p style={{ fontSize: 10, color: '#d50000' }}>
                      Este campo é obrigatório
                    </p>
                  </>
                )}
                <input
                  id="image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
              </label>
            </Button>
          </Grid>
          <Grid item style={{ width: 'unset' }}>
            <ImageContainer size={150} src={[selectedImage]} />
          </Grid>
          <Grid item>
            <TextInput
              name="legacy_code"
              label="Código Legado"
              maxLength={10}
            />
          </Grid>
          <Grid item>
            <TextInput name="description" label="Descrição" required />
          </Grid>
        </Grid>
      </Form>
      <DialogActions>
        <Button
          disabled={productImageLoading}
          onClick={handleClose}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          disabled={productImageLoading}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >
          Finalizar
          {productImageLoading && <Loading />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductImageDialog;

ProductImageDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
