import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useFreightTable } from '~/providers/freightTable';
import { useCustomerGroup } from '~/providers/customerGroup';
import { useCustomer } from '~/providers/customer';

import indexCities from '~/services/indexCities';

import { validate } from '~/validators/freightTable';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/freightTable';

import {
  Form,
  Button,
  Loading,
  TextInput,
  ValueInput,
  DatePicker,
  Selector,
  RadioGroup,
} from '~/components/Form';

import {
  Container,
  DataContent,
  FormContainer,
  FormContent,
  FormSingleContent,
} from './styles';

const freightTableStatus = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

function FreightTableForm({ tablecolor }) {
  const location = useLocation();

  const {
    freightTable,
    freightTableLoading,
    freightTableErrors,
    store,
    update,
    newFreightTable,
    clearState,
  } = useFreightTable();
  const { indexSelector: customerGroupIndexSelector } = useCustomerGroup();
  const { indexSelector: customerIndexSelector } = useCustomer();
  const { logged_branch, user } = useAuth();

  const formRef = useRef(null);

  const [customer_groups, setCustomerGroups] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingCustomerGroups, setLoadingCustomerGroups] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const loadCustomerGroups = useCallback(
    async (search = '') => {
      setLoadingCustomerGroups(true);
      const auxCustomerGroups = await customerGroupIndexSelector({ search });
      setLoadingCustomerGroups(false);

      setCustomerGroups(auxCustomerGroups);
    },
    [customerGroupIndexSelector]
  );

  const loadCustomers = useCallback(
    async (search = '') => {
      setLoadingCustomers(true);
      const auxCustomers = await customerIndexSelector({ search });
      setLoadingCustomers(false);

      setCustomers(auxCustomers);
    },
    [customerIndexSelector]
  );

  async function handleChangeState(state) {
    if (state) {
      setLoadingCities(true);
      const { cities: auxCities } = await indexCities({ state_id: state.id });
      setLoadingCities(false);

      setCities(auxCities);

      const city_id =
        formRef.current && formRef.current.getFieldValue('city_id');

      if (city_id) {
        const findedCity = auxCities.find(city => city.id === city_id);

        if (formRef.current) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(findedCity);
        }
      }
    } else {
      setCities([]);

      if (formRef.current) {
        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(null);
      }
    }
  }

  async function handleSubmit(data) {
    if (formRef.current) {
      const { errorMessages, ...parsedData } = await validate({
        ...freightTable,
        ...data,
      });

      if (errorMessages && formRef.current)
        formRef.current.setErrors(errorMessages);
      else {
        const findedCustomerGroup = customer_groups.find(
          customer_group => customer_group.uuid === data.customer_group_uuid
        );
        const findedCustomer = customers.find(
          customer => customer.uuid === data.customer_uuid
        );
        const findedState = states.find(state => state.id === data.state_id);
        const findedCity = cities.find(city => city.id === data.city_id);

        const parsedFreightTable = {
          ...parsedData,
          req_id: '1',
          customer_group: findedCustomerGroup,
          customer: findedCustomer,
          state: findedState,
          city: findedCity,
        };

        if (freightTable.uuid)
          await update({
            freightTable: parsedFreightTable,
          });
        else {
          const { company_key, branch_key } = logged_branch;

          parsedFreightTable.company_key = company_key;
          parsedFreightTable.branch_key = branch_key;

          await store({ freightTable: parsedFreightTable });
        }
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(freightTableErrors).length &&
      freightTableErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(freightTableErrors.validations);
  }, [freightTableErrors]);

  useEffect(() => {
    async function loadData() {
      const {
        customer_groups: auxCustomerGroups,
        customers: auxCustomers,
        states: auxStates,
      } = await newFreightTable();

      setCustomerGroups(auxCustomerGroups);
      setCustomers(auxCustomers);
      setStates(auxStates);
    }

    if (freightTable.uuid) {
      const auxFreightTable = { ...freightTable };

      const {
        customer_groups: auxCustomerGroups,
        customers: auxCustomers,
        states: auxStates,
        cities: auxCities,
        status_code,
        start_at,
        end_at,
        state,
        city,
        customer_group,
        customer,
        ...freightTableData
      } = auxFreightTable;

      setCustomerGroups(auxCustomerGroups);
      setCustomers(auxCustomers);
      setStates(auxStates);
      setCities(auxCities);

      if (formRef.current) {
        const statusCodeInputRef = formRef.current.getFieldRef('status_code');
        statusCodeInputRef.handleChange({ target: { value: String(status_code) } }); // prettier-ignore

        const startAtInputRef = formRef.current.getFieldRef('start_at');
        startAtInputRef.handleChange(new Date(start_at));

        const endAtInputRef = formRef.current.getFieldRef('end_at');
        endAtInputRef.handleChange(new Date(end_at));

        if (customer_group) {
          const customerGroupInputRef = formRef.current.getFieldRef('customer_group_uuid'); // prettier-ignore
          customerGroupInputRef.handleChange(customer_group);
        }

        if (customer) {
          const customerInputRef = formRef.current.getFieldRef('customer_uuid');
          customerInputRef.handleChange(customer);
        }

        if (state) {
          const stateInputRef = formRef.current.getFieldRef('state_id');
          stateInputRef.handleChange(state);
        }

        if (city) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(city);
        }
      }

      const formData = parseFormData(freightTableData);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showFreightTable) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newFreightTable) {
      loadData();

      setCanEdit(true);
    }
  }, [freightTable, location, newFreightTable]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <DataContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Especificações</h2>
          <FormContainer>
            <FormContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <TextInput
                  name="legacy_code"
                  label="Código Legado"
                  maxLength={10}
                  disabled={!canEdit || (!user.is_admin && !user.is_manager)}
                />
                <TextInput
                  name="description"
                  label="Descrição"
                  disabled={!canEdit}
                  required
                />
                <RadioGroup
                  name="status_code"
                  label="Status"
                  options={freightTableStatus}
                  required
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <ValueInput
                  name="quantity"
                  label="Quantidade"
                  disabled={!canEdit}
                  prefix="QTD "
                />
                <ValueInput
                  name="value"
                  label="Valor"
                  disabled={!canEdit}
                  prefix="R$ "
                  required
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <ValueInput
                  name="min_weight"
                  label="Peso Mínimo"
                  disabled={!canEdit}
                  prefix="KG "
                />
                <ValueInput
                  name="max_weight"
                  label="Peso Máximo"
                  disabled={!canEdit}
                  prefix="KG "
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <h2>Dados</h2>
            <FormContent>
              <FormSingleContent>
                <DatePicker
                  name="start_at"
                  label="Início Vigência"
                  disabled={!canEdit}
                  required
                />
                <DatePicker
                  name="end_at"
                  label="Fim Vigência"
                  disabled={!canEdit}
                  required
                />
              </FormSingleContent>
              <FormSingleContent table={tablecolor ? 'tablecolor' : ''}>
                <Selector
                  name="customer_group_uuid"
                  label="Grupo de Cliente"
                  idColumn="uuid"
                  nameColumn="name"
                  options={customer_groups}
                  loadData={loadCustomerGroups}
                  loading={freightTableLoading || loadingCustomerGroups}
                  disabled={!canEdit}
                />
                <Selector
                  name="customer_uuid"
                  label="Cliente"
                  idColumn="uuid"
                  nameColumn="first_name"
                  options={customers}
                  loadData={loadCustomers}
                  loading={freightTableLoading || loadingCustomers}
                  disabled={!canEdit}
                />
              </FormSingleContent>
              <FormSingleContent>
                <Selector
                  name="state_id"
                  label="Estado"
                  idColumn="id"
                  nameColumn="name"
                  options={states}
                  onChange={handleChangeState}
                  loading={freightTableLoading}
                  disabled={!canEdit}
                />
                <Selector
                  name="city_id"
                  label="Cidade"
                  idColumn="id"
                  nameColumn="name"
                  options={cities}
                  loading={loadingCities || freightTableLoading}
                  disabled={!canEdit}
                />
              </FormSingleContent>
            </FormContent>
          </FormContainer>

          <Button
            disabled={freightTableLoading || !canEdit}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Confirmar
            {freightTableLoading && <Loading />}
          </Button>
        </Form>
      </DataContent>
    </Container>
  );
}

export default FreightTableForm;

FreightTableForm.propTypes = {
  tablecolor: PropTypes.bool,
};

FreightTableForm.defaultProps = {
  tablecolor: true,
};
