import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '~/providers/auth';
import { useErrorHandler } from '~/providers/errorHandler';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ToastButton,
  Button,
} from './styles';

function ErrorHandler() {
  const { signed, signOut, refreshToken } = useAuth();
  const { error, setErrorHandlerData } = useErrorHandler();

  const [showModal, setShowModal] = useState(false);

  const parsedError = useMemo(() => {
    if (error && (error.message || error.title)) {
      const auxParsedError = {
        title: 'Ocorreu um erro',
        message: `Algo deu errado em: ${error.local}. Contate o Administrador`,
        ...error,
      };

      if (
        error.code === 'E_JWT_TOKEN_EXPIRED' ||
        error.code === 'E_LIMIT_ACCESS'
      ) {
        auxParsedError.aboutAccess = true;
        auxParsedError.showOk = true;

        setShowModal(true);
      } else
        toast.warn(
          <ToastButton onClick={() => setShowModal(true)}>
            {(error.message && error.message.slice(0, 100)) ||
              'Ocorreu um erro'}
            {error.message && error.message.length >= 100 ? '... ' : '. '}
            <b> Detalhes</b>
          </ToastButton>
        );

      return auxParsedError;
    }

    return {};
  }, [error]);

  async function handleCloseModal() {
    if (parsedError.aboutAccess) signOut();

    setErrorHandlerData({ error: null });

    setShowModal(false);
  }

  async function handleSubmit() {
    if (parsedError.aboutAccess && signed) await refreshToken();

    await parsedError.resolveFunction();

    setErrorHandlerData({ error: null });

    setShowModal(false);
  }

  return (
    <Dialog open={showModal} onClose={handleCloseModal}>
      <DialogTitle>{parsedError.title}</DialogTitle>
      <DialogContent style={{ maxWidth: 350 }}>
        <DialogContentText>{parsedError.message}</DialogContentText>
        {parsedError.rest && (
          <>
            <br />
            {parsedError.rest.split('\n').map((body, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                {body} <br />
              </div>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          {parsedError.showOk ? 'Cancelar' : 'Fechar'}
        </Button>
        {parsedError.showOk && (
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Continuar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ErrorHandler;
