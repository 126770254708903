import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  freightTables: [],
  freightTable: {},
  freightTableErrors: {},
  freightTableLoading: false,
  freightTableListLoading: false,
};

const FreightTableContext = createContext(INITIAL_STATE);

export function FreightTableProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setFreightTableData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setFreightTableData({ freightTableListLoading: true });

      const freightTableData = await actions.index({
        search,
        order_by,
        order,
      });

      if (freightTableData.freightTableErrors)
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setFreightTableData({
        ...freightTableData,
        freightTableListLoading: false,
      });
    },
    [setFreightTableData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ freightTableUuid = '' }) => {
      setFreightTableData({ freightTableLoading: true });

      const freightTableData = await actions.show({ freightTableUuid });

      if (freightTableData.freightTableErrors) {
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => show({ freightTableUuid }),
          },
        });
      }

      setFreightTableData({
        ...freightTableData,
        freightTableLoading: false,
      });
    },
    [setFreightTableData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ freightTable = {} }) => {
      setFreightTableData({ freightTableLoading: true });

      const freightTableData = await actions.store({ freightTable });

      if (freightTableData.freightTableErrors) {
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => store({ freightTable }),
          },
        });

        setFreightTableData({
          ...freightTableData,
          freightTableLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          freightTables: [freightTableData, ...oldData.freightTables],
          freightTableLoading: false,
        }));

        history.goBack();

        toast.success('Tabela de Frete cadastrada com sucesso!');
      }
    },
    [setFreightTableData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ freightTable = {} }) => {
      setFreightTableData({ freightTableLoading: true });

      const freightTableData = await actions.update({ freightTable });

      if (freightTableData.freightTableErrors) {
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => update({ freightTable }),
          },
        });

        setFreightTableData({
          ...freightTableData,
          freightTableLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          freightTables: [
            freightTableData,
            ...oldData.freightTables.filter(
              auxFreightTable => auxFreightTable.uuid !== freightTable.uuid
            ),
          ],
          freightTableLoading: false,
        }));

        history.goBack();

        toast.success('Tabela de Frete atualizada com sucesso!');
      }
    },
    [setFreightTableData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ freightTable = {} }) => {
      setFreightTableData({ freightTableListLoading: true });

      const freightTableData = await actions.destroy({ freightTable });

      if (freightTableData.freightTableErrors) {
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => destroy({ freightTable }),
          },
        });

        setFreightTableData({
          ...freightTableData,
          freightTableListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          freightTables: [
            ...oldData.freightTables.filter(
              auxFreightTable => auxFreightTable.uuid !== freightTable.uuid
            ),
          ],
          freightTableListLoading: false,
        }));

        toast.success('Tabela de Frete removida com sucesso!');
      }
    },
    [setFreightTableData, setErrorHandlerData]
  );

  const newFreightTable = useCallback(async () => {
    setFreightTableData({ freightTableLoading: true });

    const freightTableData = await actions.newFreightTable();

    if (freightTableData.freightTableErrors)
      setErrorHandlerData({
        error: {
          ...freightTableData.freightTableErrors,
          resolveFunction: () => newFreightTable(),
        },
      });

    setFreightTableData({ freightTableLoading: false });

    return {
      customer_groups: [],
      customers: [],
      states: [],
      ...freightTableData,
    };
  }, [setFreightTableData, setErrorHandlerData]);

  const freightTableValue = useCallback(
    async ({ customer_uuid, gross_weight }) => {
      setFreightTableData({ freightTableLoading: true });

      const freightTableData = await actions.freightTableValue({
        customer_uuid,
        gross_weight,
      });

      if (freightTableData.freightTableErrors)
        setErrorHandlerData({
          error: {
            ...freightTableData.freightTableErrors,
            resolveFunction: () => freightTableValue(),
          },
        });

      setFreightTableData({ freightTableLoading: false });

      return freightTableData;
    },
    [setFreightTableData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        freightTable: {},
        freightTableErrors: {},
        freightTableLoading: false,
        freightTableListLoading: false,
      };
    });
  }, []);

  return (
    <FreightTableContext.Provider
      value={{
        ...data,
        setFreightTableData,
        index,
        show,
        store,
        update,
        destroy,
        newFreightTable,
        freightTableValue,
        clearState,
      }}
    >
      {children}
    </FreightTableContext.Provider>
  );
}

export function useFreightTable() {
  const context = useContext(FreightTableContext);

  if (!context)
    throw new Error(
      'useFreightTable must be used within an FreightTableProvider'
    );

  return context;
}

FreightTableProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
