import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  unitOfMeasures: [],
  unitOfMeasure: {},
  unitOfMeasureErrors: {},
  unitOfMeasureLoading: false,
  unitOfMeasureListLoading: false,
};

const UnitOfMeasureContext = createContext(INITIAL_STATE);

export function UnitOfMeasureProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setUnitOfMeasureData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setUnitOfMeasureData({ unitOfMeasureListLoading: true });

      const unitOfMeasureData = await actions.index({
        search,
        order_by,
        order,
      });

      if (unitOfMeasureData.unitOfMeasureErrors)
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setUnitOfMeasureData({
        ...unitOfMeasureData,
        unitOfMeasureListLoading: false,
      });
    },
    [setUnitOfMeasureData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ unitOfMeasureUuid = '' }) => {
      setUnitOfMeasureData({ unitOfMeasureLoading: true });

      const unitOfMeasureData = await actions.show({ unitOfMeasureUuid });

      if (unitOfMeasureData.unitOfMeasureErrors) {
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => show({ unitOfMeasureUuid }),
          },
        });

        history.goBack();
      }

      setUnitOfMeasureData({
        ...unitOfMeasureData,
        unitOfMeasureLoading: false,
      });
    },
    [setUnitOfMeasureData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ unitOfMeasure = {} }) => {
      setUnitOfMeasureData({ unitOfMeasureLoading: true });

      const unitOfMeasureData = await actions.store({ unitOfMeasure });

      if (unitOfMeasureData.unitOfMeasureErrors) {
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => store({ unitOfMeasure }),
          },
        });

        setUnitOfMeasureData({
          ...unitOfMeasureData,
          unitOfMeasureLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          unitOfMeasures: [unitOfMeasureData, ...oldData.unitOfMeasures],
          unitOfMeasureLoading: false,
        }));

        history.goBack();

        toast.success('Unidade de Medida cadastrada com sucesso!');
      }
    },
    [setUnitOfMeasureData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ unitOfMeasure = {} }) => {
      setUnitOfMeasureData({ unitOfMeasureLoading: true });

      const unitOfMeasureData = await actions.update({ unitOfMeasure });

      if (unitOfMeasureData.unitOfMeasureErrors) {
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => update({ unitOfMeasure }),
          },
        });

        setUnitOfMeasureData({
          ...unitOfMeasureData,
          unitOfMeasureLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          unitOfMeasures: [
            unitOfMeasureData,
            ...oldData.unitOfMeasures.filter(
              auxUnitOfMeasure => auxUnitOfMeasure.uuid !== unitOfMeasure.uuid
            ),
          ],
          unitOfMeasureLoading: false,
        }));

        history.goBack();

        toast.success('Unidade de Medida atualizada com sucesso!');
      }
    },
    [setUnitOfMeasureData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ unitOfMeasure = {} }) => {
      setUnitOfMeasureData({ unitOfMeasureListLoading: true });

      const unitOfMeasureData = await actions.destroy({ unitOfMeasure });

      if (unitOfMeasureData.unitOfMeasureErrors) {
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => destroy({ unitOfMeasure }),
          },
        });

        setUnitOfMeasureData({
          ...unitOfMeasureData,
          unitOfMeasureListLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          unitOfMeasures: [
            ...oldData.unitOfMeasures.filter(
              auxUnitOfMeasure => auxUnitOfMeasure.uuid !== unitOfMeasure.uuid
            ),
          ],
          unitOfMeasureListLoading: false,
        }));

        toast.success('Unidade de Medida removida com sucesso!');
      }
    },
    [setUnitOfMeasureData, setErrorHandlerData]
  );

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setUnitOfMeasureData({ unitOfMeasureLoading: true });

      const unitOfMeasureData = await actions.indexSelector({ search });

      if (unitOfMeasureData.unitOfMeasureErrors) {
        setErrorHandlerData({
          error: {
            ...unitOfMeasureData.unitOfMeasureErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        return [];
      }

      setUnitOfMeasureData({ unitOfMeasureLoading: false });

      return unitOfMeasureData.unitOfMeasures;
    },
    [setUnitOfMeasureData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        unitOfMeasure: {},
        unitOfMeasureErrors: {},
        unitOfMeasureLoading: false,
        unitOfMeasureListLoading: false,
      };
    });
  }, []);

  return (
    <UnitOfMeasureContext.Provider
      value={{
        ...data,
        setUnitOfMeasureData,
        index,
        show,
        store,
        update,
        destroy,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </UnitOfMeasureContext.Provider>
  );
}

export function useUnitOfMeasure() {
  const context = useContext(UnitOfMeasureContext);

  if (!context)
    throw new Error(
      'useUnitOfMeasure must be used within an UnitOfMeasureProvider'
    );

  return context;
}

UnitOfMeasureProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
