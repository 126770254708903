import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

const INITIAL_STATE = {
  error: null,
};

const ErrorHandlerContext = createContext(INITIAL_STATE);

export function ErrorHandlerProvider({ children }) {
  const [data, setData] = useState(INITIAL_STATE);

  const setErrorHandlerData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  return (
    <ErrorHandlerContext.Provider value={{ ...data, setErrorHandlerData }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export function useErrorHandler() {
  const context = useContext(ErrorHandlerContext);

  if (!context)
    throw new Error(
      'useErrorHandler must be used within an ErrorHandlerProvider'
    );

  return context;
}

ErrorHandlerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
