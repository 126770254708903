import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  productImages: [],
  productImage: {},
  productImageErrors: {},
  productImageLoading: false,
  productImageListLoading: false,
};

const ProductImageContext = createContext(INITIAL_STATE);

export function ProductImageProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setProductImageData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ product_uuid = '', search = '', order_by = '', order = '' }) => {
      setProductImageData({ productImageListLoading: true });

      const productImageData = await actions.index({
        product_uuid,
        search,
        order_by,
        order,
      });

      if (productImageData.productImageErrors)
        setErrorHandlerData({
          error: {
            ...productImageData.productImageErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setProductImageData({
        ...productImageData,
        productImageListLoading: false,
      });
    },
    [setProductImageData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ productImageUuid = '' }) => {
      setProductImageData({ productImageLoading: true });

      const productImageData = await actions.show({ productImageUuid });

      if (productImageData.productImageErrors) {
        setErrorHandlerData({
          error: {
            ...productImageData.productImageErrors,
            resolveFunction: () => show({ productImageUuid }),
          },
        });
      }

      setProductImageData({
        ...productImageData,
        productImageLoading: false,
      });
    },
    [setProductImageData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ productImage = {} }) => {
      setProductImageData({ productImageLoading: true });

      const productImageData = await actions.store({ productImage });

      if (productImageData.productImageErrors) {
        setErrorHandlerData({
          error: {
            ...productImageData.productImageErrors,
            resolveFunction: () => store({ productImage }),
          },
        });

        setProductImageData({
          ...productImageData,
          productImageLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        productImages: [productImageData, ...oldData.productImages],
        productImageLoading: false,
      }));

      toast.success('Imagem de Produto cadastrada com sucesso!');

      return productImageData;
    },
    [setProductImageData, setErrorHandlerData]
  );

  const update = useCallback(
    async ({ productImage = {} }) => {
      setProductImageData({ productImageLoading: true });

      const productImageData = await actions.update({ productImage });

      if (productImageData.productImageErrors) {
        setErrorHandlerData({
          error: {
            ...productImageData.productImageErrors,
            resolveFunction: () => update({ productImage }),
          },
        });

        setProductImageData({
          ...productImageData,
          productImageLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        productImages: [
          productImageData,
          ...oldData.productImages.filter(
            auxProductImage => auxProductImage.uuid !== productImage.uuid
          ),
        ],
        productImageLoading: false,
      }));

      toast.success('Imagem de Produto atualizada com sucesso!');

      return productImageData;
    },
    [setProductImageData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ productImage = {} }) => {
      setProductImageData({ productImageListLoading: true });

      const productImageData = await actions.destroy({ productImage });

      if (productImageData.productImageErrors) {
        setErrorHandlerData({
          error: {
            ...productImageData.productImageErrors,
            resolveFunction: () => destroy({ productImage }),
          },
        });

        setProductImageData({
          ...productImageData,
          productImageListLoading: false,
        });

        return true;
      }

      setData(oldData => ({
        ...oldData,
        productImages: [
          ...oldData.productImages.filter(
            auxProductImage => auxProductImage.uuid !== productImage.uuid
          ),
        ],
        productImageListLoading: false,
      }));

      toast.success('Imagem de Produto removida com sucesso!');

      return true;
    },
    [setProductImageData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        productImage: {},
        productImageErrors: {},
        productImageLoading: false,
        productImageListLoading: false,
      };
    });
  }, []);

  return (
    <ProductImageContext.Provider
      value={{
        ...data,
        setProductImageData,
        index,
        show,
        store,
        update,
        destroy,
        clearState,
      }}
    >
      {children}
    </ProductImageContext.Provider>
  );
}

export function useProductImage() {
  const context = useContext(ProductImageContext);

  if (!context)
    throw new Error(
      'useProductImage must be used within an ProductImageProvider'
    );

  return context;
}

ProductImageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
