import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
`;

export const DataContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 1em;
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }
  form {
    display: flex;
    fieldset {
      align-items: start;
      label {
        margin: 0;
        flex-direction: row;
      }
    }
  }
`;
export const FormContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  max-height: calc(80vh - 200px);
  overflow-y: auto;
  margin-bottom: 1em;
`;
export const FormContent = styled.ul`
  display: table;
  width: 100%;
`;

export const FormSingleContent = styled.li`
  text-align: left;
  background: ${props =>
    props.table ? props.theme.colors.backThird : props.theme.colors.backSecond};
  display: flex;
  align-items: start;
  gap: 0 50px;
  justify-content: start;
  flex: 1;
`;
