import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/priceList';
import errorHandler from '~/utils/errorHandler';

export async function index({
  product_uuid = '',
  search = '',
  order_by = '',
  order = '',
}) {
  try {
    let parsedOrderBy = order_by;

    if (order_by === 'uf_city') parsedOrderBy = 'city_id';
    else if (order_by === 'start_at_view' || order_by === 'end_at_view')
      parsedOrderBy = parsedOrderBy.replace('_view', '');
    else if (
      order_by === 'product_name' ||
      order_by === 'customer_group_name' ||
      order_by === 'customer_name'
    )
      parsedOrderBy = parsedOrderBy.replace('_name', '_uuid');

    const { data } = await apiFacin
      .get('/price-lists', {
        params: { product_uuid, search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { priceLists: parseToView(data) };
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao listar Tabelas de Preços',
      local: 'PriceListActionsIndex',
    });

    return { priceListErrors };
  }
}

export async function show({ priceListUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/price-lists/${priceListUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.price_list;

    return { priceList: parseToView([{ ...data.price_list, ...auxData }])[0] };
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao visualizar Tabela de Preço',
      local: 'PriceListActionsShow',
    });

    return { priceListErrors };
  }
}

export async function store({ priceList = {} }) {
  try {
    const { data } = await apiFacin
      .post('/price-lists', {
        data: [priceList],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...priceList, ...data[0].price_list }])[0];
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Tabela de Preço',
      local: 'PriceListActionsStore',
    });

    return { priceListErrors };
  }
}

export async function update({ priceList = {} }) {
  try {
    const { data } = await apiFacin
      .put('/price-lists', {
        data: [priceList],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...priceList, ...data[0].price_list }])[0];
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao atualizar Tabela de Preço',
      local: 'PriceListActionsUpdate',
    });

    return { priceListErrors };
  }
}

export async function destroy({ priceList = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/price-lists`,
      method: 'delete',
      data: { data: [priceList] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao remover Tabela de Preço',
      local: 'PriceListActionsDestroy',
    });

    return { priceListErrors };
  }
}

export async function newPriceList() {
  try {
    const { data } = await apiFacin
      .get('/new-price-list')
      .then(res => res.data);

    return data;
  } catch (error) {
    const priceListErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para nova Tabela de Preço',
      local: 'PriceListActionsNewPriceList',
    });

    return { priceListErrors };
  }
}
