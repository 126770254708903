import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/product';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      unit_of_measure_uuid: Yup.string().required('Este campo é obrigatório'),
      product_group_uuid: Yup.string().required('Este campo é obrigatório'),
      reference_code: Yup.string().nullable(),
      name: Yup.string().required('Este campo é obrigatório'),
      description: Yup.string().nullable(),
      manufacturer: Yup.string().required('Este campo é obrigatório'),
      price: Yup.string().required('Este campo é obrigatório'),
      gross_weight: Yup.string().required('Este campo é obrigatório'),
      net_weight: Yup.string().required('Este campo é obrigatório'),
      stock_balance: Yup.string().nullable(),
      ipi_percentage: Yup.string().nullable(),
      deadline: Yup.string().nullable(),
      status_code: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
