import styled from 'styled-components';

import DialogProduct from '@material-ui/core/Dialog';

export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Close } from '@material-ui/icons/Close';
export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 1em;
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};

  svg {
    cursor: pointer;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.5;
  padding: 2em;
`;

export const ShipmentDetails = styled.div`
  flex: 2;
  margin-bottom: 2em;

  padding-bottom: 1em;

  h2 {
    font-size: 1.4rem;
    font-weight: 900;
  }
`;

export const InfoDetails = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 2.6;

  p:first-of-type {
    font-weight: 600;
    text-align: left;
  }

  p:last-of-type {
    text-align: right;
  }
`;

export const FinalizeInfoDetails = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  line-height: 2.6;
  align-items: center;

  p:first-of-type {
    font-weight: 600;
    text-align: left;
  }

  p:last-of-type {
    text-align: right;
  }
`;

export const PaymentDetails = styled.div`
  flex: 2;

  h2 {
    font-size: 1.4rem;
    font-weight: 900;
  }
`;

export const OrderDetails = styled.div`
  flex: 2;
  padding: 2em;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.4rem;
    font-weight: 900;
  }
`;

export const Dialog = styled(DialogProduct).attrs({
  fullWidth: true,
  maxWidth: 'md',
})``;
