import { valueMask } from '~/utils/mask';

export function parseToView(sellers = []) {
  return sellers.map(seller => ({
    ...seller,
    month_sales: valueMask(seller.month_sales) || '0,00',
    expected_commission: valueMask(seller.expected_commission) || '0,00',
    sales_goal: valueMask(seller.sales_goal) || '0,00',
  }));
}
