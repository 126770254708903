import * as Yup from 'yup';

export async function generatePdfValidate(data = {}, report_queries = {}) {
  try {
    const parsedSchema = {};

    report_queries.forEach(report_querie => {
      parsedSchema[report_querie.filter_key] = Yup.string();

      if (report_querie.required) {
        parsedSchema[report_querie.filter_key] = parsedSchema[
          report_querie.filter_key
        ].required('Este campo é obrigatório');
      }
    });

    const schema = Yup.object().shape(parsedSchema);

    await schema.validate(data, { abortEarly: false });

    return data;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      name: Yup.string().required('Este campo é obrigatório'),
      description: Yup.string().required('Este campo é obrigatório'),
      table_name: Yup.string().required('Este campo é obrigatório'),
      template: Yup.string().required('Este campo é obrigatório'),
    });

    await schema.validate(data, { abortEarly: false });

    return data;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
