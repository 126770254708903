import React, { useState, useEffect } from 'react';

import { useAuth } from '~/providers/auth';

import Selector from '~/components/Selector';

import { Button, Loading } from './styles';

function CompanyBranchesContent() {
  const {
    companies,
    branches,
    signed,
    authLoading,
    email,
    password,
    authErrors,
    signIn,
    changeBranch,
    authPageIndex,
  } = useAuth();

  const [companySelected, setCompanySelected] = useState(null);
  const [companyError, setCompanyError] = useState('');
  const [auxBranches, setAuxBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);
  const [branchError, setBranchError] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();

    if (!companySelected) setCompanyError('Esse campo é obrigatório');
    if (!branchSelected) setBranchError('Esse campo é obrigatório');

    if (branchSelected)
      if (signed) await changeBranch({ branch_key: branchSelected.branch_key });
      else
        await signIn({
          email,
          password,
          branch_key: branchSelected.branch_key,
        });
  }

  function handleSelectCompany(company) {
    if (company) {
      setCompanySelected(company);
      setAuxBranches(
        branches.filter(branch => branch.company_key === company.company_key)
      );
    } else {
      setCompanySelected(null);
      setAuxBranches([]);
    }

    setBranchSelected(null);
  }

  useEffect(() => {
    if (!authPageIndex) {
      setCompanySelected(null);
      setCompanyError('');
      setAuxBranches([]);
      setBranchSelected(null);
      setBranchError('');
    }
  }, [authPageIndex]);

  useEffect(
    () => authErrors.branch_key && setBranchError(authErrors.branch_key),
    [authErrors]
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>Selecione sua empresa/filial</h2>
      <Selector
        label="Selecione alguma Empresa"
        value={companySelected}
        nameColumn="name"
        options={companies}
        onChange={handleSelectCompany}
        error={companyError}
        setError={setCompanyError}
        containerStyle={{ width: 400 }}
        variant="outlined"
      />
      <Selector
        label="Selecione alguma Filial"
        value={branchSelected}
        nameColumn="first_name"
        options={auxBranches}
        onChange={setBranchSelected}
        error={branchError}
        setError={setBranchError}
        containerStyle={{ width: 400 }}
        variant="outlined"
      />
      <Button disabled={authLoading} variant="contained" type="submit">
        Acessar
        {authLoading && <Loading />}
      </Button>
    </form>
  );
}

export default CompanyBranchesContent;
