import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Magnifier from 'react-magnifier';

import { useAuth } from '~/providers/auth';
import { useProduct } from '~/providers/product';
import { useOrder } from '~/providers/order';
import { useOrderItem } from '~/providers/orderItem';
import { useNavigation } from '~/providers/navigation';

import { validate as orderValidate } from '~/validators/order';
import { validate as orderItemValidate } from '~/validators/orderItem';

import { valueUnmask, valueMask } from '~/utils/mask';

import noImage from '~/assets/images/noImage.png';

import { Form, ValueInput } from '../../Form';
import {
  SingleProductDialog,
  SingleProduct,
  SingleProductNav,
  IconButton,
  Close,
  SingleProductContent,
  SingleProductPriceInfo,
  Remove,
  Add,
  CartButton,
  CartPrice,
  ImageButton,
  ImageTableContent,
  ImageSelector,
  ImageCarousel,
  FeaturedImage,
  TableContent,
  Table,
  TableHead,
  TableRow,
} from './styles';

function OrderItemDialog({ showDialog, setShowDialog, canEdit }) {
  const { logged_branch, user } = useAuth();
  const { setPageIndex } = useNavigation();

  const {
    product,
    productLoading,
    productPrice,
    clearState: productClearState,
  } = useProduct();
  const {
    order,
    header_discount,
    orderLoading,
    show: orderShow,
    store: orderStore,
  } = useOrder();
  const {
    orderItem,
    orderItemLoading,
    orderItemErrors,
    store: orderItemStore,
    update: orderItemUpdate,
    clearState: orderItemClearState,
  } = useOrderItem();

  const formRef = useRef(null);

  const [unitary_discount_value, setUnitaryDiscountValue] = useState(
    '0,000000'
  );
  const [gross_value, setGrossValue] = useState('0,00');
  const [net_value, setNetValue] = useState('0,00');
  const [gross_weight, setGrossWeight] = useState('0,00');
  const [net_weight, setNetWeight] = useState('0,00');
  const [quantity, setQuantity] = useState('1,000000');
  const [total_discount_value, setDiscountValue] = useState('');
  const [discount_percentage, setDiscountPercentage] = useState('');
  const [currentImage, setCurrentImage] = useState(noImage);

  const product_price = useMemo(() => {
    let auxProductPrice = 0;

    if (product.price_list)
      auxProductPrice = valueMask(product.price_list.price, 6);
    else auxProductPrice = product.price;

    const auxDiscountPercentage = valueUnmask(discount_percentage) || 0;
    const auxQuantity = valueUnmask(quantity) || 1;
    const auxGrossValue = (valueUnmask(auxProductPrice) || 0) * auxQuantity;
    const auxDiscountValueUnitary =
      (valueUnmask(auxProductPrice) || 0) * (auxDiscountPercentage / 100);
    const auxDiscountValue = auxDiscountValueUnitary * auxQuantity;
    const auxNetValue = auxGrossValue - auxDiscountValue;
    const auxProductGrossWeight = valueUnmask(product.gross_weight || 0) || 0;
    const auxProductNetWeight = valueUnmask(product.net_weight || 0) || 0;

    setGrossWeight(valueMask(auxQuantity * auxProductGrossWeight) || '0,00');
    setNetWeight(valueMask(auxQuantity * auxProductNetWeight) || '0,00');
    setQuantity(valueMask(auxQuantity, 6) || '1,000000');
    setDiscountValue(valueMask(auxDiscountValue) || '');
    setUnitaryDiscountValue(
      valueMask(auxDiscountValueUnitary, 6) || '0,000000'
    );
    setGrossValue(valueMask(auxGrossValue) || '0,00');
    setNetValue(valueMask(auxNetValue) || '0,00');

    return auxProductPrice;
  }, [product]); // eslint-disable-line

  function handleChangeQuantity(event) {
    const auxDiscountPercentage = valueUnmask(discount_percentage) || 0;
    const auxQuantity = valueUnmask(event.target.value) || 1;
    const auxGrossValue = (valueUnmask(product_price) || 0) * auxQuantity;
    const auxDiscountValueUnitary =
      (valueUnmask(product_price) || 0) * (auxDiscountPercentage / 100);
    const auxDiscountValue = auxDiscountValueUnitary * auxQuantity;
    const auxNetValue = auxGrossValue - auxDiscountValue;
    const auxProductGrossWeight = valueUnmask(product.gross_weight || 0) || 0;
    const auxProductNetWeight = valueUnmask(product.net_weight || 0) || 0;

    setGrossWeight(valueMask(auxQuantity * auxProductGrossWeight) || '0,00');
    setNetWeight(valueMask(auxQuantity * auxProductNetWeight) || '0,00');
    setQuantity(valueMask(auxQuantity, 6) || '1,000000');
    setDiscountValue(valueMask(auxDiscountValue) || '');
    setUnitaryDiscountValue(
      valueMask(auxDiscountValueUnitary, 6) || '0,000000'
    );
    setGrossValue(valueMask(auxGrossValue) || '0,00');
    setNetValue(valueMask(auxNetValue) || '0,00');
  }

  function handleChangeDiscountPercentage(event) {
    const auxDiscountPercentage = valueUnmask(event.target.value) || 0;
    const auxQuantity = valueUnmask(quantity) || 1;
    const auxGrossValue = (valueUnmask(product_price) || 0) * auxQuantity;
    const auxDiscountValueUnitary =
      (valueUnmask(product_price) || 0) * (auxDiscountPercentage / 100);
    const auxDiscountValue = auxDiscountValueUnitary * auxQuantity;
    const auxNetValue = auxGrossValue - auxDiscountValue;

    setDiscountPercentage(valueMask(auxDiscountPercentage, 6) || '');
    setDiscountValue(valueMask(auxDiscountValue) || '');
    setUnitaryDiscountValue(
      valueMask(auxDiscountValueUnitary, 6) || '0,000000'
    );
    setNetValue(valueMask(auxNetValue) || '0,00');
  }

  function handleChangeDiscountValue(event) {
    const auxQuantity = valueUnmask(quantity) || 1;
    const auxGrossValue = (valueUnmask(product_price) || 0) * auxQuantity;
    const auxDiscountValue = valueUnmask(event.target.value) || 0;
    const auxDiscountPercentage = (auxDiscountValue * 100) / auxGrossValue;
    const auxNetValue = auxGrossValue - auxDiscountValue;
    const auxDiscountValueUnitary = auxDiscountValue / auxQuantity;

    setDiscountPercentage(valueMask(auxDiscountPercentage, 6) || '');
    setDiscountValue(valueMask(auxDiscountValue) || '');
    setUnitaryDiscountValue(
      valueMask(auxDiscountValueUnitary, 6) || '0,000000'
    );
    setNetValue(valueMask(auxNetValue) || '0,00');
  }

  async function handleShowPrice(newQuantity) {
    const auxQuantity = valueUnmask(newQuantity || quantity);

    if (
      !product.price_list ||
      auxQuantity < product.price_list.min_quantity ||
      auxQuantity > product.price_list.max_quantity
    )
      await productPrice({
        product_uuid: product.uuid,
        customer_uuid: order.customer && order.customer.uuid,
        quantity: auxQuantity,
      });
  }

  function auxHandleChangeQuantity(operator) {
    const event = { target: { value: valueUnmask(quantity) } };

    if (operator) event.target.value = valueMask(event.target.value + 1, 6);
    else event.target.value = valueMask(event.target.value - 1, 6);

    handleChangeQuantity(event);
    handleShowPrice(event.target.value);
  }

  function handleClose() {
    productClearState({});
    orderItemClearState({});

    setShowDialog(false);
    setCurrentImage(noImage);
  }

  async function handleSubmit() {
    const ipi_value = valueMask(
      (valueUnmask(product.ipi_percentage) / 100) *
        valueUnmask(product_price) *
        valueUnmask(quantity)
    );

    const total_sale_value = valueMask(
      valueUnmask(net_value) + valueUnmask(ipi_value)
    );

    const commission_percentage = valueMask(user.commission_percentage);

    const commission_value = valueMask(
      valueUnmask(net_value) * (valueUnmask(commission_percentage) / 100)
    );

    const {
      errorMessages: orderItemErrorMessages,
      ...orderItemParsedData
    } = await orderItemValidate({
      order_uuid: order.uuid,
      product_uuid: product.uuid,
      price_list_uuid: product.price_list ? product.price_list.uuid : null,
      total_sale_value: valueMask(valueUnmask(net_value) + ipi_value),
      quantity,
      unitary_gross_value: product_price,
      unitary_net_value: valueMask(
        valueUnmask(product_price) - valueUnmask(unitary_discount_value),
        6
      ),
      unitary_discount_value,
      unitary_gross_weight: product.gross_weight,
      unitary_net_weight: product.net_weight,
      total_net_value: net_value,
      total_gross_value: gross_value,
      total_discount_value,
      total_gross_weight: gross_weight,
      total_net_weight: net_weight,
      discount_percentage,
      ipi_value,
      ipi_percentage: product.ipi_percentage,
      commission_percentage,
      commission_value,
      deadline: product.deadline,
    });

    let response = null;

    if (!net_value || net_value === '0,00') {
      toast.warn('É necessário algum valor para realizar a venda');
    } else if (!orderItemErrorMessages)
      if (!order.uuid) {
        const {
          errorMessages: orderErrorMessages,
          ...orderParsedData
        } = await orderValidate({
          customer_uuid: order.customer.uuid,
          payment_method_uuid: order.payment_method.uuid,
          total_sale_value,
          ipi_value,
          gross_value,
          net_value,
          discount_value: total_discount_value,
          discount_percentage,
          gross_weight,
          net_weight,
          observation: '',
          deadline: product.deadline,
          status_code: 1,
        });

        if (!orderErrorMessages) {
          const { company_key, branch_key } = logged_branch;

          const parsedOrder = {
            ...orderParsedData,
            req_id: '1',
            customer: order.customer,
            payment_method: order.payment_method,
          };

          parsedOrder.company_key = company_key;
          parsedOrder.branch_key = branch_key;

          const parsedOrderItem = {
            ...orderItemParsedData,
            req_id: '1',
            product,
            price_list: product.price_list,
            company_key,
            branch_key,
          };

          response = await orderStore({
            order: parsedOrder,
            orderItem: parsedOrderItem,
          });
        }
      } else if (orderItem.uuid) {
        const parsedOrderItem = {
          ...orderItem,
          ...orderItemParsedData,
          req_id: '1',
          product,
          price_list: product.price_list,
        };

        response = await orderItemUpdate({
          orderItem: parsedOrderItem,
        });
      } else {
        const { company_key, branch_key } = logged_branch;

        const parsedOrderItem = {
          ...orderItemParsedData,
          req_id: '1',
          product,
          price_list: product.price_list,
          company_key,
          branch_key,
        };

        response = await orderItemStore({ orderItem: parsedOrderItem });
      }

    if (response && response.order_uuid) {
      if (setPageIndex) setPageIndex({ newPageIndex: 2 });

      handleClose();

      await orderShow({ orderUuid: response.order_uuid });
    }
  }

  useEffect(() => {
    if (orderItem.uuid) {
      setGrossValue(orderItem.total_gross_value);
      setNetValue(orderItem.total_net_value);
      setGrossWeight(orderItem.total_gross_weight);
      setNetWeight(orderItem.total_net_weight);
      setQuantity(orderItem.quantity);
      setDiscountPercentage(orderItem.discount_percentage);
      setDiscountValue(orderItem.total_discount_value);
      setUnitaryDiscountValue(orderItem.unitary_discount_value);
    } else {
      setQuantity('1,000000');
      setUnitaryDiscountValue('0,000000');
      setGrossValue('0,00');
      setNetValue('0,00');
      setGrossWeight('0,00');
      setNetWeight('0,00');
      setDiscountValue('');
      setDiscountPercentage('');

      if (header_discount) {
        const event = {
          target: { value: header_discount },
        };

        handleChangeDiscountPercentage(event);
      } else if (
        order.payment_method &&
        order.payment_method.start_discount_percentage
      ) {
        const event = {
          target: {
            value: order.payment_method.start_discount_percentage,
          },
        };

        handleChangeDiscountPercentage(event);
      }
    }
  }, [orderItem, order, header_discount]); // eslint-disable-line

  useEffect(() => {
    if (product.uuid)
      setCurrentImage(
        product.product_images && product.product_images.length
          ? product.product_images[0].image
          : noImage
      );
  }, [product]);

  useEffect(() => {
    if (
      Object.keys(orderItemErrors).length &&
      orderItemErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(orderItemErrors.validations);
  }, [orderItemErrors]);

  return (
    <SingleProductDialog open={showDialog} onClose={handleClose}>
      <SingleProduct>
        <SingleProductNav>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </SingleProductNav>
        <SingleProductContent>
          <ImageTableContent>
            <ImageSelector>
              <ImageCarousel>
                {product.product_images && product.product_images.length ? (
                  product.product_images.map(productImage => (
                    <ImageButton
                      key={productImage.uuid}
                      onClick={() => setCurrentImage(productImage.image)}
                    >
                      <img
                        src={productImage.image}
                        alt={productImage.description || product.name}
                      />
                    </ImageButton>
                  ))
                ) : (
                  <img src={noImage} alt={product.name} />
                )}
              </ImageCarousel>
              <FeaturedImage>
                <Magnifier
                  zoomFactor={1.3}
                  mgWidth={200}
                  mgHeight={200}
                  src={currentImage}
                  alt={product.name}
                />
              </FeaturedImage>
            </ImageSelector>
            <TableContent>
              <h1>Características do Produto</h1>
              <Table>
                <TableHead>
                  <TableRow change>
                    <th>Descrição:</th>
                    <td>{product.description || '###'}</td>
                  </TableRow>
                  <TableRow>
                    <th>Status:</th>
                    <td>{product.status_code ? 'Ativo' : 'Inativo'}</td>
                  </TableRow>
                  <TableRow change>
                    <th>Saldo em estoque:</th>
                    <td>{product.stock_balance || '###'}</td>
                  </TableRow>
                  <TableRow>
                    <th>Prazo de entrega:</th>
                    <td>{product.deadline || '###'}</td>
                  </TableRow>
                  <TableRow change>
                    <th>Fabricante:</th>
                    <td>{product.manufacturer || '###'}</td>
                  </TableRow>
                  <TableRow>
                    <th>Peso bruto:</th>
                    <td>{product.gross_weight || '###'}</td>
                  </TableRow>
                  <TableRow change>
                    <th>Peso líquido:</th>
                    <td>{product.net_weight || '###'}</td>
                  </TableRow>
                  <TableRow>
                    <th>IPI:</th>
                    <td>% {product.ipi_percentage || '###'}</td>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContent>
          </ImageTableContent>
          <SingleProductPriceInfo>
            <h1>{product.name}</h1>
            <p className="product-ref">REF: {product.reference_code}</p>
            <div className="product-info">
              <span>
                <p>Desconto Unit. R$:</p>
                <p>{unitary_discount_value}</p>
              </span>
              <span>
                <p>Peso Bruto:</p>
                <p>{gross_weight}</p>
              </span>
              <span>
                <p>Peso Líquido:</p>
                <p>{net_weight}</p>
              </span>
              <span>
                <p>Valor Bruto:</p>
                <p>{gross_value}</p>
              </span>
            </div>
            <div className="product-price">
              <h6>Valor Total:</h6>
              <p className="price">
                R$: <span>{net_value}</span>
              </p>
            </div>
            <CartPrice>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="desc-price">
                  <ValueInput
                    name="discount_percentage"
                    label="Desconto %"
                    disabled={!canEdit}
                    value={discount_percentage}
                    onChange={handleChangeDiscountPercentage}
                    inputProps={{ decimalScale: 6 }}
                  />
                  <ValueInput
                    name="total_discount_value"
                    label="Desconto R$"
                    disabled={!canEdit}
                    value={total_discount_value}
                    onChange={handleChangeDiscountValue}
                    inputProps={{ decimalScale: 6 }}
                  />
                </div>
                <div className="cart-update">
                  <IconButton
                    disabled={!canEdit}
                    onClick={() => auxHandleChangeQuantity(false)}
                  >
                    <Remove />
                  </IconButton>
                  <ValueInput
                    name="quantity"
                    label="Quantidade"
                    disabled={!canEdit}
                    value={quantity}
                    onChange={handleChangeQuantity}
                    onBlur={() => handleShowPrice()}
                    inputProps={{ decimalScale: 6 }}
                    required
                  />
                  <IconButton
                    disabled={!canEdit}
                    onClick={() => auxHandleChangeQuantity(true)}
                  >
                    <Add />
                  </IconButton>
                </div>
                <CartButton
                  disabled={
                    !canEdit ||
                    productLoading ||
                    orderLoading ||
                    orderItemLoading
                  }
                  onClick={handleSubmit}
                >
                  Adicionar ao carrinho
                </CartButton>
              </Form>
            </CartPrice>
          </SingleProductPriceInfo>
        </SingleProductContent>
      </SingleProduct>
    </SingleProductDialog>
  );
}

export default OrderItemDialog;

OrderItemDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

OrderItemDialog.defaultProps = {
  canEdit: true,
};
