import React, { useRef } from 'react';

import { useAuth } from '~/providers/auth';

import TextInput from '~/components/TextInput';

import { Button, Loading, IconEmail, IconLockOpen } from './styles';

function SignContent() {
  const { authLoading, authErrors, signIn } = useAuth();

  const emailRef = useRef({ value: '' });
  const passwordRef = useRef({ value: '' });

  async function handleSubmit(event) {
    event.preventDefault();

    await signIn({
      email: emailRef.current && emailRef.current.value,
      password: passwordRef.current && passwordRef.current.value,
    });
  }

  return (
    <form className="content" onSubmit={handleSubmit}>
      <h2>Realizar Login</h2>
      <TextInput
        label="E-mail"
        inputRef={emailRef}
        error={authErrors.validations && authErrors.validations.email}
        icon={<IconEmail />}
      />
      <TextInput
        label="Senha"
        inputRef={passwordRef}
        error={authErrors.validations && authErrors.validations.password}
        password
        icon={<IconLockOpen />}
      />
      <Button disabled={authLoading} variant="contained" type="submit">
        Acessar
        {authLoading && <Loading />}
      </Button>
    </form>
  );
}

export default SignContent;
