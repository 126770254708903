import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useProduct } from '~/providers/product';
import { useAuth } from '~/providers/auth';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'image', label: 'Imagem', image: true, disablePadding: true },
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'gross_weight', label: 'Peso Bruto' },
  { id: 'stock_balance', label: 'Saldo em Estoque' },
];

const otherCells = [
  { id: 'unit_of_measure_name', label: 'Unidade de Medida' },
  { id: 'product_group_name', label: 'Grupo de Produto' },
  { id: 'ipi_percentage', label: '% IPI' },
];

function Products() {
  const history = useHistory();

  const { products, productListLoading, index, show, destroy } = useProduct();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadProducts = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.products.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/products/form', { newProduct: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async product => {
          history.push('/products/form', { showProduct: true });

          await show({ productUuid: product.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.products.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async product => {
          if (!user.is_admin && !user.is_manager && product.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/products/form', { updateProduct: true });

            await show({ productUuid: product.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.products.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: product => {
          if (!user.is_admin && !user.is_manager && product.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ product });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && products.length <= 1) loadProducts();

    setLoaded(true);
  }, [products, loadProducts, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Produtos"
        newItem={newItem}
        headCells={headCells}
        otherCells={otherCells}
        data={products}
        loadData={loadProducts}
        loading={productListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Products;
