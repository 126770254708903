import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IcBtn from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SwipViews from 'react-swipeable-views';
import Email from '@material-ui/icons/Email';
import LockOpen from '@material-ui/icons/LockOpen';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(240, 240, 240, 0.9);
  align-items: center;
  justify-content: space-between;
`;

export const WelcomeContainer = styled.div`
  width: 60%;
  display: flex;
  backdrop-filter: blur(30px);
  background: ${props => props.theme.colors.primary};
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: bottom;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 2em 0em;
  color: ${props => props.theme.colors.backFirst};
`;

export const Facile = styled.div`
  margin-bottom: auto;
  font-size: 1rem;
`;

export const Copyright = styled.div`
  margin-bottom: ${props => (props.login ? '' : 'auto')};
  margin-top: ${props => (props.login ? 'auto' : '')};

  font-size: 1rem;
`;

export const FacinSales = styled.div`
  margin-bottom: auto;

  h1 {
    font-size: 3.5rem;
  }

  p {
    font-size: 1.4rem;
  }
`;

export const FormContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  place-items: center;
  form {
    display: flex;
    margin: 0 auto;
    width: 400px;
    flex-direction: column;
    h2 {
      font-weight: 400;
      color: ${props => props.theme.colors.primary};
      text-align: center;
      font-size: 2em;
    }
  }
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const Loading = styled(CircularProgress).attrs({
  size: 24,
})`
  position: absolute;
`;

export const SwipeableViews = styled(SwipViews).attrs({
  disabled: true,
})``;

export const IconButton = styled(IcBtn)`
  &.MuiIconButton-root {
    top: 20px;
    position: absolute;
    z-index: 1;
  }
`;

export const IconArrowBack = styled(ArrowBack)``;

export const IconEmail = styled(Email)``;

export const IconLockOpen = styled(LockOpen)``;
