import {
  cpfMask,
  cnpjMask,
  numberUnmask,
  numberMask,
  valueUnmask,
  valueMask,
} from './mask';

import { parseToView as notSaleParseToView } from './notSale';

export function parseToView(customers = []) {
  return customers.map(customer => {
    let status_buy_code = 1;
    let status_buy_text = 'Disponível';
    if (!customer.uuid) {
      status_buy_code = 3;
      status_buy_text = 'Em análise';
    } else if (!customer.credit_limit) {
      status_buy_code = 2;
      status_buy_text = 'Sem Limite';
    } else if (new Date() > new Date(customer.credit_maturity_at)) {
      status_buy_code = 2;
      status_buy_text = 'Limite Vencido';
    }

    let status_code_view = customer.status_code;
    let status_text_view = customer.status_code === 1 ? 'Ativo' : 'Inativo';
    if (!customer.uuid) {
      status_code_view = 3;
      status_text_view = 'Prospectado';
    }

    return {
      ...customer,
      uf_city: `${customer.state.uf}/${customer.city.name}`,
      cpf_cnpj:
        customer.type === 'F'
          ? cpfMask(customer.cpf_cnpj)
          : cnpjMask(customer.cpf_cnpj),
      not_sales: notSaleParseToView(customer.not_sales),
      credit_limit: valueMask(customer.credit_limit),
      status_buy_code,
      status_buy_text,
      status_code_view,
      status_text_view,
    };
  });
}

export function parseDataToApi(customer = {}) {
  return {
    legacy_code: customer.legacy_code || '',
    type: customer.type || '',
    cpf_cnpj: numberUnmask(customer.cpf_cnpj),
    state_inscription: customer.state_inscription || null,
    first_name: customer.first_name || '',
    last_name: customer.last_name || null,
    email: customer.email || '',
    ddd1: customer.ddd1 || '',
    telephone1: numberUnmask(customer.telephone1),
    ddd2: customer.ddd2 || null,
    telephone2: numberUnmask(customer.telephone2) || null,
    zip_code: numberUnmask(customer.zip_code),
    state_id: customer.state_id || '',
    city_id: customer.city_id || '',
    neighborhood: customer.neighborhood || '',
    address: customer.address || '',
    number: customer.number || null,
    complement: customer.complement || null,
    credit_limit: valueUnmask(customer.credit_limit) || null,
    credit_maturity_at: customer.credit_maturity_at || null,
    customer_group_uuid: customer.customer_group_uuid || null,
    seller1_uuid: customer.seller1_uuid || null,
    seller2_uuid: customer.seller2_uuid || null,
    status_code: numberMask(customer.status_code),
    seller1: {},
    seller2: {},
    state: {},
    states: [],
    city: {},
    cities: [],
    customer_groups: [],
    users: [],
    not_sales: [],
    status_buy_code: customer.status_buy_code || null,
    status_buy_text: customer.status_buy_text || null,
    status_code_view: customer.status_code_view || null,
    status_text_view: customer.status_text_view || null,
  };
}

export function parseFormData(customer = {}) {
  return {
    address: customer.address,
    complement: customer.complement,
    cpf_cnpj: customer.cpf_cnpj,
    credit_limit: customer.credit_limit,
    ddd1: customer.ddd1,
    ddd2: customer.ddd2,
    email: customer.email,
    first_name: customer.first_name,
    last_name: customer.last_name,
    legacy_code: customer.legacy_code,
    neighborhood: customer.neighborhood,
    number: customer.number,
    telephone1: customer.telephone1,
    telephone2: customer.telephone2,
    zip_code: customer.zip_code,
    state_inscription: customer.state_inscription,
  };
}
