import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function NumberFormatCustom({ inputRef, ...rest }) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      decimalScale={2}
      allowNegative={false}
      isAllowed={values => {
        const { floatValue } = values;
        return floatValue <= 999999999.99 || floatValue === undefined;
      }}
      {...rest}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ValueInput({ name, label, required, ...rest }) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({ value: '' });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      error={!!error}
      size="small"
      variant="outlined"
      style={{ maxWidth: '200px' }}
    >
      <InputLabel>
        <p>
          {label} {required && <b style={{ color: '#d50000' }}>*</b>}
        </p>
      </InputLabel>
      <OutlinedInput
        inputRef={inputRef}
        onChange={error && clearError}
        inputComponent={NumberFormatCustom}
        inputProps={{ ...rest }}
        label={`${label}${required ? ' *' : ''}`}
        error={!!error}
        {...rest}
      />
      <FormHelperText>{error || ' '}</FormHelperText>
    </FormControl>
  );
}

export default ValueInput;

ValueInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

ValueInput.defaultProps = {
  required: false,
};
