import styled from 'styled-components';
import ListItemTextStyled from '@material-ui/core/ListItemText';

export const ListItemText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    component: 'div',
  },
  secondaryTypographyProps: {
    component: 'div',
  },
})``;
