import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  parameters: [],
  parameter: {},
  parameterErrors: {},
  parameterLoading: false,
  parameterListLoading: false,
};

const ParameterContext = createContext(INITIAL_STATE);

export function ParameterProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setParameterData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setParameterData({ parameterListLoading: true });

      const parameterData = await actions.index({
        search,
        order_by,
        order,
      });

      if (parameterData.parameterErrors)
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setParameterData({ ...parameterData, parameterListLoading: false });
    },
    [setParameterData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ parameterUuid = '' }) => {
      setParameterData({ parameterLoading: true });

      const parameterData = await actions.show({ parameterUuid });

      if (parameterData.parameterErrors) {
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => show({ parameterUuid }),
          },
        });

        history.goBack();
      }

      setParameterData({ ...parameterData, parameterLoading: false });
    },
    [setParameterData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ parameter = {} }) => {
      setParameterData({ parameterLoading: true });

      const parameterData = await actions.store({ parameter });

      if (parameterData.parameterErrors) {
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => store({ parameter }),
          },
        });

        setParameterData({ ...parameterData, parameterLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          parameters: [parameterData, ...oldData.parameters],
          parameterLoading: false,
        }));

        history.goBack();

        toast.success('Parâmetro cadastrado com sucesso!');
      }
    },
    [setParameterData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ parameter = {} }) => {
      setParameterData({ parameterLoading: true });

      const parameterData = await actions.update({ parameter });

      if (parameterData.parameterErrors) {
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => update({ parameter }),
          },
        });

        setParameterData({ ...parameterData, parameterLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          parameters: [
            parameterData,
            ...oldData.parameters.filter(
              auxparameter => auxparameter.uuid !== parameter.uuid
            ),
          ],
          parameterLoading: false,
        }));

        history.goBack();

        toast.success('Parâmetro atualizado com sucesso!');
      }
    },
    [setParameterData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ parameter = {} }) => {
      setParameterData({ parameterListLoading: true });

      const parameterData = await actions.destroy({ parameter });

      if (parameterData.parameterErrors) {
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => destroy({ parameter }),
          },
        });

        setParameterData({ ...parameterData, parameterListLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          parameters: [
            ...oldData.parameters.filter(
              auxparameter => auxparameter.uuid !== parameter.uuid
            ),
          ],
          parameterListLoading: false,
        }));

        toast.success('Parâmetro removido com sucesso!');
      }
    },
    [setParameterData, setErrorHandlerData]
  );

  const newParameter = useCallback(async () => {
    setParameterData({ parameterLoading: true });

    const parameterData = await actions.newParameter();

    if (parameterData.parameterErrors)
      setErrorHandlerData({
        error: {
          ...parameterData.parameterErrors,
          resolveFunction: () => newParameter(),
        },
      });

    setParameterData({ parameterLoading: false });

    return { parameters: [], ...parameterData };
  }, [setParameterData, setErrorHandlerData]);

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      setParameterData({ parameterLoading: true });

      const parameterData = await actions.indexSelector({ search });

      if (parameterData.parameterErrors) {
        setErrorHandlerData({
          error: {
            ...parameterData.parameterErrors,
            resolveFunction: () => indexSelector({ search }),
          },
        });

        setParameterData({ parameterLoading: false });

        return [];
      }

      setParameterData({ parameterLoading: false });

      return parameterData.parameters;
    },
    [setParameterData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        parameter: {},
        parameterErrors: {},
        parameterLoading: false,
        parameterListLoading: false,
      };
    });
  }, []);

  return (
    <ParameterContext.Provider
      value={{
        ...data,
        setParameterData,
        index,
        show,
        store,
        update,
        destroy,
        newParameter,
        indexSelector,
        clearState,
      }}
    >
      {children}
    </ParameterContext.Provider>
  );
}

export function useParameter() {
  const context = useContext(ParameterContext);

  if (!context)
    throw new Error('useParameter must be used within an ParameterProvider');

  return context;
}

ParameterProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
