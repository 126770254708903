import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/customer';
import validateCpfCnpj from '~/utils/validateCpfCnpj';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      type: Yup.string().required('Este campo é obrigatório'),
      cpf_cnpj: Yup.string()
        .required('Este campo é obrigatório')
        .when('type', {
          is: 'F',
          then: Yup.string()
            .length(11, 'Deve conter 11 caracteres')
            .test('validateCpfCnpj', 'CPF inválido', validateCpfCnpj),
          otherwise: Yup.string()
            .length(14, 'Deve conter 14 caracteres')
            .test('validateCpfCnpj', 'CNPJ inválido', validateCpfCnpj),
        }),
      state_inscription: Yup.string()
        .max(40, 'Máximo de 40 caracteres')
        .nullable(),
      first_name: Yup.string().required('Este campo é obrigatório'),
      last_name: Yup.string().nullable(),
      email: Yup.string().email('Formato de e-mail inválido'),
      ddd1: Yup.string()
        .required('Este campo é obrigatório')
        .max(2, 'Máximo de 2 caracteres'),
      telephone1: Yup.string().required('Este campo é obrigatório'),
      ddd2: Yup.string().max(2, 'Máximo de 2 caracteres').nullable(),
      telephone2: Yup.string().nullable(),
      zip_code: Yup.string().required('Este campo é obrigatório'),
      state_id: Yup.string().required('Este campo é obrigatório'),
      city_id: Yup.string().required('Este campo é obrigatório'),
      neighborhood: Yup.string().required('Este campo é obrigatório'),
      address: Yup.string().required('Este campo é obrigatório'),
      number: Yup.string().nullable(),
      complement: Yup.string().nullable(),
      credit_limit: Yup.string().nullable(),
      credit_maturity_at: Yup.date().nullable(),
      customer_group_uuid: Yup.string().nullable(),
      seller1_uuid: Yup.string().nullable(),
      seller2_uuid: Yup.string().nullable(),
      status_code: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
