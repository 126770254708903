import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useCustomerGroup } from '~/providers/customerGroup';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
];

function CustomerGroups() {
  const history = useHistory();

  const {
    customerGroups,
    customerGroupListLoading,
    index,
    show,
    destroy,
  } = useCustomerGroup();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadCustomerGroups = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.customer-groups.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/customer-groups/form', { newCustomerGroup: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async customerGroup => {
          history.push('/customer-groups/form', { showCustomerGroup: true });

          await show({ customerGroupUuid: customerGroup.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.customer-groups.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async customerGroup => {
          if (!user.is_admin && !user.is_manager && customerGroup.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/customer-groups/form', {
              updateCustomerGroup: true,
            });

            await show({ customerGroupUuid: customerGroup.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.customer-groups.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: customerGroup => {
          if (!user.is_admin && !user.is_manager && customerGroup.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ customerGroup });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && customerGroups.length <= 1) loadCustomerGroups();

    setLoaded(true);
  }, [customerGroups, loadCustomerGroups, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Grupo de Clientes"
        newItem={newItem}
        headCells={headCells}
        data={customerGroups}
        loadData={loadCustomerGroups}
        loading={customerGroupListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default CustomerGroups;
