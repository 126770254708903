import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  priceLists: [],
  priceList: {},
  priceListErrors: {},
  priceListLoading: false,
  priceListListLoading: false,
};

const PriceListContext = createContext(INITIAL_STATE);

export function PriceListProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(INITIAL_STATE);

  const setPriceListData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ product_uuid = '', search = '', order_by = '', order = '' }) => {
      setPriceListData({ priceListListLoading: true });

      const priceListData = await actions.index({
        product_uuid,
        search,
        order_by,
        order,
      });

      if (priceListData.priceListErrors)
        setErrorHandlerData({
          error: {
            ...priceListData.priceListErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setPriceListData({
        ...priceListData,
        priceListListLoading: false,
      });
    },
    [setPriceListData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ priceListUuid = '' }) => {
      setPriceListData({ priceListLoading: true });

      const priceListData = await actions.show({ priceListUuid });

      if (priceListData.priceListErrors) {
        setErrorHandlerData({
          error: {
            ...priceListData.priceListErrors,
            resolveFunction: () => show({ priceListUuid }),
          },
        });
      }

      setPriceListData({
        ...priceListData,
        priceListLoading: false,
      });
    },
    [setPriceListData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ priceList = {} }) => {
      setPriceListData({ priceListLoading: true });

      const priceListData = await actions.store({ priceList });

      if (priceListData.priceListErrors) {
        setErrorHandlerData({
          error: {
            ...priceListData.priceListErrors,
            resolveFunction: () => store({ priceList }),
          },
        });

        setPriceListData({
          ...priceListData,
          priceListLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        priceLists: [priceListData, ...oldData.priceLists],
        priceListLoading: false,
      }));

      toast.success('Tabela de Preço criada com sucesso!');

      return priceListData;
    },
    [setPriceListData, setErrorHandlerData]
  );

  const update = useCallback(
    async ({ priceList = {} }) => {
      setPriceListData({ priceListLoading: true });

      const priceListData = await actions.update({ priceList });

      if (priceListData.priceListErrors) {
        setErrorHandlerData({
          error: {
            ...priceListData.priceListErrors,
            resolveFunction: () => update({ priceList }),
          },
        });

        setPriceListData({
          ...priceListData,
          priceListLoading: false,
        });

        return false;
      }

      setData(oldData => ({
        ...oldData,
        priceLists: [
          priceListData,
          ...oldData.priceLists.filter(
            auxPriceList => auxPriceList.uuid !== priceList.uuid
          ),
        ],
        priceListLoading: false,
      }));

      toast.success('Tabela de Preço atualizada com sucesso!');

      return priceListData;
    },
    [setPriceListData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ priceList = {} }) => {
      setPriceListData({ priceListListLoading: true });

      const priceListData = await actions.destroy({ priceList });

      if (priceListData.priceListErrors) {
        setErrorHandlerData({
          error: {
            ...priceListData.priceListErrors,
            resolveFunction: () => destroy({ priceList }),
          },
        });

        setPriceListData({
          ...priceListData,
          priceListListLoading: false,
        });

        return true;
      }

      setData(oldData => ({
        ...oldData,
        priceLists: [
          ...oldData.priceLists.filter(
            auxPriceList => auxPriceList.uuid !== priceList.uuid
          ),
        ],
        priceListListLoading: false,
      }));

      toast.success('Tabela de Preço removida com sucesso!');

      return true;
    },
    [setPriceListData, setErrorHandlerData]
  );

  const newPriceList = useCallback(async () => {
    setPriceListData({ priceListLoading: true });

    const priceListData = await actions.newPriceList();

    if (priceListData.priceListErrors)
      setErrorHandlerData({
        error: {
          ...priceListData.priceListErrors,
          resolveFunction: () => newPriceList(),
        },
      });

    setPriceListData({ priceListLoading: false });

    return { customer_groups: [], customers: [], states: [], ...priceListData };
  }, [setPriceListData, setErrorHandlerData]);

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        priceList: {},
        priceListErrors: {},
        priceListLoading: false,
        priceListListLoading: false,
      };
    });
  }, []);

  return (
    <PriceListContext.Provider
      value={{
        ...data,
        setPriceListData,
        index,
        show,
        store,
        update,
        destroy,
        newPriceList,
        clearState,
      }}
    >
      {children}
    </PriceListContext.Provider>
  );
}

export function usePriceList() {
  const context = useContext(PriceListContext);

  if (!context)
    throw new Error('usePriceList must be used within an PriceListProvider');

  return context;
}

PriceListProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
