import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { usePaymentMethod } from '~/providers/paymentMethod';
import { useAuth } from '~/providers/auth';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'name', label: 'Nome' },
  { id: 'quantity_installment', label: 'QTD Parcelas' },
  { id: 'min_per_installment', label: 'Mínimo por Parcela' },
];

function PaymentMethods() {
  const history = useHistory();

  const {
    paymentMethods,
    paymentMethodListLoading,
    index,
    show,
    destroy,
  } = usePaymentMethod();
  const { user, current_accesses } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadPaymentMethods = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.payment-methods.post']
        ? {
            label: 'ADICIONAR',
            handleFunction: () =>
              history.push('/payment-methods/form', { newPaymentMethod: true }),
          }
        : null,
    [current_accesses, history, user]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async paymentMethod => {
          history.push('/payment-methods/form', { showPaymentMethod: true });

          await show({ paymentMethodUuid: paymentMethod.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.payment-methods.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async paymentMethod => {
          if (!user.is_admin && !user.is_manager && paymentMethod.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            history.push('/payment-methods/form', {
              updatePaymentMethod: true,
            });

            await show({ paymentMethodUuid: paymentMethod.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.payment-methods.delete']
    )
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: paymentMethod => {
          if (!user.is_admin && !user.is_manager && paymentMethod.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ paymentMethod });
        },
      });

    return auxActions;
  }, [user, current_accesses, history, show, destroy]);

  useEffect(() => {
    if (!loaded && paymentMethods.length <= 1) loadPaymentMethods();

    setLoaded(true);
  }, [paymentMethods, loadPaymentMethods, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Formas de Pagamento"
        newItem={newItem}
        headCells={headCells}
        data={paymentMethods}
        loadData={loadPaymentMethods}
        loading={paymentMethodListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default PaymentMethods;
