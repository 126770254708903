import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
`;

export const DataContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 1em;
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }
  form {
    display: flex;
    fieldset {
      align-items: start;
      label {
        margin: 0;
        flex-direction: row;
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 1em;
  gap: 20px;
`;
export const FormContent = styled.ul`
  width: 100%;
`;

export const FormSingleContent = styled(Grid)`
  text-align: left;
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.table ? props.theme.colors.backFirst : props.theme.colors.backSecond};
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  padding: 1em 0.3em 0 0.3em;
  display: flex;
  align-items: start;
  gap: 0 50px;
  justify-content: start;
  flex: 1;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0;
  gap: 0 20px;
`;
