import * as Yup from 'yup';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      name: Yup.string().required('Este campo é obrigatório'),
    });

    await schema.validate(data, { abortEarly: false });

    return data;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
