import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/priceList';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      legacy_code: Yup.string().max(10, 'Máximo de 10 caracteres'),
      product_uuid: Yup.string().required('Este campo é obrigatório'),
      customer_group_uuid: Yup.string().nullable(),
      customer_uuid: Yup.string().nullable(),
      state_id: Yup.string().nullable(),
      city_id: Yup.string().nullable(),
      description: Yup.string().required('Este campo é obrigatório'),
      price: Yup.string().required('Este campo é obrigatório'),
      max_discount_percentage: Yup.string().nullable(),
      min_quantity: Yup.string().nullable(),
      max_quantity: Yup.string().nullable(),
      start_at: Yup.string().required('Este campo é obrigatório'),
      end_at: Yup.string().required('Este campo é obrigatório'),
      status_code: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
