import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/customer';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const parsedOrderBy = order_by === 'uf_city' ? 'city_id' : order_by;

    const { data } = await apiFacin
      .get('/customers', { params: { search, order_by: parsedOrderBy, order } })
      .then(res => res.data);

    return { customers: parseToView(data) };
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao listar Clientes',
      local: 'CustomerActionsIndex',
    });

    return { customerErrors };
  }
}

export async function show({ customerUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/customers/${customerUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.customer;

    return { customer: parseToView([{ ...data.customer, ...auxData }])[0] };
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao visualizar Cliente',
      local: 'CustomerActionsShow',
    });

    return { customerErrors };
  }
}

export async function store({ customer = {} }) {
  try {
    const { data } = await apiFacin
      .post('/customers', {
        data: [customer],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...customer, ...data[0].customer }])[0];
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Cliente',
      local: 'CustomerActionsStore',
    });

    return { customerErrors };
  }
}

export async function update({ customer = {} }) {
  try {
    const { data } = await apiFacin
      .put('/customers', {
        data: [customer],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...customer, ...data[0].customer }])[0];
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao atualizar Cliente',
      local: 'CustomerActionsUpdate',
    });

    return { customerErrors };
  }
}

export async function destroy({ customer = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/customers`,
      method: 'delete',
      data: { data: [customer] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao remover Cliente',
      local: 'CustomerActionsDestroy',
    });

    return { customerErrors };
  }
}

export async function newCustomer() {
  try {
    const { data } = await apiFacin.get('/new-customer').then(res => res.data);

    return data;
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo Cliente',
      local: 'CustomerActionsNewCustomer',
    });

    return { customerErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: customers } = await apiFacin
      .get(`/customers`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { customers };
  } catch (error) {
    const customerErrors = errorHandler({
      error,
      title: 'Error ao listar Clientes',
      local: 'CustomerActionsIndexSelector',
    });

    return { customerErrors };
  }
}
