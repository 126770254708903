import apiFacin from '~/config/apiFacin';

import { sessionParse } from '~/utils/auth';
import { parseToView } from '~/utils/dashboard';
import errorHandler from '~/utils/errorHandler';

export async function signIn({
  email = '',
  password = '',
  branch_key = '',
  force = false,
}) {
  try {
    const { data } = await apiFacin.post(
      '/sessions',
      {
        email,
        password,
        branch_key,
      },
      { headers: { force } }
    );

    if (data.token) {
      apiFacin.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiFacin.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;
    }

    const sessionParsed = sessionParse(data.accesses, branch_key);

    if (branch_key) {
      delete sessionParsed.companies;
      delete sessionParsed.branches;
    }

    return { ...data, ...sessionParsed };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar ao entrar',
      local: 'AuthActionsSignIn',
    });

    return { authErrors };
  }
}

export async function changeBranch({ branch_key = '', force = false }) {
  try {
    const { data } = await apiFacin.post(
      '/change-branch',
      { branch_key },
      { headers: { force } }
    );

    apiFacin.defaults.headers.authorization = `Bearer ${data.token.token}`;
    apiFacin.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

    const sessionParsed = sessionParse(data.accesses, branch_key);
    delete sessionParsed.companies;
    delete sessionParsed.branches;

    return { ...data, ...sessionParsed };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar mudar de Empresa',
      local: 'AuthActionsChangeBranch',
    });

    return { authErrors };
  }
}

export async function refreshToken() {
  try {
    const { data } = await apiFacin.put('/sessions');

    apiFacin.defaults.headers.authorization = `Bearer ${data.token.token}`;
    apiFacin.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

    const sessionParsed = sessionParse(
      data.accesses,
      data.logged_branch.branch_key
    );
    delete sessionParsed.companies;
    delete sessionParsed.branches;

    return { ...data, ...sessionParsed };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar entrar novamente',
      local: 'AuthActionsRefreshToken',
    });

    return { authErrors };
  }
}

export async function dashboardInfo({
  search = '',
  order = '',
  order_by = '',
}) {
  try {
    const { data } = await apiFacin
      .get('/dashboard', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { ...data, sellers: parseToView(data.sellers) };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar pegar informações da Dashboard',
      local: 'AuthActionsDashboardInfo',
    });

    return { authErrors };
  }
}

export async function changePassword({ password }) {
  try {
    const data = await apiFacin
      .post('/change-password', { password, password_confirmation: password })
      .then(res => res.data);

    return data;
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar alterar a senha',
      local: 'AuthActionsChangePassword',
    });

    return { authErrors };
  }
}
