import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useProduct } from '~/providers/product';

import FormContent from './content';
import ProductImages from '~/pages/ProductImages';
import PriceLists from '~/pages/PriceLists';

import { Grid } from '~/components/Form';

import {
  Container,
  SwipeableViews,
  Tabs,
  Paper,
  TabText,
  NavigationContent,
  ContentContainer,
} from './styles';

function ProductForm({ formulary }) {
  const location = useLocation();
  const [Tabsvalue, setTabsValue] = useState(1);

  const { clearState } = useProduct();

  const [pageIndex, setPageIndex] = useState(1);
  const [canEdit, setCanEdit] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => () => clearState({}), [clearState]);

  useEffect(() => {
    if (
      !location.state ||
      location.state.newProduct ||
      location.state.updateProduct
    )
      setCanEdit(true);

    window.history.replaceState(null, '');
  }, [location]);

  return (
    <Container>
      {location.state &&
        (location.state.showProduct || location.state.updateProduct) && (
          <NavigationContent>
            <Grid container justify="center">
              <Paper
                formulary={formulary ? 'formulary' : ''}
                style={{ boxShadow: 'none' }}
              >
                <Tabs
                  style={{ background: '#061928' }}
                  value={Tabsvalue}
                  onChange={handleChangeTabs}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <TabText
                    formulary={formulary ? 'formulary' : ''}
                    onClick={() => setPageIndex(0)}
                    label="Imagens"
                  />
                  <TabText
                    formulary={formulary ? 'formulary' : ''}
                    onClick={() => setPageIndex(1)}
                    label="Formulário"
                  />
                  <TabText
                    formulary={formulary ? 'formulary' : ''}
                    onClick={() => setPageIndex(2)}
                    label="Tabelas de Preço"
                  />
                </Tabs>
              </Paper>
            </Grid>
          </NavigationContent>
        )}
      <ContentContainer>
        <SwipeableViews index={pageIndex}>
          {location.state &&
          (location.state.showProduct || location.state.updateProduct) ? (
            <ProductImages setPageIndex={setPageIndex} canEdit={canEdit} />
          ) : (
            <div />
          )}
          <FormContent canEdit={canEdit} />
          {location.state &&
          (location.state.showProduct || location.state.updateProduct) ? (
            <PriceLists setPageIndex={setPageIndex} canEdit={canEdit} />
          ) : (
            <div />
          )}
        </SwipeableViews>
      </ContentContainer>
    </Container>
  );
}

export default ProductForm;

ProductForm.propTypes = {
  formulary: PropTypes.bool,
};

ProductForm.defaultProps = {
  formulary: true,
};
