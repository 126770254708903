import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useProduct } from '~/providers/product';
import { usePriceList } from '~/providers/priceList';
import { useCustomerGroup } from '~/providers/customerGroup';
import { useCustomer } from '~/providers/customer';

import indexCities from '~/services/indexCities';

import { validate } from '~/validators/priceList';

import { parseFormData } from '~/utils/priceList';
import { setFormData } from '~/utils/form';

import {
  Form,
  Grid,
  Loading,
  TextInput,
  ValueInput,
  DatePicker,
  Selector,
  RadioGroup,
} from '../Form';
import { Dialog, DialogActions, DialogTitle, Button } from './styles';

const priceListStatus = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

function PriceListDialog({ showDialog, setShowDialog }, ref) {
  const { logged_branch } = useAuth();
  const { product } = useProduct();
  const { indexSelector: customerGroupIndexSelector } = useCustomerGroup();
  const { indexSelector: customerIndexSelector } = useCustomer();
  const {
    priceList,
    priceListLoading,
    store,
    update,
    priceListErrors,
    clearState,
    newPriceList,
  } = usePriceList();

  const [customer_groups, setCustomerGroups] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [loadingCustomerGroups, setLoadingCustomerGroups] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  async function handleLoadData() {
    const {
      customer_groups: auxCustomerGroups,
      customers: auxCustomers,
      states: auxStates,
    } = await newPriceList();

    setCustomerGroups(auxCustomerGroups);
    setCustomers(auxCustomers);
    setStates(auxStates);
  }

  useImperativeHandle(ref, () => ({ handleLoadData, setCanEdit }));

  const formRef = useRef(null);

  function handleClose() {
    clearState({});
    setShowDialog(false);
    setCanEdit(false);
  }

  const loadCustomerGroups = useCallback(
    async (search = '') => {
      setLoadingCustomerGroups(true);
      const auxCustomerGroups = await customerGroupIndexSelector({ search });
      setLoadingCustomerGroups(false);

      setCustomerGroups(auxCustomerGroups);
    },
    [customerGroupIndexSelector]
  );

  const loadCustomers = useCallback(
    async (search = '') => {
      setLoadingCustomers(true);
      const auxCustomers = await customerIndexSelector({ search });
      setLoadingCustomers(false);

      setCustomers(auxCustomers);
    },
    [customerIndexSelector]
  );

  async function handleChangeState(state) {
    if (state) {
      const { cities: auxCities } = await indexCities({ state_id: state.id });

      setCities(auxCities);

      const city_id =
        formRef.current && formRef.current.getFieldValue('city_id');

      if (city_id) {
        const findedCity = auxCities.find(city => city.id === city_id);

        if (formRef.current) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(findedCity);
        }
      }
    } else {
      setCities([]);

      if (formRef.current) {
        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(null);
      }
    }
  }

  async function handleSubmit() {
    if (formRef.current) {
      const data = formRef.current.getData();

      const { errorMessages, ...parsedData } = await validate({
        product_uuid: product.uuid,
        ...priceList,
        ...data,
      });

      if (errorMessages && formRef.current)
        formRef.current.setErrors(errorMessages);
      else {
        const findedCustomerGroup = customer_groups.find(
          customer_group => customer_group.uuid === data.customer_group_uuid
        );
        const findedCustomer = customers.find(
          customer => customer.uuid === data.customer_uuid
        );
        const findedState = states.find(state => state.id === data.state_id);
        const findedCity = cities.find(city => city.id === data.city_id);

        const parsedPriceList = {
          ...parsedData,
          req_id: '1',
          customer_group: findedCustomerGroup,
          customer: findedCustomer,
          state: findedState,
          city: findedCity,
        };

        let response = null;

        if (priceList.uuid)
          response = await update({
            priceList: parsedPriceList,
          });
        else {
          const { company_key, branch_key } = logged_branch;

          parsedPriceList.company_key = company_key;
          parsedPriceList.branch_key = branch_key;

          response = await store({ priceList: parsedPriceList });
        }

        if (response && response.uuid) handleClose();
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(priceListErrors).length &&
      priceListErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(priceListErrors.validations);
  }, [priceListErrors]);

  useEffect(() => {
    if (priceList.uuid) {
      const auxPriceList = { ...priceList };

      const {
        customer_groups: auxCustomerGroups,
        customers: auxCustomers,
        states: auxStates,
        cities: auxCities,
        status_code,
        start_at,
        end_at,
        state,
        city,
        customer_group,
        customer,
        ...priceListData
      } = auxPriceList;

      setCustomerGroups(auxCustomerGroups);
      setCustomers(auxCustomers);
      setStates(auxStates);
      setCities(auxCities);

      if (formRef.current) {
        const statusCodeInputRef = formRef.current.getFieldRef('status_code');
        statusCodeInputRef.handleChange({ target: { value: String(status_code) } }); // prettier-ignore

        const startAtInputRef = formRef.current.getFieldRef('start_at');
        startAtInputRef.handleChange(new Date(start_at));

        const endAtInputRef = formRef.current.getFieldRef('end_at');
        endAtInputRef.handleChange(new Date(end_at));

        if (customer_group) {
          const customerGroupInputRef = formRef.current.getFieldRef('customer_group_uuid'); // prettier-ignore
          customerGroupInputRef.handleChange(customer_group);
        }

        if (customer) {
          const customerInputRef = formRef.current.getFieldRef('customer_uuid');
          customerInputRef.handleChange(customer);
        }

        if (state) {
          const stateInputRef = formRef.current.getFieldRef('state_id');
          stateInputRef.handleChange(state);
        }

        if (city) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(city);
        }
      }

      const formData = parseFormData(priceListData);

      setFormData({ formRef, formData });
    }
  }, [priceList]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDialog}
      onClose={!priceListLoading ? handleClose : () => {}}
    >
      <DialogTitle>Tabela de Preço</DialogTitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <TextInput
              name="legacy_code"
              label="Código Legado"
              maxLength={10}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <TextInput
              name="description"
              label="Descrição"
              disabled={!canEdit}
              required
            />
          </Grid>
          <Grid item>
            <ValueInput
              name="price"
              label="Preço"
              disabled={!canEdit}
              prefix="R$ "
              required
            />
          </Grid>
          <Grid item>
            <ValueInput
              name="max_discount_percentage"
              label="Máximo desconto %"
              disabled={!canEdit}
              prefix="% "
            />
          </Grid>
          <Grid item>
            <ValueInput
              name="min_quantity"
              label="Quantidade Mínima"
              disabled={!canEdit}
              prefix="QTD "
            />
          </Grid>
          <Grid item>
            <ValueInput
              name="max_quantity"
              label="Quantidade Máxima"
              disabled={!canEdit}
              prefix="QTD "
            />
          </Grid>
          <Grid item>
            <DatePicker
              name="start_at"
              label="Início Vigência"
              disabled={!canEdit}
              required
            />
          </Grid>
          <Grid item>
            <DatePicker
              name="end_at"
              label="Fim Vigência"
              disabled={!canEdit}
              required
            />
          </Grid>
          <Grid item>
            <Selector
              name="customer_group_uuid"
              label="Grupo de Cliente"
              idColumn="uuid"
              nameColumn="name"
              options={customer_groups}
              loadData={loadCustomerGroups}
              loading={priceListLoading || loadingCustomerGroups}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <Selector
              name="customer_uuid"
              label="Cliente"
              idColumn="uuid"
              nameColumn="first_name"
              options={customers}
              loadData={loadCustomers}
              loading={priceListLoading || loadingCustomers}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <Selector
              name="state_id"
              label="Estado"
              idColumn="id"
              nameColumn="name"
              options={states}
              onChange={handleChangeState}
              loading={priceListLoading}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <Selector
              name="city_id"
              label="Cidade"
              idColumn="id"
              nameColumn="name"
              options={cities}
              loading={priceListLoading}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <RadioGroup
              name="status_code"
              label="Status"
              options={priceListStatus}
              required
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
      </Form>
      <DialogActions>
        <Button
          disabled={priceListLoading}
          onClick={handleClose}
          color="primary"
        >
          Cancelar
        </Button>
        {canEdit && (
          <Button
            disabled={priceListLoading}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
          >
            Finalizar
            {priceListLoading && <Loading />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(PriceListDialog);

PriceListDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
