export function getData() {
  return {
    companies: JSON.parse(localStorage.getItem('@FACIN/auth:companies')),
    branches: JSON.parse(localStorage.getItem('@FACIN/auth:branches')),
    signed: JSON.parse(localStorage.getItem('@FACIN/auth:signed')),
    user: JSON.parse(localStorage.getItem('@FACIN/auth:user')),
    token: JSON.parse(localStorage.getItem('@FACIN/auth:token')),
    logged_branch: JSON.parse(localStorage.getItem('@FACIN/auth:logged_branch')), // prettier-ignore
    current_accesses: JSON.parse(localStorage.getItem('@FACIN/auth:current_accesses')), // prettier-ignore
    sectorSelected: JSON.parse(localStorage.getItem('@FACIN/auth:sectorSelected')), // prettier-ignore
  };
}

export function setData(newData) {
  if (newData.companies) localStorage.setItem('@FACIN/auth:companies', JSON.stringify(newData.companies)); // prettier-ignore
  if (newData.branches) localStorage.setItem('@FACIN/auth:branches', JSON.stringify(newData.branches)); // prettier-ignore
  if (newData.signed || newData.signed === false) localStorage.setItem('@FACIN/auth:signed', JSON.stringify(newData.signed)); // prettier-ignore
  if (newData.user) localStorage.setItem('@FACIN/auth:user', JSON.stringify(newData.user)); // prettier-ignore
  if (newData.token) localStorage.setItem('@FACIN/auth:token', JSON.stringify(newData.token)); // prettier-ignore
  if (newData.logged_branch) localStorage.setItem('@FACIN/auth:logged_branch', JSON.stringify(newData.logged_branch)); // prettier-ignore
  if (newData.current_accesses) localStorage.setItem('@FACIN/auth:current_accesses', JSON.stringify(newData.current_accesses)); // prettier-ignore
  if (newData.sectorSelected) localStorage.setItem('@FACIN/auth:sectorSelected', JSON.stringify(newData.sectorSelected)); // prettier-ignore
}
