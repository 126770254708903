import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import Avatar from '@material-ui/core/Avatar';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import Close from '@material-ui/icons/Close';
import Loyalty from '@material-ui/icons/Loyalty';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';
import { useOrderItem } from '~/providers/orderItem';
import { useOrder } from '~/providers/order';
import { useNavigation } from '~/providers/navigation';

import { debounceEvent } from '~/utils/debounce';

import {
  Button,
  Breadcrumbs,
  OutlinedInput,
  NavigateNextIcon,
  Nav,
  FormControl,
  InputLabel,
  InputAdornment,
  CircularProgress,
  CartContainer,
  CartListContainer,
  List,
  ListItem,
} from './styles';

const useStyles = makeStyles(theme => ({
  popperRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 3px 6px 0px rgba(30, 30, 50, 0.30)',
  },
  root: {
    width: '100%',
    minWidth: 300,
    minHeight: 200,
    maxWidth: 300,
    maxHeight: 200,
    overflow: 'auto',
  },
  even: {
    backgroundColor: theme.palette.action.selected,
  },
  odd: {
    backgroundColor: theme.palette.action.hover,
  },
  inline: {
    display: 'flex',
    flexDirection: 'column',
  },
  finalizeOrder: {
    width: '100%',
    padding: '0',
    maxWidth: 300,
    maxHeight: 40,
    borderTop: `1px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  goToCart: {
    padding: '0',
    margin: '.4em',
    borderRadius: '4px',
    color: 'white',
    maxWidth: 300,
    backgroundColor: theme.palette.success.main,
    justifyContent: 'center',
  },
  goToCartText: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  goToCartSVG: {
    fontSize: '1.5rem',
  },
  centerButton: {
    justifyContent: 'center',
  },
  customBadge: {
    backgroundColor: '#ffa500',
  },
}));

function OrderHeader({ search, loading, changing }) {
  const { setPageIndex, pageIndex } = useNavigation();

  const classes = useStyles();

  const { order, show } = useOrder();
  const { orderItems, destroy: orderItemDestroy } = useOrderItem();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleSearch(event) {
    const auxText = event.target.value.trim();

    debounceEvent(() => search(auxText), 500)();
  }

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  function handleGoToCart() {
    setPageIndex({ newPageIndex: 1 });
    handleClick();
  }

  function handleGoToProducts() {
    setPageIndex({ newPageIndex: 2 });
    handleClick();
  }
  async function handleDestroyOrderItem(orderItem) {
    if (await orderItemDestroy({ orderItem }))
      await show({ orderUuid: orderItem.order_uuid });
  }

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  function handleBreadcrumbs(index) {
    switch (index) {
      case 1:
        setPageIndex({ newPageIndex: index });

        break;
      case 2:
        if (!order.customer)
          toast.warn('É necessário selecionar um Cliente antes');
        else setPageIndex({ newPageIndex: index });

        break;
      case 3:
        if (!order.payment_method)
          toast.warn('É necessário selecionar uma Forma de Pagamento antes');
        else {
          setPageIndex({ newPageIndex: index });
        }

        break;
      default:
        break;
    }
  }

  if (order.uuid)
    return (
      <Nav style={pageIndex === 2 ? {} : { justifyContent: 'flex-end' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {pageIndex === 0 && (
            <Button color="primary" onClick={() => handleGoToCart()} disabled>
              {changing === 'customer' ? 'Clientes' : 'Forma de Pagamento'}
            </Button>
          )}
          {pageIndex !== 2 && (
            <Button
              disabled={order.status_code !== 1}
              color={pageIndex === 2 ? 'primary' : 'primary'}
              onClick={() =>
                pageIndex === 0 ? handleGoToCart() : handleGoToProducts()
              }
            >
              {pageIndex !== 0 ? 'Produtos' : 'Carrinho'}
              {pageIndex !== 0 ? <Loyalty /> : <ShoppingCart />}
            </Button>
          )}
        </Breadcrumbs>

        {pageIndex === 2 && (
          <CartContainer>
            <FormControl size="small" variant="outlined" style={{ width: 200 }}>
              <InputLabel variant="outlined">Buscar ...</InputLabel>
              <OutlinedInput
                onChange={handleSearch}
                label="Buscar ..."
                type="search"
                endAdornment={
                  loading && (
                    <>
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    </>
                  )
                }
              />
            </FormControl>
            <CartListContainer>
              <Popper
                id={id}
                anchorEl={anchorEl}
                open={open}
                placement="left-start"
                className={classes.popperRoot}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '.3em',
                  }}
                >
                  <Close
                    onClick={() => handleClick()}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <List className={classes.root}>
                  {orderItems.map((orderItem, index) => (
                    <ListItem
                      className={index % 2 === 0 ? classes.even : classes.odd}
                      key={orderItem.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={orderItem.product_image}
                          alt={orderItem.product_name}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={orderItem.product_name}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              <span>Quantidade: {orderItem.quantity}</span>
                              <span>Valor: R$ {orderItem.total_net_value}</span>
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDestroyOrderItem(orderItem)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <List className={classes.finalizeOrder}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textPrimary"
                        >
                          Valor Total:
                        </Typography>
                      }
                      secondary={
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          R$ {order.net_value}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <List className={classes.goToCart}>
                  <ListItem
                    className={classes.centerButton}
                    button
                    onClick={() => handleGoToCart()}
                  >
                    <div className={classes.goToCartText}>
                      Finalizar Pedido
                      <ArrowRight className={classes.goToCartSVG} />
                    </div>
                  </ListItem>
                </List>
              </Popper>
              <Badge
                onClick={handleClick}
                classes={{ badge: classes.customBadge }}
                badgeContent={!orderItems ? '' : orderItems?.length}
                style={{
                  cursor: 'pointer',
                  fontSize: '1.8rem',
                  color: 'black',
                }}
              >
                <ShoppingCart style={{ color: '#1f7ecc' }} />
              </Badge>
            </CartListContainer>
          </CartContainer>
        )}
      </Nav>
    );

  return (
    <Nav>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Button
          color={pageIndex === 1 ? 'primary' : 'inherit'}
          onClick={() => handleBreadcrumbs(1)}
        >
          Clientes
        </Button>
        <Button
          color={pageIndex === 2 ? 'primary' : 'inherit'}
          onClick={() => handleBreadcrumbs(2)}
        >
          Forma de Pagamento
        </Button>
        <Button
          color={pageIndex === 3 ? 'primary' : 'inherit'}
          onClick={() => handleBreadcrumbs(3)}
        >
          Produtos
        </Button>
      </Breadcrumbs>
      <FormControl size="small" variant="outlined" style={{ width: 200 }}>
        <InputLabel variant="outlined">Buscar ...</InputLabel>
        <OutlinedInput
          onChange={handleSearch}
          label="Buscar ..."
          type="search"
          endAdornment={
            loading && (
              <>
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              </>
            )
          }
        />
      </FormControl>
    </Nav>
  );
}

export default OrderHeader;

OrderHeader.propTypes = {
  search: PropTypes.func,
  loading: PropTypes.bool,
  changing: PropTypes.string,
};

OrderHeader.defaultProps = {
  search: null,
  loading: false,
  changing: '',
};
