import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { debounceEvent } from '~/utils/debounce';

import { Autocomplete, InputWrapper, Input, SelectorLoading } from './styles';

function Selector({
  name,
  label,
  idColumn,
  nameColumn,
  options,
  defaultValue,
  onChange,
  loadData,
  loading,
  required,
  ...rest
}) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const [value, setValue] = useState(defaultValue || null);

  const inputRef = useRef({ value: '' });

  const handleOnBlur = useCallback(() => {
    if (loadData && !inputRef.current.value) loadData('');
  }, [loadData]);

  const handleChangeText = useCallback(
    event => {
      if (loadData) {
        const auxText = event.target.value.trim();

        debounceEvent(() => loadData(auxText), 500)();
      }
    },
    [loadData]
  );

  const handleChange = useCallback(
    newValue => {
      setValue(newValue);

      if (inputRef.current)
        if (newValue) inputRef.current.value = newValue[idColumn];
        else {
          inputRef.current.value = '';

          if (loadData) loadData('');
        }

      if (onChange) onChange(newValue);

      if (error) clearError();
    },
    [idColumn, onChange, error, clearError, loadData]
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, handleChange]);

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={option => option[nameColumn]}
      getOptionSelected={option => option}
      loading={loading}
      variant="outlined"
      size="small"
      renderInput={params => (
        <InputWrapper error={!!error}>
          {loading && <SelectorLoading />}
          <Input
            {...params}
            label={
              <p>
                {label} {required && <b style={{ color: '#d50000' }}>*</b>}
              </p>
            }
            variant="outlined"
            error={!!error}
            helperText={error || ' '}
            onChange={handleChangeText}
            onBlur={handleOnBlur}
          />
        </InputWrapper>
      )}
      {...rest}
    />
  );
}

export default Selector;

Selector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  idColumn: PropTypes.string.isRequired,
  nameColumn: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func,
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.shape({}),
};

Selector.defaultProps = {
  onChange: null,
  loadData: null,
  loading: false,
  required: false,
  defaultValue: null,
};
