import styled from 'styled-components';
import DialogStyled from '@material-ui/core/Dialog';

export const Container = styled.div``;

export const Dialog = styled(DialogStyled).attrs({
  width: 'md',
})``;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;
