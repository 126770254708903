import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import DialogStyled from '@material-ui/core/Dialog';

export const Container = styled.div``;

export const Dialog = styled(DialogStyled).attrs({
  width: 'md',
})``;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

export const SaveButtonStatus = styled(Btn).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  &.MuiButton-root {
    width: 100%;
    color: ${props => props.theme.colors.primary};

    &:hover {
      background-color: ${props => props.theme.colors.backFirst};
    }
  }
`;
