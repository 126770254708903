export default function validateCpfCnpj(cpf_cpnj) {
  if (!cpf_cpnj || (cpf_cpnj.length !== 14 && cpf_cpnj.length !== 11))
    return false;

  const type = cpf_cpnj.length === 14 ? 'J' : 'F';

  if (type === 'F') {
    let sum = 0;
    let rest = 0;

    if (cpf_cpnj === '00000000000') return false;

    for (let i = 1; i <= 9; i += 1)
      sum += Number(cpf_cpnj.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== Number(cpf_cpnj.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i += 1)
      sum += Number(cpf_cpnj.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== Number(cpf_cpnj.substring(10, 11))) return false;
  }

  if (type === 'J') {
    let weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;
    for (let i = 0; i < weights.length; i += 1) {
      sum += Number(cpf_cpnj[i], 10) * weights[i];
    }

    let remainder = sum % 11;
    let firstDigit;
    if (remainder < 2) firstDigit = 0;
    else firstDigit = 11 - remainder;

    weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    sum = 0;
    for (let i = 0; i < weights.length; i += 1) {
      sum += Number(cpf_cpnj[i]) * weights[i];
    }
    remainder = sum % 11;

    let secondDigit;
    if (remainder < 2) secondDigit = 0;
    else secondDigit = 11 - remainder;

    if (
      !(
        firstDigit === Number(cpf_cpnj[12]) &&
        secondDigit === Number(cpf_cpnj[13])
      )
    ) {
      return false;
    }
  }

  return true;
}
