import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useReport } from '~/providers/reports';
import { useErrorHandler } from '~/providers/errorHandler';

import {
  indexSelectorByTableName,
  parseToView,
} from '~/utils/indexSelectorByTableName';

import {
  Selector,
  ValueInput,
  Checkbox,
  DatePicker,
  RadioGroup,
  TextInput,
  ImagePicker,
} from '../Form';

export function DinamicInput({ reportQuery, width }) {
  const { report } = useReport();

  const [indexSelectorLoading, setIndexSelectorLoading] = useState(false);
  const { setErrorHandlerData } = useErrorHandler();

  const [options, setOptions] = useState([]);

  const loadSelector = useCallback(
    async (search = '') => {
      setIndexSelectorLoading(true);

      const indexSelectorData = await indexSelectorByTableName({
        tableName: reportQuery.table_name.replace('_', '-'),
        search,
      });

      if (indexSelectorData.indexSelectorErrors) {
        setErrorHandlerData({
          error: {
            ...indexSelectorData.indexSelectorErrors,
            resolveFunction: () => loadSelector({ search }),
          },
        });
      } else {
        const parsedOptions = parseToView({
          options: indexSelectorData,
          tableLabel: reportQuery?.table_label,
          tableKey: reportQuery?.table_key,
          frontKey: reportQuery.front_key,
        });

        setOptions(parsedOptions);
      }

      setIndexSelectorLoading(false);
    },
    [reportQuery, setErrorHandlerData]
  );

  const defaultProps = {
    options: options || [],
    label: reportQuery?.name || 'Valor',
    name: reportQuery?.filter_key || 'value',
    required: reportQuery?.required,
  };

  const inputDict = {
    value: (
      <ValueInput
        inputProps={{
          decimalScale: reportQuery?.field_type === 'integer' ? 0 : 2,
          thousandSeparator: reportQuery?.field_type === 'integer' ? null : '.',
        }}
        style={{ width }}
        {...defaultProps}
      />
    ),
    checkbox: <Checkbox style={{ width }} {...defaultProps} />,
    date: <DatePicker style={{ width }} {...defaultProps} />,
    radio: <RadioGroup style={{ width }} {...defaultProps} />,
    text: <TextInput style={{ width }} {...defaultProps} />,
    selector: (
      <Selector
        idColumn={reportQuery?.table_key}
        nameColumn={`${reportQuery?.table_label}_view`}
        loadData={loadSelector}
        loading={indexSelectorLoading}
        {...defaultProps}
        style={{ width }}
      />
    ),
    image: <ImagePicker style={{ width }} {...defaultProps} />,
  };

  useEffect(() => {
    const parsedOptions = parseToView({
      options: report[reportQuery?.table_name],
      tableLabel: reportQuery?.table_label,
      tableKey: reportQuery?.table_key,
      frontKey: reportQuery.front_key,
    });

    setOptions(parsedOptions);
  }, [report, reportQuery]);

  return (
    inputDict[reportQuery?.input_type] || (
      <TextInput
        name="value"
        label={reportQuery?.name || 'value'}
        disabled
        style={{ width }}
      />
    )
  );
}

DinamicInput.propTypes = {
  reportQuery: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string,
};

DinamicInput.defaultProps = {
  width: null,
};
