import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export { default as TextField } from '@material-ui/core/TextField';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 2em;
  gap: 20px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  gap: 20px;
  img {
    width: 200px;
    height: 200px;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    object-fit: contain;
  }
`;

export const TextFieldContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 20px;
  }
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
})``;
