import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useShare } from '~/providers/share';

import TableList, { WrapperContainer } from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'company_key', label: 'CPF / CNPJ RAIZ' },
];

function Shares() {
  const history = useHistory();

  const { shares, shareListLoading, index, show, destroy } = useShare();

  const [loaded, setLoaded] = useState(false);

  const loadShares = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () => ({
      label: 'ADICIONAR',
      handleFunction: () => history.push('/shares/form', { newShare: true }),
    }),
    [history]
  );

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async share => {
          history.push('/shares/form', { showShare: true });

          await show({ shareUuid: share.uuid });
        },
      },
      {
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async share => {
          history.push('/shares/form', { updateShare: true });

          await show({ shareUuid: share.uuid });
        },
      },
      {
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: share => {
          destroy({ share });
        },
      },
    ],
    [history, show, destroy]
  );

  useEffect(() => {
    if (!loaded && shares.length <= 1) loadShares();

    setLoaded(true);
  }, [shares, loadShares, loaded]);

  return (
    <WrapperContainer>
      <TableList
        title="Compatilhamentos"
        newItem={newItem}
        headCells={headCells}
        data={shares}
        loadData={loadShares}
        loading={shareListLoading}
        actions={actions}
      />
    </WrapperContainer>
  );
}

export default Shares;
