import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiFacin
      .get('/unit-of-measures', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { unitOfMeasures: data };
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao listar Unidades de Medidas',
      local: 'UnitOfMeasureActionsIndex',
    });

    return { unitOfMeasureErrors };
  }
}

export async function show({ unitOfMeasureUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/unit-of-measures/${unitOfMeasureUuid}`)
      .then(res => res.data);

    return { unitOfMeasure: data.unit_of_measure };
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao visualizar Unidade de Medida',
      local: 'UnitOfMeasureActionsShow',
    });

    return { unitOfMeasureErrors };
  }
}

export async function store({ unitOfMeasure = {} }) {
  try {
    const { data } = await apiFacin
      .post('/unit-of-measures', {
        data: [unitOfMeasure],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...unitOfMeasure, ...data[0].unit_of_measure };
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Unidade de Medida',
      local: 'UnitOfMeasureActionsStore',
    });

    return { unitOfMeasureErrors };
  }
}

export async function update({ unitOfMeasure = {} }) {
  try {
    const { data } = await apiFacin
      .put('/unit-of-measures', {
        data: [unitOfMeasure],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...unitOfMeasure, ...data[0].unit_of_measure };
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao atualizar Unidade de Medida',
      local: 'UnitOfMeasureActionsUpdate',
    });

    return { unitOfMeasureErrors };
  }
}

export async function destroy({ unitOfMeasure = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/unit-of-measures`,
      method: 'delete',
      data: { data: [unitOfMeasure] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao remover Unidade de Medida',
      local: 'UnitOfMeasureActionsDestroy',
    });

    return { unitOfMeasureErrors };
  }
}

export async function indexSelector({ search = '' }) {
  try {
    const { data: unitOfMeasures } = await apiFacin
      .get(`/unit-of-measures`, { params: { search, for_selector: true } })
      .then(res => res.data);

    return { unitOfMeasures };
  } catch (error) {
    const unitOfMeasureErrors = errorHandler({
      error,
      title: 'Error ao listar Unidades de Medida',
      local: 'UnitOfMeasureActionsIndexSelector',
    });

    return { unitOfMeasureErrors };
  }
}
