import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import CartButton from '@material-ui/core/Button';

import { useProduct } from '~/providers/product';
import { useOrder } from '~/providers/order';
import { useOrderItem } from '~/providers/orderItem';
import { useNavigation } from '~/providers/navigation';

import noImage from '~/assets/images/noImage.png';

import OrderHeader from '~/components/OrderHeader';
import OrderItemDialog from '~/components/Dialogs/OrderItem/index';
import OrderDiscountDialog from '~/components/Dialogs/OrderDiscount';

import {
  Container,
  NavigationContent,
  ProductList,
  ProductListItem,
  CircularProgress,
  Button,
  ShoppingCart,
} from './styles';

function Products() {
  const { products, productListLoading, index, productPrice } = useProduct();
  const { order } = useOrder();
  const { pageIndex, setPageIndex } = useNavigation();
  const { orderItems, show } = useOrderItem();

  const parsedProducts = useMemo(
    () =>
      products.map(product => {
        const orderItemFinded = orderItems.find(
          orderItem => orderItem.product_uuid === product.uuid
        );

        return { ...product, orderItemFinded };
      }),
    [products, orderItems]
  );

  const [showProductDialog, setShowProductDialog] = useState(false);
  const [showDiscountDialog, setShowDiscountDialog] = useState(false);

  async function handleSelectProduct(product) {
    if (product.status_code !== 1) {
      toast.warn('Só é possível selecionar Produtos ativos');
    } else {
      setShowProductDialog(true);

      if (product.orderItemFinded)
        await show({ orderItemUuid: product.orderItemFinded.uuid });
      else {
        await productPrice({
          product_uuid: product.uuid,
          customer_uuid: order.customer && order.customer.uuid,
          quantity: 1,
        });
      }
    }
  }

  const loadProducts = useCallback(
    async (search = '', order_by = 'id', auxOrder = 'asc') => {
      await index({
        search,
        order_by,
        order: auxOrder,
        customer_uuid: order.customer.uuid,
      });
    },
    [index, order]
  );

  function onErrorImage(event) {
    event.target.src = noImage; // eslint-disable-line
  }

  useEffect(() => {
    if (order.customer && order.payment_method) loadProducts();
  }, [loadProducts, order]);

  return (
    <Container>
      <OrderHeader
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        search={loadProducts}
        loading={productListLoading}
      />
      {productListLoading && (
        <div className="loading-wrapper">
          <CircularProgress />
        </div>
      )}
      <OrderItemDialog
        showDialog={showProductDialog}
        setShowDialog={setShowProductDialog}
        setPageIndex={setPageIndex}
      />
      <OrderDiscountDialog
        showDialog={showDiscountDialog}
        setShowDialog={setShowDiscountDialog}
      />
      <NavigationContent>
        <h2>Lista de Produtos</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDiscountDialog(true)}
        >
          <p>Aplicar Desconto</p>
        </Button>
      </NavigationContent>
      <ProductList>
        {parsedProducts.map(product => (
          <ProductListItem key={product.uuid}>
            {product.orderItemFinded && <ShoppingCart />}
            <div className="product-image">
              <img
                src={
                  product.product_images && product.product_images.length
                    ? product.product_images[0].image
                    : noImage
                }
                alt={product.name}
                onError={onErrorImage}
              />
            </div>
            <div className="product-name-price">
              <h2>{product.name}</h2>
              <p>
                <span>R$</span>
                {product.price_list_price || product.price}
              </p>
            </div>
            <div className="product-info">
              <p>{product.description}</p>
            </div>
            <CartButton onClick={() => handleSelectProduct(product)}>
              Adicionar ao carrinho
            </CartButton>
          </ProductListItem>
        ))}
      </ProductList>
    </Container>
  );
}

export default Products;
