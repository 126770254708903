import React from 'react';
import PropTypes from 'prop-types';

import { useCustomer } from '~/providers/customer';

import CustomersContent from '~/pages/Customers/Form/content';
import { Dialog, DialogTitle, IconButton, CloseIcon } from './styles';

function CustomerDialog({ showDialog, setShowDialog }) {
  const { customerLoading, clearState } = useCustomer();

  function handleClose() {
    clearState({});
    setShowDialog(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={showDialog}
      onClose={!customerLoading ? handleClose : () => {}}
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Cliente
        <IconButton onClick={!customerLoading ? handleClose : () => {}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {showDialog && <CustomersContent setShowDialog={setShowDialog} />}
    </Dialog>
  );
}

export default CustomerDialog;

CustomerDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
