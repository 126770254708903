export default {
  title: 'dark',

  colors: {
    primary: '#128c7e',
    secondary: '#25d366',

    backMain: '#0d1418',
    backHeader: '#1e2428',
    backAside: '#131c21',
    backAsideHover: '#262d31',
    backScrollBar: '#394045',
    backFromSystemMessage: '#1e2a30',
    backFromCustomerMessage: '#262d31',
    backFromMeMessage: '#054740',
    backMessageShadow: 'rgba(255, 255, 255, 0.2)',
    backSendMessage: '#1e2428',
    backInputMessage: '#33383b',
    backModalButtonHover: '#353535',

    borderDivider: '#33383b',

    textFirst: '#9b9d9f',
    textSecond: '#939699',
    textThird: '#bfc1c3',
    textFourth: '#9fa2a5',
    textFifth: '#d3d3d5',
  },
};
