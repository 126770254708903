import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const INITIAL_STATE = {
  pageIndex: 1,
  previousIndexes: [],
};

const NavContext = createContext(INITIAL_STATE);

export function NavigationProvider({ children }) {
  const history = useHistory();
  const [data, setData] = useState(INITIAL_STATE);

  const setNavData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const setPageIndex = useCallback(
    ({ newPageIndex = null }) => {
      if (newPageIndex || newPageIndex === 0) {
        setData(oldData => {
          const auxPreviousIndexes = oldData.previousIndexes;

          const findedPageIndex = auxPreviousIndexes.findIndex(
            pageIndex => pageIndex === newPageIndex
          );
          if (findedPageIndex !== -1) {
            auxPreviousIndexes.splice(findedPageIndex);
          } else {
            auxPreviousIndexes.push(oldData.pageIndex);
          }

          return {
            pageIndex: newPageIndex,
            previousIndexes: auxPreviousIndexes,
          };
        });
      } else {
        setData(oldData => {
          const auxPreviousIndexes = oldData.previousIndexes;
          const auxPageIndex = auxPreviousIndexes.pop();
          if (!auxPageIndex) {
            history.goBack();
          }
          return {
            previousIndexes: auxPreviousIndexes || [],
            pageIndex: auxPageIndex || 1,
          };
        });
      }
    },
    [history]
  );

  const clearNavigationState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        pageIndex: 1,
        previousIndexes: [],
      };
    });
  }, []);

  return (
    <NavContext.Provider
      value={{
        ...data,
        setNavData,
        setPageIndex,
        clearNavigationState,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export function useNavigation() {
  const context = useContext(NavContext);

  return context;
}

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
