import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';
import { useProductImage } from '../productImage';
import { usePriceList } from '../priceList';

import * as actions from './actions';

const INITIAL_STATE = {
  products: [],
  product: {},
  productErrors: {},
  productLoading: false,
  productListLoading: false,
};

const ProductContext = createContext(INITIAL_STATE);

export function ProductProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();
  const {
    setProductImageData,
    clearState: productImageClearState,
  } = useProductImage();
  const { setPriceListData, clearState: priceListClearState } = usePriceList();

  const [data, setData] = useState(INITIAL_STATE);

  const setProductData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '', customer_uuid = '' }) => {
      setProductData({ productListLoading: true });

      const productData = await actions.index({
        search,
        order_by,
        order,
        customer_uuid,
      });

      if (productData.productErrors)
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () =>
              index({ search, order_by, order, customer_uuid }),
          },
        });

      setProductData({ ...productData, productListLoading: false });
    },
    [setProductData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ productUuid = '' }) => {
      setProductData({ productLoading: true });

      const productData = await actions.show({ productUuid });

      if (productData.productErrors) {
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () => show({ productUuid }),
          },
        });

        history.goBack();
      } else {
        setProductImageData({
          productImages: [...productData.product.product_images],
        });
        delete productData.product.product_images;

        setPriceListData({
          priceLists: [...productData.product.price_lists],
        });
        delete productData.product.price_lists;
      }

      setProductData({ ...productData, productLoading: false });
    },
    [
      setProductData,
      setErrorHandlerData,
      history,
      setProductImageData,
      setPriceListData,
    ]
  );

  const store = useCallback(
    async ({ product = {} }) => {
      setProductData({ productLoading: true });

      const productData = await actions.store({ product });

      if (productData.productErrors) {
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () => store({ product }),
          },
        });

        setProductData({ ...productData, productLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          products: [productData, ...oldData.products],
          productLoading: false,
        }));

        history.goBack();

        toast.success('Produto cadastrado com sucesso!');
      }
    },
    [setProductData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ product = {} }) => {
      setProductData({ productLoading: true });

      const productData = await actions.update({ product });

      if (productData.productErrors) {
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () => update({ product }),
          },
        });

        setProductData({ ...productData, productLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          products: [
            productData,
            ...oldData.products.filter(
              auxProduct => auxProduct.uuid !== product.uuid
            ),
          ],
          productLoading: false,
        }));

        history.goBack();

        toast.success('Produto atualizado com sucesso!');
      }
    },
    [setProductData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ product = {} }) => {
      setProductData({ productListLoading: true });

      const productData = await actions.destroy({ product });

      if (productData.productErrors) {
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () => destroy({ product }),
          },
        });

        setProductData({ ...productData, productListLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          products: [
            ...oldData.products.filter(
              auxProduct => auxProduct.uuid !== product.uuid
            ),
          ],
          productListLoading: false,
        }));

        toast.success('Produto removido com sucesso!');
      }
    },
    [setProductData, setErrorHandlerData]
  );

  const newProduct = useCallback(async () => {
    setProductData({ productLoading: true });

    const productData = await actions.newProduct();

    if (productData.productErrors)
      setErrorHandlerData({
        error: {
          ...productData.productErrors,
          resolveFunction: () => newProduct(),
        },
      });

    setProductData({ productLoading: false });

    return { product_groups: [], states: [], users: [], ...productData };
  }, [setProductData, setErrorHandlerData]);

  const productPrice = useCallback(
    async ({ customer_uuid, product_uuid, quantity }) => {
      setProductData({ productLoading: true });

      const productData = await actions.productPrice({
        customer_uuid,
        product_uuid,
        quantity,
      });

      if (productData.productErrors) {
        setErrorHandlerData({
          error: {
            ...productData.productErrors,
            resolveFunction: () =>
              productPrice({ customer_uuid, product_uuid, quantity }),
          },
        });
      }

      setProductData({ ...productData, productLoading: false });
    },
    [setProductData, setErrorHandlerData]
  );

  const clearState = useCallback(
    ({ all = false }) => {
      productImageClearState({ all: true });
      priceListClearState({ all: true });

      setData(oldData => {
        if (all) return INITIAL_STATE;
        return {
          ...oldData,
          product: {},
          productErrors: {},
          productLoading: false,
          productListLoading: false,
        };
      });
    },
    [productImageClearState, priceListClearState]
  );

  return (
    <ProductContext.Provider
      value={{
        ...data,
        setProductData,
        index,
        show,
        store,
        update,
        destroy,
        newProduct,
        productPrice,
        clearState,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export function useProduct() {
  const context = useContext(ProductContext);

  if (!context)
    throw new Error('useProduct must be used within an ProductProvider');

  return context;
}

ProductProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
