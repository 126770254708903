import styled from 'styled-components';
import MUIShoppingCart from '@material-ui/icons/ShoppingCart';

export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Button } from '@material-ui/core/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1px;

  height: 100vh;

  .loading-wrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 70px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em 2em;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
`;

export const ProductList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em 2em;
  flex-flow: row wrap;
  overflow-x: auto;
`;

export const ProductListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  flex: 0 1 230px;
  min-height: 350px;
  max-height: 350px;
  margin-right: 2em;
  margin-bottom: 2em;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  transition: background 200ms;
  line-height: 1.5;
  position: relative;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  h2,
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    text-overflow: ellipsis;
  }

  .product-name-price {
    h2 {
      font-size: 1rem;
      vertical-align: top;
    }

    p {
      font-size: 1.2rem;

      span {
        margin-right: 0.5em;
        font-size: 0.8rem;
      }
    }
  }

  .product-info {
    p {
      font-size: 0.9rem;
      margin-bottom: 1em;
    }
  }

  button {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.textPrimary};

    &:hover {
      background: ${props => props.theme.colors.primaryDarkened};
    }
  }
`;

export const ShoppingCart = styled(MUIShoppingCart).attrs({
  color: 'primary',
})`
  position: absolute;
  top: 10px;
  right: 10px;
`;
