export function setFormData({ formRef, formData = {} }) {
  Object.keys(formData).forEach(fieldName => {
    if (!formRef.current) return;

    const inputRef = formRef.current.getFieldRef(fieldName);

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(inputRef, formData[fieldName] || '');

    const ev2 = new Event('input', { bubbles: true });
    inputRef.dispatchEvent(ev2);

    setTimeout(() => inputRef.blur(), 0);
  });
}
