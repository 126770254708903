import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '~/providers/auth';
import { useCustomer } from '~/providers/customer';
import { useNotSale } from '~/providers/notSale';

import { validate } from '~/validators/notSale';

import { parseFormData } from '~/utils/notSale';
import { setFormData } from '~/utils/form';

import { Form, Grid, Loading, TextInput, Selector } from '../Form';
import { Dialog, DialogActions, DialogTitle, Button } from './styles';

function NotSaleDialog({ showDialog, setShowDialog }, ref) {
  const { logged_branch, user } = useAuth();
  const { customer } = useCustomer();
  const {
    notSale,
    notSaleLoading,
    store,
    update,
    notSaleErrors,
    clearState,
    newNotSale,
  } = useNotSale();

  const [generic_tables, setGenericTables] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({});

  async function handleLoadData() {
    const { generic_tables: auxGenericTables } = await newNotSale();

    setGenericTables(auxGenericTables);
  }

  useImperativeHandle(ref, () => ({
    handleLoadData,
    setCanEdit,
    setCurrentCustomer,
  }));

  const formRef = useRef(null);

  function handleClose() {
    clearState({});
    setShowDialog(false);
    setCanEdit(false);
  }

  async function handleSubmit() {
    if (formRef.current) {
      const data = formRef.current.getData();

      const { errorMessages, ...parsedData } = await validate({
        customer_uuid: currentCustomer.uuid,
        ...data,
      });

      if (errorMessages && formRef.current)
        formRef.current.setErrors(errorMessages);
      else {
        const findedGenericTable = generic_tables.find(
          generic_table => generic_table.uuid === data.generic_table_uuid
        );

        const parsedNotSale = {
          ...parsedData,
          req_id: '1',
          customer: currentCustomer,
          generic_table: findedGenericTable,
        };

        let response = null;

        if (notSale.uuid)
          response = await update({
            notSale: { ...notSale, ...parsedNotSale },
          });
        else {
          const { company_key, branch_key } = logged_branch;

          parsedNotSale.company_key = company_key;
          parsedNotSale.branch_key = branch_key;

          response = await store({ notSale: parsedNotSale });
        }

        if (response && response.uuid) handleClose();
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(notSaleErrors).length &&
      notSaleErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(notSaleErrors.validations);
  }, [notSaleErrors]);

  useEffect(() => {
    setCurrentCustomer(customer);
  }, [customer]);

  useEffect(() => {
    if (notSale.uuid) {
      const auxNotSale = { ...notSale };

      const {
        generic_tables: auxGenericTables,
        generic_table,
        ...notSaleData
      } = auxNotSale;

      setGenericTables(auxGenericTables);

      if (formRef.current) {
        const genericTableInputRef = formRef.current.getFieldRef(
          'generic_table_uuid'
        );
        genericTableInputRef.handleChange(generic_table);
      }

      const formData = parseFormData(notSaleData);

      setFormData({ formRef, formData });
    }
  }, [notSale]);

  return (
    <Dialog
      open={showDialog}
      onClose={!notSaleLoading ? handleClose : () => {}}
    >
      <DialogTitle>Não Venda</DialogTitle>
      <Form style={{ maxWidth: 300 }} ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <TextInput
              name="legacy_code"
              label="Código Legado"
              maxLength={10}
              disabled={!canEdit || (!user.is_admin && !user.is_manager)}
            />
          </Grid>
          <Grid item>
            <Selector
              name="generic_table_uuid"
              label="Motivo"
              idColumn="uuid"
              nameColumn="name"
              options={generic_tables}
              loading={notSaleLoading}
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid item>
            <TextInput
              name="observation"
              label="Observação"
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
      </Form>
      <DialogActions>
        <Button disabled={notSaleLoading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {canEdit && (
          <Button
            disabled={notSaleLoading}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
          >
            Finalizar
            {notSaleLoading && <Loading />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(NotSaleDialog);

NotSaleDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
