import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Logo = styled.img``;

export const HeaderContent = styled(AppBar).attrs({
  color: 'primary',
  position: 'fixed',
})`
  &.MuiPaper-root {
    background: ${props => props.theme.colors.backHeader};
    flex-direction: row;
  }
  align-items: center;
  justify-content: space-between;
`;

export const LogoContent = styled(Toolbar)`
  h2 {
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
  }
`;
export const FacileLogoContent = styled(Toolbar)`
  img {
    width: 40px;
  }
`;

export const MenuButton = styled(IconButton).attrs({
  edge: 'start',
  color: 'inherit',
})``;

export const DrawerContent = styled(Drawer)`
  > .MuiPaper-root {
    background: ${props => props.theme.colors.secondary};
  }
`;

export const ListItemIconContent = styled(ListItemIcon)`
  > svg {
    margin-left: 6px;

    color: ${props => (props.navigate ? props.theme.colors.primary : '#fff')};
  }
`;

export const ListItemTitle = styled(ListItemText)`
  color: ${props => props.theme.colors.backFirst};
`;

export const Main = styled.main`
  padding: 0 !important;
  display: flex;
  flex-direction: column;
`;

export const RouteContent = styled.div`
  margin: 60px 0 0 0;
  height: 50px;
  width: 100%;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
`;

export const RouteTitle = styled.h1`
  padding: 4px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.backFirst};
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

export const TabText = styled(Tab)`
  color: ${props => props.theme.colors.backFirst} !important;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  max-height: calc(100vh - 110px);
  overflow: auto;
`;
