import React from 'react';
import { useHistory } from 'react-router-dom';

import CompanyBranchesContent from './content';

import { Container, IconButton, IconArrowBack } from './styles';

function CompanyBranches() {
  const history = useHistory();

  return (
    <Container>
      <IconButton onClick={() => history.goBack()}>
        <IconArrowBack />
      </IconButton>
      <CompanyBranchesContent />
    </Container>
  );
}

export default CompanyBranches;
