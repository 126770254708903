import React from 'react';
import PropTypes from 'prop-types';

import { ErrorHandlerProvider } from './errorHandler';
import { ShareProvider } from './share';
import { UserProvider } from './user';
import { CustomerGroupProvider } from './customerGroup';
import { GenericTableProvider } from './genericTable';
import { NotSaleProvider } from './notSale';
import { CustomerProvider } from './customer';
import { ProductGroupProvider } from './productGroup';
import { UnitOfMeasureProvider } from './unitOfMeasure';
import { ProductImageProvider } from './productImage';
import { PriceListProvider } from './priceList';
import { ProductProvider } from './product';
import { PaymentMethodProvider } from './paymentMethod';
import { FreightTableProvider } from './freightTable';
import { OrderItemProvider } from './orderItem';
import { OrderProvider } from './order';
import { AuthProvider } from './auth';
import { ThemeProvider } from './theme';
import { NavigationProvider } from './navigation';
import { ParameterProvider } from './parameter';
import { ReportProvider } from './reports';

function AppProvider({ children }) {
  return (
    <ErrorHandlerProvider>
      <ShareProvider>
        <UserProvider>
          <CustomerGroupProvider>
            <GenericTableProvider>
              <ParameterProvider>
                <NotSaleProvider>
                  <CustomerProvider>
                    <ProductGroupProvider>
                      <UnitOfMeasureProvider>
                        <ProductImageProvider>
                          <PriceListProvider>
                            <PaymentMethodProvider>
                              <FreightTableProvider>
                                <ProductProvider>
                                  <OrderItemProvider>
                                    <OrderProvider>
                                      <ReportProvider>
                                        <NavigationProvider>
                                          <AuthProvider>
                                            <ThemeProvider>
                                              {children}
                                            </ThemeProvider>
                                          </AuthProvider>
                                        </NavigationProvider>
                                      </ReportProvider>
                                    </OrderProvider>
                                  </OrderItemProvider>
                                </ProductProvider>
                              </FreightTableProvider>
                            </PaymentMethodProvider>
                          </PriceListProvider>
                        </ProductImageProvider>
                      </UnitOfMeasureProvider>
                    </ProductGroupProvider>
                  </CustomerProvider>
                </NotSaleProvider>
              </ParameterProvider>
            </GenericTableProvider>
          </CustomerGroupProvider>
        </UserProvider>
      </ShareProvider>
    </ErrorHandlerProvider>
  );
}

export default AppProvider;

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
