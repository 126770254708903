import { numberMask, valueUnmask, valueMask } from './mask';

export function parseToView(priceLists = []) {
  return priceLists.map(priceList => ({
    ...priceList,
    price: valueMask(priceList.price),
    max_discount_percentage: valueMask(priceList.max_discount_percentage),
    min_quantity: valueMask(priceList.min_quantity),
    max_quantity: valueMask(priceList.max_quantity),
    product_name: priceList.product.name,
    customer_group_name:
      priceList.customer_group && priceList.customer_group.name,
    customer_name: priceList.customer && priceList.customer.first_name,
    uf_city: `${(priceList.state && priceList.state.uf) || ''} / ${
      (priceList.city && priceList.city.name) || ''
    }`,
    start_at_view: new Date(priceList.start_at).toLocaleDateString(),
    end_at_view: new Date(priceList.end_at).toLocaleDateString(),
  }));
}

export function parseDataToApi(priceList = {}) {
  return {
    branch_key: priceList.branch_key,
    cities: [],
    city: priceList.city,
    city_id: priceList.city_id,
    company_key: priceList.company_key,
    customer: priceList.customer,
    customer_group: {},
    customer_group_name: priceList.customer_group_name,
    customer_group_uuid: priceList.customer_group_uuid,
    customer_groups: [],
    customer_name: priceList.customer_name,
    customer_uuid: priceList.customer_uuid,
    customers: [],
    description: priceList.description,
    end_at: priceList.end_at,
    end_at_view: priceList.end_at_view,
    legacy_code: priceList.legacy_code,
    max_discount_percentage: valueUnmask(priceList.max_discount_percentage),
    min_quantity: valueUnmask(priceList.min_quantity),
    max_quantity: valueUnmask(priceList.max_quantity),
    price: valueUnmask(priceList.price),
    product: {},
    product_name: priceList.product_name,
    product_uuid: priceList.product_uuid,
    req_id: priceList.req_id,
    start_at: priceList.start_at,
    start_at_view: priceList.start_at_view,
    state: {},
    state_id: priceList.state_id,
    states: [],
    status_code: numberMask(priceList.status_code),
    uf_city: priceList.uf_city,
    uuid: priceList.uuid,
  };
}

export function parseFormData(priceList = {}) {
  return {
    legacy_code: priceList.legacy_code,
    description: priceList.description,
    price: priceList.price,
    max_discount_percentage: priceList.max_discount_percentage,
    min_quantity: priceList.min_quantity,
    max_quantity: priceList.max_quantity,
  };
}
