import React, { useState, useMemo, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useAuth } from '~/providers/auth';
import { useProduct } from '~/providers/product';
import { usePriceList } from '~/providers/priceList';

import PriceListDialog from '~/components/Dialogs/PriceList';
import TableList, { WrapperContainer } from '~/components/TableList';

import { Container } from './styles';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'description', label: 'Descrição' },
  { id: 'start_at_view', label: 'Início Vigência' },
  { id: 'end_at_view', label: 'Fim Vigência' },
];

const otherCells = [
  { id: 'min_quantity', label: 'Quantidade Mínima' },
  { id: 'max_quantity', label: 'Quantidade Máxima' },
  { id: 'customer_group_name', label: 'Grupo de Cliente' },
  { id: 'customer_name', label: 'Cliente' },
  { id: 'uf_city', label: 'UF / Município' },
];

function PriceLists({ canEdit }) {
  const { product } = useProduct();
  const {
    priceLists,
    priceListListLoading,
    index,
    show,
    destroy,
  } = usePriceList();
  const { user, current_accesses } = useAuth();

  const dialogRef = useRef(null);

  const [showDialog, setShowDialog] = useState(false);

  const loadPriceLists = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ product_uuid: product.uuid, search, order_by, order });
    },
    [index, product]
  );

  const newItem = useMemo(
    () => ({
      label: 'ADICIONAR',
      handleFunction: () => {
        if (dialogRef.current) {
          dialogRef.current.handleLoadData();
          dialogRef.current.setCanEdit(true);
        }

        setShowDialog(true);
      },
    }),
    []
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async priceList => {
          setShowDialog(true);

          await show({ priceListUuid: priceList.uuid });
        },
      },
    ];

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.price-lists.put']
    )
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        disabled: !canEdit,
        handleFunction: async priceList => {
          if (!user.is_admin && !user.is_manager && priceList.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else {
            if (dialogRef.current) dialogRef.current.setCanEdit(true);

            setShowDialog(true);

            await show({ priceListUuid: priceList.uuid });
          }
        },
      });

    if (
      user.is_admin ||
      user.is_manager ||
      current_accesses['facin.price-lists.delete']
    )
      auxActions.push({
        label: 'Remover',
        disabled: !canEdit,
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: priceList => {
          if (!user.is_admin && !user.is_manager && priceList.legacy_code)
            toast.warn(
              'Somente o Administrador pode alterar registros com Código Legado preenchido'
            );
          else destroy({ priceList });
        },
      });

    return auxActions;
  }, [user, current_accesses, canEdit, show, destroy]);

  return (
    <Container>
      <WrapperContainer>
        <PriceListDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          ref={dialogRef}
        />
        <TableList
          title={<>Tabelas de Preço</>}
          newItem={newItem}
          headCells={headCells}
          otherCells={otherCells}
          data={priceLists}
          loadData={loadPriceLists}
          loading={priceListListLoading}
          actions={actions}
          canEdit={canEdit}
        />
      </WrapperContainer>
    </Container>
  );
}

export default PriceLists;

PriceLists.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};
