import apiFacin from '~/config/apiFacin';

import { parseToView } from '~/utils/notSale';
import errorHandler from '~/utils/errorHandler';

export async function index({
  customer_uuid = '',
  search = '',
  order_by = '',
  order = '',
}) {
  try {
    let parsedOrderBy = order_by;

    if (order_by === 'generic_table_name' || order_by === 'customer_name')
      parsedOrderBy = parsedOrderBy.replace('_name', '_uuid');

    const { data } = await apiFacin
      .get('/not-sales', {
        params: { customer_uuid, search, order_by: parsedOrderBy, order },
      })
      .then(res => res.data);

    return { notSales: parseToView(data) };
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao listar Não Vendas',
      local: 'NotSaleActionsIndex',
    });

    return { notSaleErrors };
  }
}

export async function show({ notSaleUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/not-sales/${notSaleUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.not_sale;

    return { notSale: parseToView([{ ...data.not_sale, ...auxData }])[0] };
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao visualizar Não Venda',
      local: 'NotSaleActionsShow',
    });

    return { notSaleErrors };
  }
}

export async function store({ notSale = {} }) {
  try {
    const { data } = await apiFacin
      .post('/not-sales', {
        data: [notSale],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...notSale, ...data[0].not_sale }])[0];
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Não Venda',
      local: 'NotSaleActionsStore',
    });

    return { notSaleErrors };
  }
}

export async function update({ notSale = {} }) {
  try {
    const { data } = await apiFacin
      .put('/not-sales', {
        data: [notSale],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...notSale, ...data[0].not_sale }])[0];
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao atualizar Não Venda',
      local: 'NotSaleActionsUpdate',
    });

    return { notSaleErrors };
  }
}

export async function destroy({ notSale = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/not-sales`,
      method: 'delete',
      data: { data: [notSale] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao remover Não Venda',
      local: 'NotSaleActionsDestroy',
    });

    return { notSaleErrors };
  }
}

export async function newNotSale() {
  try {
    const { data } = await apiFacin.get('/new-not-sale').then(res => res.data);

    return data;
  } catch (error) {
    const notSaleErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para nova Não Venda',
      local: 'NotSaleActionsNewNotSale',
    });

    return { notSaleErrors };
  }
}
