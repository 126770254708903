import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import SwipViews from 'react-swipeable-views';
import RadioGroup from '@material-ui/core/RadioGroup';
import ButtonBase from '@material-ui/core/ButtonBase';
import MUITypography from '@material-ui/core/Typography';

export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Icon } from '@material-ui/core/Icon';
export { default as TextField } from '@material-ui/core/TextField';
export { default as Radio } from '@material-ui/core/Radio';
export { default as FormControlLabel } from '@material-ui/core/FormControlLabel';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Menu } from '@material-ui/core/Menu';
export { default as MenuItem } from '@material-ui/core/MenuItem';

export { default as Email } from '@material-ui/icons/Email';
export { default as Visibility } from '@material-ui/icons/Visibility';
export { default as PdfIcon } from '@material-ui/icons/PictureAsPdf';
export { default as Close } from '@material-ui/icons/Close';
export { default as Add } from '@material-ui/icons/Add';
export { default as Remove } from '@material-ui/icons/Remove';
export { default as Delete } from '@material-ui/icons/Delete';
export { default as ArrowBack } from '@material-ui/icons/ArrowBack';
export { default as DescriptionIcon } from '@material-ui/icons/Description';
export { default as DescriptionOutlinedIcon } from '@material-ui/icons/DescriptionOutlined';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1px;

  .loading-wrapper {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 70px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const Typography = styled(MUITypography)`
  margin-left: 5px;
`;

export const HeaderContainer = styled.div``;

export const EmptyBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 400;
  }

  p {
    font-size: 2rem;
    font-weight: 300;
  }

  img {
    width: 100%;
    max-width: 300px;
    padding: 2em;
    filter: grayscale(1);
  }
`;

export const ShoppingCart = styled.div`
  display: flex;
  padding: 1em 1.2em;
  gap: 10px;
  justify-content: space-between;
`;

export const CartList = styled.ul`
  background: #fff;
  width: 30%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  flex: 1;
  padding: 0.3em 0.5em;
  border-radius: 10px;
  max-height: calc(90vh - 10px);
  overflow-y: auto;
`;

export const CartItems = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 1em;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  padding: 0.6em 0.5em;
  border-radius: 10px;

  .product-subtotal {
    display: flex;
    align-items: center;
    padding: 0 1em;
    gap: 20px;

    p {
      font-size: 1rem;
    }

    svg {
      font-size: 1rem;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const ProductImageInfo = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    border-right: 2px solid ${props => props.theme.colors.backSecond};

    img {
      width: 100%;
      max-width: 80px;
      max-height: 80px;
      object-fit: contain;
      margin-right: 0.9em;
    }

    .name-info {
      text-align: left;

      h1 {
        font-size: 1.4rem;
        font-weight: lighter;
      }

      p {
        font-size: 0.8 rem;
        font-weight: lighter;
      }
    }
  }
`;

export const CartResumeInfo = styled.div`
  background: #fff;
  width: 35%;
  box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
  border-radius: 10px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.2rem;
    font-weight: lighter;
    text-align: left;
    margin-bottom: 0.2em;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.3em;
  }

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1em;
  }

  .product-price {
    padding: 1em;
    margin-top: 1em;
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
    border-radius: 10px;
    margin-bottom: 1em;

    .price-info {
      display: flex;
      justify-content: space-between;

      svg {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .product-info-detail {
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
    padding: 1em;
    border-radius: 10px;
    margin-bottom: 0.8em;
    line-height: 1.4;
    gap: 10px;
    display: flex;
    text-align: left;
    justify-content: space-evenly;

    div {
      flex: 1;
    }
  }

  .product-ship-info {
    padding: 1em;
    margin-bottom: 1em;
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
    border-radius: 10px;

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.8em;
      p {
        font-size: 1.1rem;
      }
    }
  }

  .order-buttons {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 0.3em;
    gap: 10px;

    .approve-refuse {
      display: flex;
      width: 100%;

      flex: 1;
      gap: 10px;
    }
    .save-confirm {
      display: flex;
      width: 100%;

      flex: 1;
      gap: 10px;
    }
  }
`;

export const SendEmail = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 0.9em;
    gap: 10px;
    color: ${props => (props.disabled ? 'unset' : props.theme.colors.primary)};
  }
`;

export const OrderDetails = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 0.9em;
    gap: 10px;
    color: ${props => props.theme.colors.primary};
  }
`;

export const ProductInfo = styled(ButtonBase)`
  &.MuiButtonBase-root {
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
    padding: 1em;
    border-radius: 10px;

    span {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${props => props.theme.colors.backSecond};
      padding-bottom: 0.4em;
      padding-top: 0.1em;
    }
  }
`;

export const ProductPrice = styled(ButtonBase)`
  &.MuiButtonBase-root {
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    padding: 1em;
    margin-top: 1em;
    box-shadow: 0px 3px 6px 0px rgba(30, 30, 50, 0.13);
    border-radius: 10px;
    margin-bottom: 1em;

    .price-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

export const CartButton = styled(Btn).attrs({
  variant: 'contained',
  color: 'primary',
})`
  &.MuiButton-contained {
    width: 60%;
    background-color: ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props => props.theme.colors.primaryDarken};
    }
  }
`;

export const RefuseButton = styled(Btn).attrs({
  variant: 'outlined',
  color: 'secondary',
})`
  &.MuiButton-root {
    width: 40%;
    color: ${props => props.theme.colors.refuseButton};
    border-color: ${props => props.theme.colors.refuseButton};

    &:hover {
      border-color: ${props => props.theme.colors.refuseButtonHover};
    }
  }
`;

export const ApproveButton = styled(Btn).attrs({
  variant: 'contained',
  color: 'primary',
})`
  &.MuiButton-contained {
    width: 60%;
    background-color: ${props => props.theme.colors.acceptButton};
    color: #fff;

    &:hover {
      background-color: ${props => props.theme.colors.acceptButtonHover};
    }
  }
`;

export const SaveButton = styled(Btn).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  &.MuiButton-root {
    width: 40%;
    color: ${props => props.theme.colors.primary};

    &:hover {
      background-color: ${props => props.theme.colors.backFirst};
    }
  }
`;

export const SaveButtonStatus = styled(Btn).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  &.MuiButton-root {
    width: 100%;
    color: ${props => props.theme.colors.primary};

    &:hover {
      background-color: ${props => props.theme.colors.backFirst};
    }
  }
`;

export const ShipSelectForm = styled(RadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid ${props => props.theme.colors.backSecond};
    padding-bottom: 0.8em;
  }
`;

export const SwipeableViews = styled(SwipViews).attrs({
  disabled: true,
})`
  width: 100%;

  .react-swipeable-view-container {
    height: 100%;
  }

  .react-swipeable-view-container > * {
    display: flex;
    align-items: flex-start;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;
