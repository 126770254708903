import apiCorreios from '~/config/apiCorreios';

import { numberUnmask } from '~/utils/mask';
import errorHandler from '~/utils/errorHandler';
import { setFormData } from '~/utils/form';

async function SearchZipCodeService({
  setLoadingZipCode,
  formRef,
  setErrorHandlerData,
  states,
}) {
  setLoadingZipCode(true);

  const zip_code = formRef.current && formRef.current.getFieldValue('zip_code');
  const zipCodeUnmasked = numberUnmask(zip_code);

  if (zipCodeUnmasked && zipCodeUnmasked.length === 8) {
    await apiCorreios
      .get(`${zipCodeUnmasked}/json`)
      .then(res => {
        if (!res.data.erro && !res.data.message) {
          if (formRef.current)
            formRef.current.setFieldValue('city_id', res.data.ibge);

          const formData = {
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
          };

          setFormData({ formRef, formData });

          const findedState = states.find(
            state => state.id === res.data.ibge.slice(0, 2)
          );

          const stateInputRef = formRef.current.getFieldRef('state_id');
          stateInputRef.handleChange(findedState);
        } else {
          setErrorHandlerData({
            error: {
              title: 'CEP inválido',
              message: 'Favor verificar o CEP',
            },
          });
        }
      })
      .catch(error => {
        errorHandler({
          error,
          title: 'Erro ao buscar CNPJ',
          local: 'SearchZipCodeService',
        });

        const errorParsed = errorHandler({
          error,
          title: 'Erro ao buscar CEP',
          local: 'SearchZipCodeService',
        });

        setErrorHandlerData({ error: errorParsed });
      });
  } else {
    setErrorHandlerData({
      error: {
        title: 'Quantidade inválida de caracteres',
        message: 'Somente é possível buscar CEP e com 8 caracteres',
      },
    });
  }

  setLoadingZipCode(false);
}

export default SearchZipCodeService;
