import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import Close from '@material-ui/icons/Close';
import { zipCodeMask } from '~utils/mask';
import {
  Dialog,
  Container,
  ShipmentDetails,
  InfoDetails,
  FinalizeInfoDetails,
  PaymentDetails,
  OrderDetails,
  Detail,
} from './styles';

function CartDetailDialog({ showDetailDialog, setShowDetailDialog, order }) {
  const handleClose = () => {
    setShowDetailDialog(false);
  };

  return (
    <Dialog onClose={handleClose} open={showDetailDialog}>
      <Container>
        <Detail>
          <ShipmentDetails>
            <h2>Detalhes da Entrega</h2>
            <InfoDetails>
              <p>Tipo de Entrega:</p>
              <p>{order?.freight_type || 'Tipo de entrega não selecionado'}</p>
            </InfoDetails>
            <InfoDetails>
              <p>Prazo de Entrega:</p>
              <p>{order.deadline || '###'} dias</p>
            </InfoDetails>
            <InfoDetails>
              <p>Endereço de Entrega:</p>
              <p>{order?.customer?.address || 'Endereço não preenchido'}</p>
            </InfoDetails>
            <InfoDetails>
              <p>Número</p>
              <p>{order?.customer?.number || 'Número não preenchido'}</p>
            </InfoDetails>
            <InfoDetails>
              <p>Complemento:</p>
              <p>
                {order?.customer?.complement || 'Complemento não preenchido'}
              </p>
            </InfoDetails>
            <InfoDetails>
              <p>CEP</p>
              <p>
                {zipCodeMask(order?.customer?.zip_code) || 'CEP não preenchido'}
              </p>
            </InfoDetails>
          </ShipmentDetails>
          <PaymentDetails>
            <h2>Pagamento</h2>
            <InfoDetails>
              <p>Forma de pagamento:</p>
              <p>
                {order?.payment_method?.name || 'Pagamento não selecionado'}
              </p>
            </InfoDetails>
          </PaymentDetails>
        </Detail>
        <OrderDetails>
          <h2>Detalhes do Pedido:</h2>
          <InfoDetails>
            <p>Código Legado:</p>
            <p>{order?.legacy_code || '##'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Número do pedido:</p>
            <p>{order?.id || '##'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Status:</p>
            <p>{order?.status_name || '##'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Peso Bruto (KG):</p>
            <p>{order?.gross_weight || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Peso Líquido (KG):</p>
            <p>{order?.net_weight || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Desconto (%):</p>
            <p>{order?.discount_percentage || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>IPI (%):</p>
            <p>{order?.ipi_value || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Valor Bruto (R$): </p>
            <p>{order?.gross_value || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Valor Líquido (R$): </p>
            <p>{order?.net_value || '0,00'}</p>
          </InfoDetails>
          <InfoDetails>
            <p>Data da Venda:</p>
            <p>
              {order.created_at
                ? format(parseISO(order.created_at), "dd'/'MM'/'yyyy - HH:mm")
                : '###'}
            </p>
          </InfoDetails>

          <FinalizeInfoDetails>
            <p>Valor total R$:</p>
            <p>{order?.total_sale_value || '--'}</p>
          </FinalizeInfoDetails>
        </OrderDetails>
        <Close size={30} onClick={() => handleClose()} />
      </Container>
    </Dialog>
  );
}

export default CartDetailDialog;

CartDetailDialog.propTypes = {
  showDetailDialog: PropTypes.bool,
  setShowDetailDialog: PropTypes.func.isRequired,
  order: PropTypes.shape(),
};

CartDetailDialog.defaultProps = {
  showDetailDialog: false,
  order: {},
};
