import apiFacin from '~/config/apiFacin';

import errorHandler from '~/utils/errorHandler';

export async function index({
  product_uuid = '',
  search = '',
  order_by = '',
  order = '',
}) {
  try {
    const { data } = await apiFacin
      .get('/product-images', {
        params: { product_uuid, search, order_by, order },
      })
      .then(res => res.data);

    return { productImages: data };
  } catch (error) {
    const productImageErrors = errorHandler({
      error,
      title: 'Error ao listar Imagens de Produtos',
      local: 'ProductImageActionsIndex',
    });

    return { productImageErrors };
  }
}

export async function show({ productImageUuid = '' }) {
  try {
    const { data } = await apiFacin
      .get(`/product-images/${productImageUuid}`)
      .then(res => res.data);

    return { productImage: data.product_image };
  } catch (error) {
    const productImageErrors = errorHandler({
      error,
      title: 'Error ao visualizar Imagem de Produto',
      local: 'ProductImageActionsShow',
    });

    return { productImageErrors };
  }
}

export async function store({ productImage = {} }) {
  try {
    const { data } = await apiFacin
      .post('/product-images', {
        data: [productImage],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...productImage, ...data[0].product_image };
  } catch (error) {
    const productImageErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Imagem de Produto',
      local: 'ProductImageActionsStore',
    });

    return { productImageErrors };
  }
}

export async function update({ productImage = {} }) {
  try {
    const { data } = await apiFacin
      .put('/product-images', {
        data: [productImage],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...productImage, ...data[0].product_image };
  } catch (error) {
    const productImageErrors = errorHandler({
      error,
      title: 'Error ao atualizar Imagem de Produto',
      local: 'ProductImageActionsUpdate',
    });

    return { productImageErrors };
  }
}

export async function destroy({ productImage = {} }) {
  try {
    const { data } = await apiFacin({
      url: `${apiFacin.defaults.baseURL}/product-images`,
      method: 'delete',
      data: { data: [productImage] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const productImageErrors = errorHandler({
      error,
      title: 'Error ao remover Imagem de Produto',
      local: 'ProductImageActionsDestroy',
    });

    return { productImageErrors };
  }
}
